<template>
  <spinner v-if="isLoadingData"/>

  <div v-if="!isLoadingData" class="grid grid-cols-2 gap-7 px-7 pt-7">
    <div class="col-span-1 mb-4 xl:mb-2">

      <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <div class="p-7 pb-2 border-b border-gray-200">
                  <form action="">
                    <table class="mb-4">
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Идентификатор:</td>
                        <td class="pb-3 text-sm">{{ id }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Наименование (RU):</td>
                        <td class="pb-3 text-sm">{{ name }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Наименование (EN):</td>
                        <td class="pb-3 text-sm">{{ nameEn || '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Координаты:</td>
                        <td class="pb-3 text-sm">{{ coords || '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Короткое описание (RU):</td>
                        <td class="pb-3 text-sm">{{ shortDescRu || '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Короткое описание (EN):</td>
                        <td class="pb-3 text-sm">{{ shortDescEn || '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Активность (RU):</td>
                        <td class="pb-3 text-sm">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="true"
                                id="bordered-checkbox"
                                type="checkbox"
                                v-model="isActiveRu"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (RU)
                              </label>
                              <p
                                  id="helper-checkbox-text"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)' }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Активность (EN):</td>
                        <td class="pb-3 text-sm">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="true"
                                id="bordered-checkbox-1"
                                type="checkbox"
                                v-model="isActiveRu"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-1" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (EN)
                              </label>
                              <p
                                  id="helper-checkbox-text-1"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)' }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Порядок сортировки:</td>
                        <td class="pb-3 text-sm">{{ sortValue || '-' }}</td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import Spinner from "../../../../flowbite/spinner.vue";
import {useRoute, useRouter} from 'vue-router'
import {servicePavilionFindById} from "../../../../../service/lists/serviceListPavilion.js";

const route = useRoute();
const router = useRouter();

const isLoadingData = ref(true);

const id = ref(null);
const name = ref(null);
const nameEn = ref(null);
const coords = ref(null);
const isActiveRu = ref(false);
const isActiveEn = ref(false);
const shortDescRu = ref(null);
const shortDescEn = ref(null);
const sortValue = ref(null);

if (route.params.id) {
  servicePavilionFindById(route.params.id).then(response => {
    isLoadingData.value = false;

    if (response.success && response.data) {
      const data = response.data;

      name.value = data.name;
      nameEn.value = data.nameEn;
      id.value = data.id;
      isActiveRu.value = data.activeRu;
      isActiveEn.value = data.activeEn;
      coords.value = data.coords;
      shortDescRu.value = data.shortDescriptionRu;
      shortDescEn.value = data.shortDescriptionEn;
      sortValue.value = data.sort;
    }
  });
}

</script>
