export const contentEventCardTypeEnum = {
    Speaker: 'speakers',
    Moderator: 'moderators',
    Lecturer: 'lecturers',
    Organizer: 'organizers',
    Partner: 'partners',
    PointRoute: 'pointRoutes',
    Language: 'languages',
    Artists: 'artists',
    Durations: 'durations',
    Thematic: 'thematics',
};
