import {defineStore} from 'pinia'
import axios from "axios";
import router from '../router'
import setHeaderAuth from "../utilits/setHeaderAuth.js";
import {ref} from 'vue';
import {serviceLogout} from "../service/user/user.js";
import {createTokenPromise} from "../service/handleAsync.js";

export const useAuthStore = defineStore("authStore", () => {
    const authUser = ref(JSON.parse(localStorage.getItem('authUser')));

    const error = ref(null);
    const getProfile = async () => {
        return await axios.get('/exhibitors/user/profile');
    };
    const login = async (email, password) => {
        error.value = null;

        await axios.post('/exhibitors/login', {
            email: email,
            password: password
        }).then((r) => {
            let response = r.data;
            if (response.success) {
                authUser.value = response.data.user;
                localStorage.setItem('authUser', JSON.stringify(response.data.user));
                localStorage.setItem('token', response.data.token);
                setHeaderAuth(response.data.token)

                let back = localStorage.getItem('auth_back');
                router.push(back ? back : {name: 'Dashboard'})
            } else {
                error.value = response.error.message
            }

        }).catch((e) => {
            console.log(e);
        });
    };

    const logout = () => {
        authUser.value = null;

        serviceLogout().then(response => {
            localStorage.removeItem('authUser');
            localStorage.removeItem('token');
            router.push({name: 'Login'}).then();
            createTokenPromise();
        });
    }

    const clearAuthUser = () => {
        authUser.value = null;
        localStorage.removeItem('authUser');
        localStorage.removeItem('token');
    }

    return {
        authUser,
        error,
        login,
        logout,
        getProfile,
        clearAuthUser
    }
})
