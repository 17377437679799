import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceSetting() {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/app/settings`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceSettingUpdate(id, data) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/app/settings/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data)
    }));
}
