<template>
  <div class="grid grid-cols-2 gap-7 px-7 pt-7">
    <div class="col-span-1 mb-4 xl:mb-2">
      <div
          v-if="saveMessageResponse"
          style="z-index: 100; top: 80px"
          id="toast-top-right"
          :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
          class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
          role="alert">
        <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
      </div>

      <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">{{ $t('common.contour_type_edit') }}</h1>
            <p class="mb-2" v-if="checkRole('admin')">
              <router-link :to="{name: 'UserLog', query: {object: 'App\\Models\\Lists\\ListContour', id: id}}" target="_blank" class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">{{ $t('common.toLogs') }}</router-link>
            </p>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <div class="p-7 pb-12 border-b border-gray-200">
                  <form action="">
                    <div class="grid gap-4 mb-4 sm:grid-cols-2">
                      <div class="sm:col-span-2">
                        <input-text
                            v-model="valueName"
                            @blur="onHandleNameBlur"
                            :label="$t('directoryDocuments.name')"/>
                        <p
                            v-if="errorName"
                            class="mt-2 text-sm text-red-600">
                          {{ errorName }}
                        </p>
                      </div>
                      <div class="sm:col-span-2">
                        <label class="block mb-2 font-medium text-basic-gray">Тип карточки</label>
                        <Multiselect v-model="selectedDocType"
                                     :max="checkRole(['admin', 'manager']) ? -1 : 1"
                                     :options="docType"
                                     :mode="'tags'"
                                     :valueProp="'id'"
                                     track-by="name"
                                     label="name"
                                     :close-on-select="false"
                                     :searchable="true"
                        />
                      </div>
                      <div class="sm:col-span-2">
                        <input-text v-model="valueSort" :label="$t('directoryDocuments.sort')"/>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <drop-file v-if="!isFile" v-model="file" :type="fileType" :single="true" />

                      <div v-if="isFile">
                        <div class="flex">
                          <span class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4">{{ existingFile.name }}</span>
                          <button @click.prevent="deleteFile()" type="button" class="hover:opacity-60">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="p-7 flex justify-end">
                  <spinner v-if="isEntitySaved" />
                  <button
                      v-if="!isEntitySaved"
                      @click.prevent="onHandleSave"
                          class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                    <span>{{ $t('common.save') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import {useRoute, useRouter} from 'vue-router'
import {
  serviceContourEdit,
  serviceContourFindId,
  serviceContourUniqueName
} from "../../../../service/lists/serviceListContour.js";
import Spinner from "../../../flowbite/spinner.vue";
import {serviceCoordinationTypeList} from "../../../../service/lists/serviceListCoordinationType.js";
import Multiselect from '@vueform/multiselect';
import {checkRole} from "../../../../utilits/functions";
import DropFile from "../../../flowbite/form/dropFile.vue";

const isEntitySaved = ref(false);
const valueName = ref('');
const errorName = ref(null);
const saveMessageResponse = ref(null);

const id = ref(null);
const valueSort = ref(500);
const docType = ref([]);
const selectedDocType = ref([]);

const file = ref(null);
// Проверка, есть ли файл
const isFile = ref(false);
const fileType = {
  extensions: ["doc", "docx", "xlsx", "pdf", "png", "svg", "mp3", "mp4"],
  max_filesize: 10,
};
const existingFile = ref(null);
const isFileAction = ref(null);

// Первоначальные данные
const route = useRoute()
const router = useRouter()

serviceCoordinationTypeList().then(response => {
  docType.value = response?.data || [];
});

const deleteFile = () => {
  isFile.value = false;
  existingFile.value = null;
  file.value = null;
  isFileAction.value = 'delete';
}

serviceContourFindId(route.params.id).then(response => {
  const data = response?.data;
  if (!data) {
    return null;
  }

  id.value = data.id;
  valueName.value = data.name;
  valueSort.value = data.sort;
  selectedDocType.value = data?.documentTypes?.map(item => item.id) || [];

  isFile.value = !!data.file;

  existingFile.value = {
    name: getFileNameFromUrl(data.file),
    url: data.file,
  };
});

const onHandleSave = () => {
  isEntitySaved.value = true;

  errorName.value = !valueName.value?.length ? "Не должно быть пустым" : null;
  if (errorName.value) {
    return null;
  }

  isEntitySaved.value = true;

  serviceContourEdit(route.params.id, {name: valueName.value, sort: valueSort.value, documentTypes: selectedDocType.value, file: file.value, isFileAction: file.value ? null : isFileAction.value}).then(response => {
    isEntitySaved.value = false;

    const status = response?.success;
    const message = response?.success ? 'Успешно сохранено' : 'Ошибка, данные не были сохранены';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      saveMessageResponse.value = null;
    }, 3000);
  });
};

const getFileNameFromUrl = (url) => {
  const segments = url.split('/');
  return segments[segments.length - 1];
}

const onHandleNameBlur = () => {
  if (!valueName.value?.length) {
    return null;
  }

  serviceContourUniqueName(valueName.value).then(response => {
    errorName.value = response.data ? 'Такой тип контура уже существует' : null;
  })
}

</script>
