<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div v-if="entityStore.entity">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ entityStore.error }}
                        </div>

                        <div v-if="isEntitySaved" class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                          {{ $t('common.documentSaved') }}
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-3">
                          <div class="sm:col-span-2">
                            <template v-if="canEditAttribute('name')">
                              <template v-if="!entityStore.entity.id || isEditName">
                                <input-text v-model="entityStore.entity.name" :label="$t('document.documentName') + ':'"/>
                                <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                              </template>
                              <template v-else>
                                <span class="text-3xl font-medium mr-4">{{ entityStore.entity.name }}</span>
                                <button type="button" @click.prevent="isEditName = true">
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.586 1.58616C10.7705 1.39514 10.9912 1.24278 11.2352 1.13796C11.4792 1.03314 11.7416 0.977969 12.0072 0.975661C12.2728 0.973354 12.5361 1.02396 12.7819 1.12452C13.0277 1.22508 13.251 1.37359 13.4388 1.56137C13.6266 1.74916 13.7751 1.97246 13.8756 2.21825C13.9762 2.46405 14.0268 2.72741 14.0245 2.99296C14.0222 3.25852 13.967 3.52096 13.8622 3.76497C13.7574 4.00898 13.605 4.22967 13.414 4.41416L12.621 5.20716L9.793 2.37916L10.586 1.58616ZM8.379 3.79316L0 12.1722V15.0002H2.828L11.208 6.62116L8.378 3.79316H8.379Z" fill="#111928"/>
                                  </svg>
                                </button>
                              </template>
                            </template>
                            <template v-else>
                              <span class="text-3xl font-medium mr-4">{{ entityStore.entity.name }}</span>
                            </template>

                            <div class="mt-2 text-sm" v-if="entityStore.entity.id">{{ entityStore.entity.user.name }}</div>
                          </div>
                          <div class="sm:col-span-1" v-if="entityStore.entity.id">
                            <div class="text-right text-sm">
                              <p class="mb-2"><span class="text-gray-500">{{ $t('document.created') }}:</span> {{ moment(entityStore.entity.created_at).format('DD.MM.YYYY') }}</p>
                              <p class="mb-2"><span class="text-gray-500">{{ $t('document.updated') }}:</span> {{ moment(entityStore.entity.updated_at).format('DD.MM.YYYY') }}</p>
                              <p class="mb-2" v-if="entityStore.entity.deadline"><span class="text-gray-500">{{ $t('document.deadline') }}:</span> {{ moment(entityStore.entity.deadline).format('DD.MM.YYYY') }}</p>
                              <p class="mb-2">
                                <router-link :to="{name: 'UserLog', query: {object: 'App\\Models\\Document', id: entityStore.entity.id}}" target="_blank" class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">{{ $t('common.toLogs') }}</router-link>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2" v-if="entityStore.entity.status">
                          <div class="sm:col-span-2">
                            <div class="inline-block px-4 py-1 rounded-xl mr-4 whitespace-nowrap opacity-100 cursor-default" :class="['bg-status-' + entityStore.entity.status]">
                              <span>{{ statusListAll.find(item => item.id === entityStore.entity.status).name }}</span>
                            </div>
                          </div>
                        </div>

                        <div
                            v-if="contour"
                            class="flex mt-6 mb-4">
                          <label class="block mb-2 mr-1 font-medium text-basic-gray">{{ $t('common.contour') }}:</label>
                          <div
                              class="text-black focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium"
                          >
                            {{ contour?.name || '-' }}
                          </div>
                        </div>

                        <hr class="mb-4">

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите контур:</label>
                          <Multiselect
                              v-model="selectedContours"
                              :options="contours"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="name"
                              label="name"
                              :close-on-select="false"
                              :searchable="true" />
                          <p v-if="docSelectedContoursError" class="mt-2 text-sm text-red-600"> {{ docSelectedContoursError }} </p>
                        </div>

                        <table class="mb-4" v-if="!canEditAttribute('type') || !canEditAttribute('doc_types') || (!canEditAttribute('exhibitor_id') && entityStore.entity.exhibitor)">
                          <tr v-if="!canEditAttribute('type')">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.type') }}:</td>
                            <td class="pb-3 text-sm">{{ typeList.find(type => type.id === entityStore.entity.type).name }}</td>
                          </tr>
                          <tr v-if="!canEditAttribute('doc_types') && entityStore.entity.doc_types_info.length">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.document') }}:</td>
                            <td class="pb-3 text-sm">{{ entityStore.entity.doc_types_info.map(item => documentTypeListAll.find(documentType => documentType.id === item.id).name).join(', ') }}</td>
                          </tr>
                          <tr v-if="entityStore.entity.exhibitor">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.assigned') }}:</td>
                            <td class="pb-3 text-sm">{{ entityStore.entity.exhibitor.name }}</td>
                          </tr>
                          <tr v-if="entityStore.entity.assigned">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.assignedManager') }}:</td>
                            <td class="pb-3 text-sm">{{ entityStore.entity.assigned.name }}</td>
                          </tr>
                        </table>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea v-model="entityStore.entity.description" :label="$t('document.description') + ':'" :readonly="!canEditAttribute('description')"/>
                          </div>
                        </div>

                        <div v-for="fileBlock in entityStore.entity.files">
                          <div class="grid gap-4 mb-4 sm:grid-cols-2 p-4" :class="{'bg-[#effbe3] border-[#effbe3]': entityStore.entity.doc_types_agreed.includes(fileBlock.doc_type), 'border': true}">
                            <div class="sm:col-span-1">
                              <div class="flex items-center mb-2">
                                <input :id="'doc-type-files-' + fileBlock.doc_type" v-model="entityStore.entity.doc_types_agreed" :value="fileBlock.doc_type" type="checkbox" class="w-4 h-4 bg-gray-50 mr-2 rounded border-gray-300 focus:ring-3 checked:bg-primary focus:ring-primary-300">
                                <label :for="'doc-type-files-' + fileBlock.doc_type" class="block font-medium text-basic-gray">{{ $t('document.files') }} «{{ documentTypeListAll.find(documentType => documentType.id === fileBlock.doc_type)?.name }}»:</label>
                              </div>

                              <div v-if="fileBlock.files.length" class="mt-4">
                                <div v-for="file in fileBlock.files" class="mb-3">
                                  <div class="flex">
                                    <a :href="file.src" target="_blank" class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4 hover:text-blue-600" :title="file.original_name">{{ file.original_name }}<div class="text-xs">{{ moment(file.created_at).format('DD.MM.YYYY HH:mm') }}</div></a>
                                    <span class="text-xs text-gray-400 mr-2">{{ humanFileSize(file.size) }}</span>
                                    <button @click.prevent="deleteFile(file.id)" type="button" class="hover:opacity-60 h-5" v-if="canEditAttribute('file_delete')">
                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="sm:col-span-1" v-if="!docTypesAgreed.includes(fileBlock.doc_type) && canEditAttribute('file_upload')">
                              <div class="sm:col-span-2">
                                <drop-file v-model="fileBlock.newFiles" :type="documentTypeListAll.find(documentType => documentType.id === fileBlock.doc_type)"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <p v-if="v$.files.$error" class="mb-2 mt-2 text-sm text-red-600"> {{ v$.files.$errors[0].$message }} </p>
                      </form>
                    </div>
                    <div class="p-7 flex justify-between">
                      <div>
                        <div class="flex flex-wrap">
                          <div
                              class="flex items-center mb-4" v-for="option in entityStore.entity.typeAndProgress">
                            <label
                                @click.prevent="saveEntity(option.processId)"
                                class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80" :class="['bg-status-' + option.id]">
                              <span>{{ option.name }}</span>
                            </label>
                          </div>
                          <div
                              class="flex items-center mb-4">
                            <label
                                @click.prevent="saveEntity(null)"
                                class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80 bg-blue-300">
                              <span>Сохранить</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center p-7 border-b border-gray-200">
            <div class="text-3xl font-medium">
              {{ $t('document.remarksTitle') }}
            </div>
          </div>
          <div class="p-7 pt-4">
            <div class="flex-1 justify-between flex flex-col h-[50vh]">
              <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                <div class="chat-message" v-for="message in entityStore.entity.messages">
                  <div class="flex items-end" :class="{'justify-end': !message.is_answer}">
                    <div class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                      <div>
                        <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none" :class="{'bg-gray-300 text-gray-600': message.is_answer, 'bg-blue-600 text-white': !message.is_answer}">
                          <div class="text-xs">
                            {{ message.user.name }}
                          </div>
                          <div class="text-xs">
                            {{ moment(message.created_at).format('DD.MM.YYYY HH:mm') }}
                          </div>
                          <div class="text-sm">
                            {{ message.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0" v-if="entityStore.entity.id && canEditAttribute('remarks')">
                <div class="relative flex">
                    <textarea
                        :placeholder="$t('chat.write_message')"
                        v-model="entityStore.entity.remarks"
                        rows="5"
                        class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                    ></textarea>
                </div>
                <div class="items-center mt-2" v-if="canEditAttribute('remarks_button')">
                  <button type="button" @click.prevent="sendMessage" :disabled="!entityStore.entity.remarks.trim().length" :class="{'opacity-50': !entityStore.entity.remarks.trim().length}" class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                    <template v-if="!loadDataMessage">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                      </svg>
                    </template>
                    <template v-else>
                      <svg aria-hidden="true" class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRegistryStore} from "../../../../stores/RegistryStore.js";
import Spinner from "../../../flowbite/spinner.vue";
import {computed, reactive, ref, watch, onUpdated} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {helpers, required} from '../../../../utilits/custom-validators.js'
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../flowbite/form/dropFile.vue";
import moment from "moment";
import Multiselect from '@vueform/multiselect'
import i18n from "../../../../i18n.js";
import {serviceSelectedUserContours} from "../../../../service/user/user.js";
import {serviceContourList} from "../../../../service/lists/serviceListDocumentType.js";

const entityStore = useRegistryStore()
entityStore.error = null

const loadDataMessage = ref(false)

const isEditName = ref(false)
const loadData = ref(true)
const isEntitySaved = ref(false)

const contour = ref(null);

const statusList = ref([])
const statusListAll = ref([])
const typeList = ref([])
const typeListAllowed = ref([])
const documentTypeList = ref([])
const documentTypeListAll = ref([])
const changeStatusList = ref({})
const createStatusList = ref({})
const exhibitors = ref([])
const filteredExhibitors = ref([])
const exhibitorTypes = ref([])

const selectedContours = ref([]);
const contours = ref([]);
const docSelectedContoursError = ref('');

// Первоначальные данные
const docTypesAgreed = ref([])
const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;

const route = useRoute()
const router = useRouter()

const messageEntity = ref({
  document_id: route.params.id,
  message: '',
});

serviceContourList().then(response => {
  documentTypeList.value = response?.data || [];
})

const userContours = () => {
  serviceSelectedUserContours().then(response => {
    const items = response?.data || [];

    if (items.length) {
      items?.map((type, index) => {
        type?.userDocTypes?.map(item => {
          if (item.key === 'document') {
            contours.value.push(items[index]);
          }
        });
      });
    }

    selectedContours.value = entityStore.entity?.contour ? [entityStore.entity?.contour?.id] : [];
    contour.value = entityStore.entity?.contour;
  });
}

entityStore.getDirectories().then(() => {
  statusList.value = [...statusList.value, ...entityStore.statusListAll]
  statusListAll.value = [...statusListAll.value, ...entityStore.statusListAll]
  // documentTypeList.value = [...documentTypeList.value, ...entityStore.documentTypeList]
  documentTypeListAll.value = [...documentTypeListAll.value, ...entityStore.documentTypeListAll]
  typeList.value = [...typeList.value, ...entityStore.typeList]
  typeListAllowed.value = [...typeListAllowed.value, ...entityStore.typeListAllowed]
  changeStatusList.value = entityStore.changeStatusList
  createStatusList.value = entityStore.createStatusList
  exhibitorTypes.value = entityStore.exhibitorTypes

  exhibitors.value.push({
    id: -1,
    name: i18n.global.t('document.chooseAll'),
    type_id: 0
  })
  exhibitors.value = [...exhibitors.value, ...entityStore.exhibitors]

  filteredExhibitors.value = exhibitors.value

  if (route.params.id > 0) {
    entityStore.getEntityById(route.params.id).then(() => {
      userContours();

      loadData.value = false

      setAvailableStatusList()

      docTypesAgreed.value = entityStore.entity.doc_types_agreed

      selectedContours.value = entityStore.entity?.contour ? [entityStore.entity?.contour?.id] : [];
      contour.value = entityStore.entity?.contour;
    });

  } else {
    userContours();

    entityStore.setEmptyEntity().then(() => {
      loadData.value = false

      statusList.value = statusList.value.filter(status => createStatusList.value.includes(status.id))
      entityStore.entity.status = statusList.value[0]?.id
      entityStore.entity.type = 0;

      if (exhibitorTypes.value.length === 1) {
        entityStore.entity.draft_exhibitor_type_id.push(exhibitorTypes.value[0]?.id)
      }
    })
  }
})

onUpdated(() => {
  setChatScroll()
})

// Валидация
const exhibitorIdRule = {
  exhibitorRequired: helpers.withMessage(i18n.global.t('common.validation.chooseExponent'), () => {
    return !canEditAttribute('exhibitors') || entityStore.entity.draft_exhibitors.length
  })
};

const filesRule = {
  filesRule: helpers.withMessage(i18n.global.t('common.validation.uploadFiles'), () => {
    return !entityStore.entity.files.filter((files) => {
      return !files.files.length && !files.newFiles.length
    }).length
  })
}

const docTypesRule = {
  exhibitorRequired: helpers.withMessage(i18n.global.t('common.validation.required'), () => {
    if (!canEditAttribute('doc_types') || entityStore.entity.type === 2) {
      return true
    }

    return entityStore.entity.doc_types.length
  })
}

const rules = computed(() => ({
  name: {required},
  type: {required},
  doc_types: docTypesRule,
  status: {required},
  draft_exhibitors: exhibitorIdRule,
  files: filesRule,
}));

let state = reactive({
  name: '',
  type: '',
  doc_types: [],
  status: '',
  exhibitor_id: '',
  description: '',
  remarks: '',
  deadline: '',
  files: [],
  exhibitors: [],
});

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.type = entityStore.entity.type;
    state.doc_types = entityStore.entity.doc_types;
    state.status = entityStore.entity.status;
    state.exhibitor_id = entityStore.entity.exhibitor_id;
    state.description	= entityStore.entity.description;
    state.remarks	= entityStore.entity.remarks;
    state.deadline	= entityStore.entity.deadline;
    state.files	= entityStore.entity.files;

    if (entityStore.entity.doc_types !== undefined) {
      createFileBlocks()
    }
  }
})

const v$ = useVuelidate(rules, state)

// Работа с данными
const createFileBlocks = () => {
  entityStore.entity.doc_types.forEach((doc_type) => {
    if (!entityStore.entity.files.find(item => item.doc_type === doc_type)) {
      entityStore.entity.files.push({
        doc_type: doc_type,
        files: [],
        newFiles: [],
      })
    }
  })

  entityStore.entity.files.forEach((files, index) => {
    if (!entityStore.entity.doc_types.includes(files.doc_type) && !files.files.length) {
      entityStore.entity.files.splice(index, 1)
    }
  })
}

const saveEntity = (newStatus) => {
  let contourValue = null;
  if (!route.params.type || entityStore.entity.type === 1) {
    contourValue = selectedContours.value ? selectedContours.value[0] : null;
    if (!contourValue) {
      docSelectedContoursError.value = 'Вы не выбрали контур';

      return null;
    }
  }

  docSelectedContoursError.value = '';

  entityStore.error = null

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true

    let isNew = entityStore.entity.id === undefined

    entityStore.entity.new_status = newStatus
    entityStore.entity.contour = contourValue;

    entityStore.entity.type = 1;

    entityStore.storeEntity(entityStore.entity).then(() => {
      if (!entityStore.error && isNew) {
        router.push({name: 'RegistryEdit', params: {id: entityStore.entity.id}})
      }

      loadData.value = false

      if (!entityStore.error) {
        setAvailableStatusList()

        isEntitySaved.value = true

        setTimeout(() => {
          isEntitySaved.value = false
        }, 4000)
      }
    })

    v$.value.$reset()
  }
}

const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

const deleteFile = (fileId) => {
  if (confirm('Вы действительно хотите удалить файл?')) {
    entityStore.deleteFile(fileId).then(() => {
    });
  }
}

const setChatScroll = () => {
  let el = document.getElementById('messages')

  if (el) {
    el.scrollTop = el.scrollHeight
  }
}

const setAvailableStatusList = () => {
  let availableStatusList = changeStatusList.value[entityStore.entity.type][entityStore.entity.status]

  if (typeof availableStatusList === 'undefined') {
    availableStatusList = []
  }

  statusList.value = statusListAll.value.filter(status => availableStatusList.includes(status.id) || status.id === entityStore.entity.status)
}

const canEditAttribute = (attribute) => {
  if (!entityStore.entity.id) {
    return true
  }

  return entityStore.entity.permissions.edit[attribute];
}

const chooseDraftExhibitors = (option) => {
  if (option === -1) {
    entityStore.entity.draft_exhibitors = filteredExhibitors.value.map(item => item.id).filter(item => item !== -1)
  }
}

const filterDraftExhibitorsByType = (exhibitorsTypeIds) => {
  filteredExhibitors.value = exhibitors.value.filter((item) => {
    return item.id === -1 || !exhibitorsTypeIds.length || exhibitorsTypeIds.includes(item.type_id)
  })

  let filteredIds = filteredExhibitors.value.map(item => item.id)

  entityStore.entity.draft_exhibitors = entityStore.entity.draft_exhibitors.filter(id => filteredIds.includes(id))
}

const sendMessage = () => {
  entityStore.error = null

  messageEntity.value.message = entityStore.entity.remarks

  if (messageEntity.value.message) {
    loadDataMessage.value = true

    entityStore.sendMessage(messageEntity.value).then(() => {
      loadDataMessage.value = false
      messageEntity.value.message = ''
      entityStore.entity.remarks = ''
    })
  }
}
</script>

<style src="../../../../../node_modules/@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>
