<template>
  <spinner v-if="isLoadingData"/>

  <div v-if="!isLoadingData" class="grid grid-cols-2 gap-7 px-7 pt-7">
    <div class="col-span-1 mb-4 xl:mb-2">

      <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <div class="p-7 pb-2 border-b border-gray-200">
                  <form action="">
                    <table class="mb-4">
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Идентификатор:</td>
                        <td class="pb-3 text-sm">{{ id }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Наименование (RU):</td>
                        <td class="pb-3 text-sm">{{ nameRu }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Наименование (EN):</td>
                        <td class="pb-3 text-sm">{{ nameEn || '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Краткое описание (RU):</td>
                        <td class="pb-3 text-sm">{{ shortDescriptionRu }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Краткое описание (EN):</td>
                        <td class="pb-3 text-sm">{{ shortDescriptionEn || '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Ссылка (RU):</td>
                        <td class="pb-3 text-sm">{{ linkRu }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Ссылка (EN):</td>
                        <td class="pb-3 text-sm">{{ linkEn || '-' }}</td>
                      </tr>
                      <tr v-if="image">
                        <td class="pb-3 font-medium text-basic-gray pr-12">Изображение:</td>
                        <td class="pb-3 text-sm"><img :src="image"></td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12"> Выводить на главной:</td>
                        <td class="pb-3 text-sm">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="true"
                                id="bordered-checkbox"
                                type="checkbox"
                                v-model="isMain"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox" class="font-medium text-basic-gray dark:text-gray-300">
                                Выводить на главной
                              </label>
                              <p
                                  id="helper-checkbox-text"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{isMain ? 'Да' : 'Нет' }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12"> Выводить на странице "О выставке":</td>
                        <td class="pb-3 text-sm">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="true"
                                id="bordered-checkbox"
                                type="checkbox"
                                v-model="isAbout"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox" class="font-medium text-basic-gray dark:text-gray-300">
                                Выводить на странице "О выставке"
                              </label>
                              <p
                                  id="helper-checkbox-text"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{isAbout ? 'Да' : 'Нет' }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12"> Выводить в партнёрах:</td>
                        <td class="pb-3 text-sm">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="true"
                                id="bordered-checkbox"
                                type="checkbox"
                                v-model="isPartners"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox" class="font-medium text-basic-gray dark:text-gray-300">
                                Выводить в партнёрах
                              </label>
                              <p
                                  id="helper-checkbox-text"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{isPartners ? 'Да' : 'Нет' }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Активность (RU):</td>
                        <td class="pb-3 text-sm">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="true"
                                id="bordered-checkbox"
                                type="checkbox"
                                v-model="isActiveRu"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (RU)
                              </label>
                              <p
                                  id="helper-checkbox-text"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{isActiveRu ? 'Активна (Да)' : 'Не активна (Нет)' }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Активность (EN):</td>
                        <td class="pb-3 text-sm">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="true"
                                id="bordered-checkbox-1"
                                type="checkbox"
                                v-model="isActiveEn"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-1" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (EN)
                              </label>
                              <p
                                  id="helper-checkbox-text-1"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)' }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Порядок сортировки:</td>
                        <td class="pb-3 text-sm">{{ sortValue || '-' }}</td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import Spinner from "../../../../flowbite/spinner.vue";
import {useRoute, useRouter} from 'vue-router'
import {servicePartnerFindById} from "../../../../../service/lists/serviceListPartner.js";

const route = useRoute();
const router = useRouter();

const isLoadingData = ref(true);

const id = ref(null);
const nameRu = ref(null);
const nameEn = ref(null);
const shortDescriptionRu = ref(null);
const shortDescriptionEn = ref(null);
const authorImageRu = ref(null);
const authorImageEn = ref(null);
const linkRu = ref(null);
const linkEn = ref(null);
const isMain = ref(false);
const isAbout = ref(false);
const isPartners = ref(false);
const isActiveRu = ref(false);
const isActiveEn = ref(false);
const image = ref(null);

const sortValue = ref(null);

if (route.params.id) {
  servicePartnerFindById(route.params.id).then(response => {
    isLoadingData.value = false;

    if (response.success && response.data) {
      const data = response.data;

      nameRu.value = data.titleRu;
      nameEn.value = data.titleEn;
      shortDescriptionRu.value = data.shortDescriptionRu;
      shortDescriptionEn.value = data.shortDescriptionEn;
      authorImageRu.value = data.authorImageRu;
      authorImageEn.value = data.authorImageEn;

      linkRu.value = data.linkRu;
      linkEn.value = data.linkEn;

      isMain.value = data.isMain;
      isAbout.value = data.isAbout;
      isPartners.value = data.isPartners;

      isActiveRu.value = data.activeRu;
      isActiveEn.value = data.activeEn;
      image.value = data.image;

      id.value = data.id;
      sortValue.value = data.sort;
    }
  });
}

</script>
