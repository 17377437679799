import {getToken, handleAsync} from "../handleAsync.js";
import axios from "axios";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceContourList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/contours` + getQueryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContourUniqueName(name) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/contours/unique?name=` + name, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContourFindId(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/contours/` + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContourEdit(id, data) {
    const token = await getToken();

    return await axios.post(`${url}/lists/contours/${id}/update`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: r?.status === 204 || false,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceContourSave(data) {
    const token = await getToken();

    return await axios.post(`${url}/lists/contours`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceContourMultiDelete(ids) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/contours/multi-delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({ids}),
    }));
}

function getQueryParams(params) {
    let queryParams = params?.page ? `?page=${params.page}` : '';
    if (params?.limit)
        queryParams += (queryParams ? '&' : '?') + `limit=${params.limit}`;
    if (params?.search)
        queryParams += (queryParams ? '&' : '?') + `search=${params.search}`;

    return queryParams;
}
