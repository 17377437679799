<template>
  <div v-if="isLoadingCreate">
    <spinner/>
    <div style="text-align: center; color: #afafaf; margin-top: -30px;">Создание мероприятие ...</div>
  </div>

  <div
      v-if="saveMessageResponse"
      style="z-index: 100; top: 80px"
      id="toast-top-right"
      :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
      class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
      role="alert">
    <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
  </div>

  <div v-if="!isLoadingCreate">
    <div class="grid card-event grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div class="mb-5">
                        <label class="block mb-2 font-medium text-basic-gray">Выберите тип карточки мероприятия
                          *</label>
                        <Multiselect
                            v-model="selectedEventTypeCard"
                            :options="eventTypeCards"
                            :mode="'single'"
                            :valueProp="'id'"
                            max="1"
                            track-by="name"
                            label="name"
                            :close-on-select="true"
                            :searchable="true"/>
                      </div>

                      <div v-if="selectedEventTypeCard?.length && !isBusinessRoleError">
                        <div class="tabs">
                          <div
                              :class="{'active': tabKey === 'main'}"
                              @click="onHandleClickTabKey('main')"
                              class="tab">
                            <div class="tab-label">
                              Основное
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && item.code === 'cultural_program')"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Artists}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Artists)"
                              class="tab">
                            <div class="tab-label">
                              Артисты {{ artistItems.length ? `(${artistItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && (item.code === 'business_event' || item.code === 'excursion'))"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Speaker}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Speaker)"
                              class="tab">
                            <div class="tab-label">
                              Спикеры {{ speakerItems.length ? `(${speakerItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && (item.code === 'business_event' || item.code === 'excursion'))"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Lecturer}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Lecturer)"
                              class="tab">
                            <div class="tab-label">
                              Докладчики {{ lecturersItems.length ? `(${lecturersItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && (item.code === 'business_event' || item.code === 'excursion'))"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Moderator}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Moderator)"
                              class="tab">
                            <div class="tab-label">
                              Модераторы {{ moderatorItems.length ? `(${moderatorItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && item.code === 'excursion')"
                              :class="{'active': tabKey === contentEventCardTypeEnum.PointRoute}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.PointRoute)"
                              class="tab">
                            <div class="tab-label">
                              Точки маршрута {{ pointRouteItems.length ? `(${pointRouteItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              hidden
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && (item.code === 'business_event' || item.code === 'excursion'))"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Organizer}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Organizer)"
                              class="tab">
                            <div class="tab-label">
                              Организаторы {{ eventOrganizerItems.length ? `(${eventOrganizerItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && item.code === 'excursion')"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Durations}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Durations)"
                              class="tab">
                            <div class="tab-label">
                              Продолжительность для экскурсии
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && item.code === 'excursion')"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Partner}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Partner)"
                              class="tab">
                            <div class="tab-label">
                              Партнеры {{ partnerItems.length ? `(${partnerItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && item.code === 'excursion')"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Language}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Language)"
                              class="tab">
                            <div class="tab-label">
                              Языки для экскурсий {{ languageItems.length ? `(${languageItems.length})` : '' }}
                            </div>
                          </div>
                          <div
                              v-if="eventTypeCards?.find(item => item.id === selectedEventTypeCard && item.code === 'business_event')"
                              :class="{'active': tabKey === contentEventCardTypeEnum.Thematic}"
                              @click="onHandleClickTabKey(contentEventCardTypeEnum.Thematic)"
                              class="tab">
                            <div class="tab-label">
                              Тематика деловой недели
                            </div>
                          </div>
                          <div
                              :class="{'active': tabKey === 'session'}"
                              @click="onHandleClickTabKey('session')"
                              class="tab">
                            <div class="tab-label">
                              Сеансы {{ sessions.length ? `(${sessions.length})` : '' }}
                            </div>
                          </div>
                          <div
                              :class="{'active': tabKey === 'sliders'}"
                              @click="onHandleClickTabKey('sliders')"
                              class="tab">
                            <div class="tab-label">
                              Слайдер {{ sliders.length ? `(${sliders.length})` : '' }}
                            </div>
                          </div>
                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Speaker">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите Спикера</label>
                            <Multiselect
                                v-model="selectedSpeakers"
                                :options="officials"
                                @select="id => onHandleSelectOfficial(id, contentEventCardTypeEnum.Speaker)"
                                @deselect="id => onHandleDeSelectOfficial(id, contentEventCardTypeEnum.Speaker)"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="true"
                                :searchable="true"/>
                          </div>
                          <div
                              @click="onHandleClickOfficialCreate(contentEventCardTypeEnum.Speaker)"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового спикера
                            </div>
                          </div>

                          <div v-if="speakerItems?.length">
                            <div
                                style="margin-top: 30px"
                                v-for="(speaker, index) in speakerItems"
                                :key="speaker?.id || index">
                              <EventSpeaker
                                  :index="index"
                                  @click:onDelete="onHandleClickDeleteCard"
                                  :id="speaker?.id"
                                  :key="index"
                                  :typeSpeaker="'speaker'"
                                  :typeSpeakerError="speaker?.typeSpeakerError"
                                  @update:typeSpeaker="speaker.typeSpeaker = $event"
                                  :nameRu="speaker.nameRu"
                                  :nameRuError="speaker.nameRuError"
                                  :nameEn="speaker.nameEn"
                                  :nameEnError="speaker.nameEnError"
                                  @update:nameRu="speaker.nameRu = $event"
                                  @update:nameEn="speaker.nameEn = $event"
                                  :descriptionRu="speaker.descriptionRu"
                                  :descriptionRuError="speaker.descriptionRuError"
                                  :descriptionEn="speaker.descriptionEn"
                                  :descriptionEnError="speaker.descriptionEnError"
                                  @update:descriptionRu="speaker.descriptionRu = $event"
                                  @update:descriptionEn="speaker.descriptionEn = $event"
                                  :positionRu="speaker.positionRu"
                                  :positionRuError="speaker.positionRuError"
                                  :positionEn="speaker.positionEn"
                                  :positionEnError="speaker.positionEnError"
                                  @update:positionRu="speaker.positionRu = $event"
                                  @update:positionEn="speaker.positionEn = $event"
                                  :image="speaker.image"
                                  :imageError="speaker.imageError"
                                  @update:image="speaker.image = $event"
                                  :authorRu="speaker.authorRu"
                                  :authorRuError="speaker.authorRuError"
                                  :authorEn="speaker.authorEn"
                                  :authorEnError="speaker.authorEnError"
                                  @update:authorRu="speaker.authorRu = $event"
                                  @update:authorEn="speaker.authorEn = $event"
                                  :isDisable="!!speaker?.id"
                                  :isCreate="true"
                              />
                            </div>
                          </div>

                          <div
                              v-if="speakerItems?.length"
                              @click="onHandleClickOfficialCreate(contentEventCardTypeEnum.Speaker)"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового спикера
                            </div>
                          </div>

                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Lecturer">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите докладчика</label>
                            <Multiselect
                                v-model="selectedLecturers"
                                :options="officials"
                                @select="id => onHandleSelectOfficial(id, contentEventCardTypeEnum.Lecturer)"
                                @deselect="id => onHandleDeSelectOfficial(id, contentEventCardTypeEnum.Lecturer)"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="true"
                                :searchable="true"/>
                          </div>
                          <div
                              @click="onHandleClickOfficialCreate(contentEventCardTypeEnum.Lecturer)"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового докладчика
                            </div>
                          </div>

                          <div v-if="lecturersItems?.length">
                            <div
                                style="margin-top: 30px"
                                v-for="(lecturer, index) in lecturersItems"
                                :key="lecturer?.id || index">
                              <EventSpeaker
                                  :index="index"
                                  @click:onDelete="onHandleClickDeleteCard"
                                  :id="lecturer?.id"
                                  :key="index"
                                  :typeSpeaker="'lecturer'"
                                  :typeSpeakerError="lecturer?.typeSpeakerError"
                                  @update:typeSpeaker="lecturer.typeSpeaker = $event"
                                  :nameRu="lecturer.nameRu"
                                  :nameRuError="lecturer.nameRuError"
                                  :nameEn="lecturer.nameEn"
                                  :nameEnError="lecturer.nameEnError"
                                  @update:nameRu="lecturer.nameRu = $event"
                                  @update:nameEn="lecturer.nameEn = $event"
                                  :descriptionRu="lecturer.descriptionRu"
                                  :descriptionRuError="lecturer.descriptionRuError"
                                  :descriptionEn="lecturer.descriptionEn"
                                  :descriptionEnError="lecturer.descriptionEnError"
                                  @update:descriptionRu="lecturer.descriptionRu = $event"
                                  @update:descriptionEn="lecturer.descriptionEn = $event"
                                  :positionRu="lecturer.positionRu"
                                  :positionRuError="lecturer.positionRuError"
                                  :positionEn="lecturer.positionEn"
                                  :positionEnError="lecturer.positionEnError"
                                  @update:positionRu="lecturer.positionRu = $event"
                                  @update:positionEn="lecturer.positionEn = $event"
                                  :image="lecturer.image"
                                  :imageError="lecturer.imageError"
                                  @update:image="lecturer.image = $event"
                                  :authorRu="lecturer.authorRu"
                                  :authorRuError="lecturer.authorRuError"
                                  :authorEn="lecturer.authorEn"
                                  :authorEnError="lecturer.authorEnError"
                                  @update:authorRu="lecturer.authorRu = $event"
                                  @update:authorEn="lecturer.authorEn = $event"
                                  :isDisable="!!lecturer?.id"
                                  :isCreate="true"
                              />
                            </div>
                          </div>

                          <div
                              v-if="lecturersItems?.length"
                              @click="onHandleClickOfficialCreate(contentEventCardTypeEnum.Lecturer)"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового докладчика
                            </div>
                          </div>

                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Moderator">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите модератора</label>
                            <Multiselect
                                v-model="selectedModerators"
                                :options="officials"
                                @select="id => onHandleSelectOfficial(id, contentEventCardTypeEnum.Moderator)"
                                @deselect="id => onHandleDeSelectOfficial(id, contentEventCardTypeEnum.Moderator)"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="true"
                                :searchable="true"/>
                          </div>
                          <div
                              @click="onHandleClickOfficialCreate(contentEventCardTypeEnum.Moderator)"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового модератора
                            </div>
                          </div>

                          <div v-if="moderatorItems?.length">
                            <div
                                style="margin-top: 30px"
                                v-for="(moderator, index) in moderatorItems"
                                :key="moderator?.id || index">
                              <EventSpeaker
                                  :index="index"
                                  @click:onDelete="onHandleClickDeleteCard"
                                  :id="moderator?.id"
                                  :key="index"
                                  :typeSpeaker="'moderator'"
                                  :typeSpeakerError="moderator?.typeSpeakerError"
                                  @update:typeSpeaker="moderator.typeSpeaker = $event"
                                  :nameRu="moderator.nameRu"
                                  :nameRuError="moderator.nameRuError"
                                  :nameEn="moderator.nameEn"
                                  :nameEnError="moderator.nameEnError"
                                  @update:nameRu="moderator.nameRu = $event"
                                  @update:nameEn="moderator.nameEn = $event"
                                  :descriptionRu="moderator.descriptionRu"
                                  :descriptionRuError="moderator.descriptionRuError"
                                  :descriptionEn="moderator.descriptionEn"
                                  :descriptionEnError="moderator.descriptionEnError"
                                  @update:descriptionRu="moderator.descriptionRu = $event"
                                  @update:descriptionEn="moderator.descriptionEn = $event"
                                  :positionRu="moderator.positionRu"
                                  :positionRuError="moderator.positionRuError"
                                  :positionEn="moderator.positionEn"
                                  :positionEnError="moderator.positionEnError"
                                  @update:positionRu="moderator.positionRu = $event"
                                  @update:positionEn="moderator.positionEn = $event"
                                  :image="moderator.image"
                                  :imageError="moderator.imageError"
                                  @update:image="moderator.image = $event"
                                  :authorRu="moderator.authorRu"
                                  :authorRuError="moderator.authorRuError"
                                  :authorEn="moderator.authorEn"
                                  :authorEnError="moderator.authorEnError"
                                  @update:authorRu="moderator.authorRu = $event"
                                  @update:authorEn="moderator.authorEn = $event"
                                  :isDisable="!!moderator?.id"
                                  :isCreate="true"
                              />
                            </div>
                          </div>

                          <div
                              v-if="moderatorItems?.length"
                              @click="onHandleClickOfficialCreate(contentEventCardTypeEnum.Moderator)"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового модератора
                            </div>
                          </div>

                        </div>

                        <div hidden v-if="tabKey === contentEventCardTypeEnum.Organizer">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите организатора</label>
                            <Multiselect
                                v-model="selectedEventOrganizers"
                                :options="eventOrganizers"
                                @select="onHandleSelectOrganizer"
                                @deselect="onHandleDeSelectOrganizer"
                                :valueProp="'id'"
                                track-by="nameRu"
                                label="nameRu"
                                :close-on-select="true"
                                :searchable="true"/>
                          </div>

                          <div>
                            <div class="mb-5 mt-5">
                              <div>
                                <input-text
                                    :disabled="!!organizerId"
                                    v-model="organizerNameRu"
                                    label="Наименование (RU) *"/>
                                <p v-if="organizerNameRuError" class="mt-2 text-sm text-red-600">
                                  {{ organizerNameRuError }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!!organizerId"
                                    v-model="organizerNameEn"
                                    label="Наименование (EN)"/>
                                <p v-if="organizerNameEnError" class="mt-2 text-sm text-red-600">
                                  {{ organizerNameEnError }} </p>
                              </div>
                            </div>
                            <div class="mb-5 mt-5">
                              <div>
                                <input-text
                                    :disabled="!!organizerId"
                                    v-model="organizerLinkRu"
                                    label="Ссылка (RU) *"/>
                                <p v-if="organizerLinkRuError" class="mt-2 text-sm text-red-600">
                                  {{ organizerLinkRuError }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!!organizerId"
                                    v-model="organizerLinkEn"
                                    label="Ссылка (EN)"/>
                                <p v-if="organizerLinkEnError" class="mt-2 text-sm text-red-600">
                                  {{ organizerLinkEnError }} </p>
                              </div>
                            </div>
                            <div class="mb-5 mt-5">
                              <div>
                                <input-text
                                    :disabled="!!organizerId"
                                    v-model="organizerEmail"
                                    label="Email *"/>
                                <p v-if="organizerEmailError" class="mt-2 text-sm text-red-600">
                                  {{ organizerEmailError }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!!organizerId"
                                    v-model="organizerPhone"
                                    label="Номер телефона *"/>
                                <p v-if="organizerPhoneError" class="mt-2 text-sm text-red-600">
                                  {{ organizerPhoneError }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!!organizerId"
                                    v-model="organizerInn"
                                    label="ИНН *"/>
                                <p v-if="organizerInnError" class="mt-2 text-sm text-red-600">
                                  {{ organizerInnError }} </p>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Thematic">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите деловую тематику</label>
                            <Multiselect
                                v-model="selectedWeekThematics"
                                :options="weekThematics"
                                @select="onHandleSelectThematic"
                                @deselect="onHandleDeSelectThematic"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="true"
                                :searchable="true"/>
                          </div>

                          <div>
                            <div class="mb-5 mt-5">
                              <div>
                                <input-text
                                    :disabled="!!thematicId"
                                    v-model="thematicNameRu"
                                    label="Наименование (RU) *"/>
                                <p v-if="thematicNameRuError" class="mt-2 text-sm text-red-600"> {{
                                    thematicNameRuError
                                  }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!!thematicId"
                                    v-model="thematicNameEn"
                                    label="Наименование (EN)"/>
                                <p v-if="thematicNameEnError" class="mt-2 text-sm text-red-600"> {{
                                    thematicNameEnError
                                  }} </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.PointRoute">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите точки маршрутов</label>
                            <Multiselect
                                v-model="selectedPointRoutes"
                                :options="pointRoutes"
                                @select="onHandleSelectPointRoute"
                                @deselect="onHandleDeSelectPointRoute"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="false"
                                :searchable="true"/>
                          </div>
                          <div
                              @click="onHandleClickPointRouteCreate"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить новую точку маршрута
                            </div>
                          </div>

                          <div v-if="pointRouteItems?.length">
                            <div
                                style="margin-top: 30px"
                                v-for="(routePoint, index) in pointRouteItems"
                                :key="routePoint?.id || index">
                              <EventPointRoute
                                  :index="index"
                                  :id="routePoint?.id"
                                  @click:onDelete="onHandleClickDeleteCard"
                                  :key="routePoint?.id || index"
                                  :nameRu="routePoint.nameRu"
                                  :nameRuError="routePoint.nameRuError"
                                  :nameEn="routePoint.nameEn"
                                  :nameEnError="routePoint.nameEnError"
                                  @update:nameRu="routePoint.nameRu = $event"
                                  @update:nameEn="routePoint.nameEn = $event"
                                  :selectedPavilion="routePoint.selectedPavilion"
                                  @update:selectedPavilion="routePoint.selectedPavilion = $event"
                                  :selectedPavilionError="routePoint.selectedPavilionError"
                                  :pavilions="pavilions"
                                  :descriptionRu="routePoint.descriptionRu"
                                  :descriptionRuError="routePoint.descriptionRuError"
                                  :descriptionEn="routePoint.descriptionEn"
                                  :descriptionEnError="routePoint.descriptionEnError"
                                  @update:descriptionRu="routePoint.descriptionRu = $event"
                                  @update:descriptionEn="routePoint.descriptionEn = $event"
                                  :audio="routePoint.audio"
                                  :audioError="routePoint.audioError"
                                  @update:audio="routePoint.audio = $event"
                                  :audioTextRu="routePoint.audioTextRu"
                                  :audioTextRuError="routePoint.audioTextRuError"
                                  :audioTextEn="routePoint.audioTextEn"
                                  :audioTextEnError="routePoint.audioTextEnError"
                                  @update:audioTextRu="routePoint.audioTextRu = $event"
                                  @update:audioTextEn="routePoint.audioTextEn = $event"
                                  :image="routePoint.image"
                                  :imageError="routePoint.imageError"
                                  @update:image="routePoint.image = $event"
                                  :imageAuthorRu="routePoint.imageAuthorRu"
                                  :imageAuthorRuError="routePoint.imageAuthorRuError"
                                  :imageAuthorEn="routePoint.imageAuthorEn"
                                  :imageAuthorEnError="routePoint.imageAuthorEnError"
                                  @update:imageAuthorRu="routePoint.imageAuthorRu = $event"
                                  @update:imageAuthorEn="routePoint.imageAuthorEn = $event"
                                  :isDisable="!!routePoint?.id"
                                  :isCreate="true"
                                  :isActiveRu="routePoint.isActiveRu"
                                  @update:isActiveRu="routePoint.isActiveRu = $event"
                              />
                            </div>
                          </div>

                          <div
                              v-if="pointRouteItems?.length"
                              @click="onHandleClickPointRouteCreate"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить новую точку маршрута
                            </div>
                          </div>

                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Durations">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите продолжительность для экскурсий</label>
                            <Multiselect
                                v-model="selectedEventDurations"
                                :options="eventDurations"
                                @select="onHandleSelectEventDuration"
                                @deselect="onHandleDeSelectEventDuration"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="true"
                                :searchable="true"/>
                          </div>

                          <div>
                            <div class="mb-5 mt-5">
                              <div>
                                <input-text
                                    :disabled="!!eventDurationId"
                                    v-model="eventDurationNameRu"
                                    label="Наименование (RU) *"/>
                                <p v-if="eventDurationNameRuError" class="mt-2 text-sm text-red-600">
                                  {{ eventDurationNameRuError }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!!eventDurationId"
                                    v-model="eventDurationNameEn"
                                    label="Наименование (EN)"/>
                                <p v-if="eventDurationNameEnError" class="mt-2 text-sm text-red-600">
                                  {{ eventDurationNameEnError }} </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Partner">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите партнера</label>
                            <Multiselect
                                v-model="selectedPartners"
                                :options="partners"
                                @select="onHandleSelectPartner"
                                @deselect="onHandleDeSelectPartner"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="false"
                                :searchable="true"/>
                          </div>
                          <div
                              @click="onHandleClickPartnerCreate"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового партнера
                            </div>
                          </div>

                          <div v-if="partnerItems?.length">
                            <div
                                style="margin-top: 30px"
                                v-for="(partner, index) in partnerItems"
                                :key="partner?.id || index">
                              <EventPartner
                                  :index="index"
                                  :id="partner?.id"
                                  @click:onDelete="onHandleClickDeleteCard"
                                  :key="partner?.id || index"
                                  :nameRu="partner.nameRu"
                                  :nameRuError="partner.nameRuError"
                                  :nameEn="partner.nameEn"
                                  :nameEnError="partner.nameEnError"
                                  @update:nameRu="partner.nameRu = $event"
                                  @update:nameEn="partner.nameEn = $event"
                                  :linkRu="partner.linkRu"
                                  :linkRuError="partner.linkRuError"
                                  :linkEn="partner.linkEn"
                                  :linkEnError="partner.linkEnError"
                                  @update:linkRu="partner.linkRu = $event"
                                  @update:linkEn="partner.linkEn = $event"
                                  :shortDescriptionRu="partner.shortDescriptionRu"
                                  :shortDescriptionRuError="partner.shortDescriptionRuError"
                                  :shortDescriptionEn="partner.shortDescriptionEn"
                                  :shortDescriptionEnError="partner.shortDescriptionEnError"
                                  @update:shortDescriptionRu="partner.shortDescriptionRu = $event"
                                  @update:shortDescriptionEn="partner.shortDescriptionEn = $event"
                                  :image="partner.image"
                                  :imageError="partner.imageError"
                                  @update:image="partner.image = $event"
                                  :authorImageRu="partner.authorImageRu"
                                  :authorImageRuError="partner.authorImageRuError"
                                  :authorImageEn="partner.authorImageEn"
                                  :authorImageEnError="partner.authorImageEnError"
                                  @update:authorImageRu="partner.authorImageRu = $event"
                                  @update:authorImageEn="partner.authorImageEn = $event"
                                  :isMain="partner.isMain"
                                  :isAbout="partner.isAbout"
                                  :isPartners="partner.isPartners"
                                  :isStatusRu="partner.isStatusRu"
                                  :isStatusEn="partner.isStatusEn"
                                  :isActiveRu="partner.isActiveRu"
                                  :isActiveEn="partner.isActiveEn"
                                  @update:isMain="partner.isMain = $event"
                                  @update:isAbout="partner.isAbout = $event"
                                  @update:isPartners="partner.isPartners = $event"
                                  @update:isStatusRu="partner.isStatusRu = $event"
                                  @update:isStatusEn="partner.isStatusEn = $event"
                                  @update:isActiveRu="partner.isActiveRu = $event"
                                  @update:isActiveEn="partner.isActiveEn = $event"
                                  :isDisable="!!partner?.id"
                                  :isCreate="true"
                              />
                            </div>
                          </div>

                          <div
                              v-if="partnerItems?.length"
                              @click="onHandleClickPartnerCreate"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового партнера
                            </div>
                          </div>

                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Language">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите язык для экскурсий</label>
                            <Multiselect
                                v-model="selectedLanguages"
                                :options="languages"
                                @select="onHandleSelectLanguage"
                                @deselect="onHandleDeSelectLanguage"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="true"
                                :searchable="true"/>
                          </div>

                          <div>
                            <div class="mb-5 mt-5">
                              <div>
                                <input-text
                                    :disabled="!!languageId"
                                    v-model="languageNameRu"
                                    label="Наименование (RU) *"/>
                                <p v-if="languageNameRuError" class="mt-2 text-sm text-red-600">
                                  {{ languageNameRuError }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!!languageId"
                                    v-model="languageNameEn"
                                    label="Наименование (EN)"/>
                                <p v-if="languageNameEnError" class="mt-2 text-sm text-red-600">
                                  {{ languageNameEnError }} </p>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div v-if="tabKey === contentEventCardTypeEnum.Artists">
                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите артиста</label>
                            <Multiselect
                                v-model="selectedArtists"
                                :options="artists"
                                @select="onHandleSelectArtist"
                                @deselect="onHandleDeSelectArtist"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="false"
                                :searchable="true"/>
                          </div>
                          <div
                              @click="onHandleClickArtistCreate"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового артиста
                            </div>
                          </div>

                          <div v-if="artistItems?.length">
                            <div
                                style="margin-top: 30px"
                                v-for="(artist, index) in artistItems"
                                :key="artist?.id || index">
                              <EventArtist
                                  :index="index"
                                  :id="artist?.id"
                                  @click:onDelete="onHandleClickDeleteCard"
                                  :key="artist?.id || index"
                                  :nameRu="artist.nameRu"
                                  :nameRuError="artist.nameRuError"
                                  :nameEn="artist.nameEn"
                                  :nameEnError="artist.nameEnError"
                                  @update:nameRu="artist.nameRu = $event"
                                  @update:nameEn="artist.nameEn = $event"
                                  :descriptionRu="artist.descriptionRu"
                                  :descriptionRuError="artist.descriptionRuError"
                                  :descriptionEn="artist.descriptionEn"
                                  :descriptionEnError="artist.descriptionEnError"
                                  @update:descriptionRu="artist.descriptionRu = $event"
                                  @update:descriptionEn="artist.descriptionEn = $event"
                                  :positionRu="artist.positionRu"
                                  :positionRuError="artist.positionRuError"
                                  :positionEn="artist.positionEn"
                                  :positionEnError="artist.positionEnError"
                                  @update:positionRu="artist.positionRu = $event"
                                  @update:positionEn="artist.positionEn = $event"
                                  :image="artist.image"
                                  :imageError="artist.imageError"
                                  @update:image="artist.image = $event"
                                  :authorRu="artist.authorRu"
                                  :authorRuError="artist.authorRuError"
                                  :authorEn="artist.authorEn"
                                  :authorEnError="artist.authorEnError"
                                  @update:authorRu="artist.authorRu = $event"
                                  @update:authorEn="artist.authorEn = $event"
                                  :activeRu="artist.activeRu"
                                  :activeEn="artist.activeEn"
                                  @update:activeRu="artist.activeRu = $event"
                                  @update:activeEn="artist.activeEn = $event"
                                  :isDisable="!!artist?.id"
                                  :isCreate="true"
                              />
                            </div>
                          </div>

                          <div
                              v-if="artistItems?.length"
                              @click="onHandleClickArtistCreate"
                              class="content-item-add">
                            <div class="content-item-btn">
                              Добавить нового артиста
                            </div>
                          </div>

                        </div>

                        <div v-if="tabKey === 'sliders'">
                          <div
                              style="padding: 5px 15px; color: red; text-align: center;"
                              v-if="messageSliderEmpty" class="alert-message-error">
                            {{messageSliderEmpty}}
                          </div>
                          <div
                              v-for="(slider, index) in sliders"
                              :key="index"
                              class="achievement-content-item">
                            <div class="achievement-content-item-image mb-7">
                              <div class="grid gap-4 mb-4">
                                <div v-if="slider.image?.name?.length">
                                  <div class="image-content"
                                       style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                                    <h2 class="mb-2" style="text-align: center">Изображение *</h2>
                                    <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                      <span class="mr-2">{{slider.image.name}}</span>
                                      <button @click="onHandleClickSliderImageDelete(index)" type="button" class="hover:opacity-60">
                                        <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                    v-else-if="!slider.image?.length"
                                    class="sm:col-span-2">
                                  <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                  <drop-file
                                      v-model="slider.image"
                                      :type="imageTypes"
                                      :single="true"
                                  />
                                  <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>
                                </div>
                                <div v-else>
                                  <div class="image-content"
                                       style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                                    <h2 class="mb-2" style="text-align: center">Изображение *</h2>
                                    <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                      <span class="mr-2">{{slider.image[0].name}}</span>
                                      <button @click="onHandleClickSliderImageDelete(index)" type="button" class="hover:opacity-60">
                                        <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <hr/>
                            <div class="author-achievement mb-5 mt-5">
                              <div>
                                <input-text
                                    v-model="slider.authorRu"
                                    label="Наименование автора изображения (RU)"/>
                                <p v-if="slider.authorRuError" class="mt-2 text-sm text-red-600"> {{ slider.authorRuError }} </p>
                              </div>
                              <div>
                                <input-text
                                    v-model="slider.authorEn"
                                    label="Наименование автора изображения (EN)"/>
                                <p v-if="slider.authorEnError" class="mt-2 text-sm text-red-600"> {{ slider.authorEnError }} </p>
                              </div>
                            </div>
                            <div class="author-achievement mb-5 mt-5">
                              <div>
                                <input-text
                                    v-model="slider.sort"
                                    label="Сортировка"/>
                                <p v-if="slider.sortError" class="mt-2 text-sm text-red-600"> {{ slider.sortError }} </p>
                              </div>
                            </div>
                            <hr />
                            <button
                                @click="onHandleDeleteSlider(index)"
                                class="px-7 mr-5 mt-5 py-3 mb-5 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                              Удалить
                            </button>
                          </div>

                          <div
                              @click="onHandleClickSliderCreate"
                              class="session-add">
                            <div class="session-btn">
                              Добавить новый слайд
                            </div>
                          </div>
                        </div>

                        <div
                            v-if="tabKey === 'session'"
                            class="tab-content tab-content-session">

                          <div
                              style="padding: 5px 15px; color: red; text-align: center;"
                              v-if="messageSessionEmpty" class="alert-message-error">
                            {{messageSessionEmpty}}
                          </div>

                          <div class="sessions">
                            <div
                                v-for="(item, index) in sessionGenerates"
                                class="session-content-week session-item">
                              <p style="padding: 10px 5px 5px 10px">Дни недели *</p>
                              <div class="session-week">
                                <div
                                    v-for="(week, index) in item.weeks"
                                    class="session-week-item">
                                  <div class="week flex items-center mb-4">
                                    <input
                                        v-model="week.value"
                                        :id="'checkbox-session-generate-' + index"
                                        type="checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" >
                                    <label :for="'checkbox-session-generate-' + index" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ week.name }}</label>
                                  </div>
                                </div>
                                <p v-if="item.weekError" class="mt-2 text-sm text-red-600"> {{
                                    item.weekError
                                  }} </p>
                              </div>
                              <div class="grid gap-4 mb-4 sm:grid-cols-2">
                                <div class="sm:col-span-2">
                                  <div>
                                    <input-text
                                        type="number"
                                        v-model="item.name"
                                        label="Квота на сеанс *"/>
                                    <p v-if="item.nameError" class="mt-2 text-sm text-red-600"> {{
                                        item.nameError
                                      }} </p>
                                  </div>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата начала *</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="item.dateStart"
                                      locale="ru"
                                      model-type="yyyy-MM-dd"
                                      :format="'dd.MM.yyyy'"
                                      auto-apply
                                      :enable-time-picker="false"
                                  />
                                  <p v-if="item.dateStartError" class="mt-2 text-sm text-red-600">
                                    {{ item.dateStartError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата окончания *</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="item.dateEnd"
                                      locale="ru"
                                      model-type="yyyy-MM-dd"
                                      :format="'dd.MM.yyyy'"
                                      auto-apply
                                      :enable-time-picker="false"
                                  />
                                  <p v-if="item.dateEndError" class="mt-2 text-sm text-red-600">
                                    {{ item.dateEndError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <div>
                                    <span class="title text-gray-400">Время начала *</span>
                                    <VueDatePicker
                                        class="mt-2"
                                        v-model="item.timeStart"
                                        locale="ru"
                                        model-type="HH:mm"
                                        :format="'HH:mm'"
                                        auto-apply
                                        :enable-time-picker="true"
                                        time-picker
                                    />
                                    <p v-if="item.timeStartError" class="mt-2 text-sm text-red-600">
                                      {{ item.timeStartError }} </p>
                                  </div>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Время окончания *</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="item.timeEnd"
                                      locale="ru"
                                      model-type="HH:mm"
                                      :format="'HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                      time-picker
                                  />
                                  <p v-if="item.timeEndError" class="mt-2 text-sm text-red-600">
                                    {{ item.timeEndError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата планового открытия *</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="item.datePlanOpen"
                                      locale="ru"
                                      model-type="yyyy-MM-dd HH:mm"
                                      :format="'dd.MM.yyyy HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                  />
                                  <p v-if="item.datePlanOpenError" class="mt-2 text-sm text-red-600">
                                    {{ item.datePlanOpenError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата закрытия регистрации</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="item.registrationOffDate"
                                      locale="ru"
                                      model-type="yyyy-MM-dd HH:mm"
                                      :format="'dd.MM.yyyy HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                  />
                                  <p v-if="item.registrationOffDateError" class="mt-2 text-sm text-red-600">
                                    {{ item.registrationOffDateError }} </p>
                                </div>

                                <div class="sm:col-span-2"/>

                                <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                                  <div
                                      class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input
                                        :id="'bordered-checkbox-event-session-is-plan-reg'"
                                        type="checkbox"
                                        v-model="item.isOpenRegistration"
                                        name="bordered-checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <div class="ml-2 text-sm">
                                      <label :for="'bordered-checkbox-event-session-is-plan-reg'"
                                             class="font-medium text-basic-gray dark:text-gray-300">
                                        Признак открытия регистрации
                                      </label>
                                      <p
                                          :id="'helper-checkbox-event-session-text-reg-'"
                                          class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        {{
                                          item.isOpenRegistration ? 'Активна (Да)' : 'Не активна (Нет)'
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                      class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input
                                        :id="'bordered-checkbox-event-session-is-active'"
                                        type="checkbox"
                                        v-model="item.isActive"
                                        name="bordered-checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <div class="ml-2 text-sm">
                                      <label :for="'bordered-checkbox-event-session-is-active'"
                                             class="font-medium text-basic-gray dark:text-gray-300">
                                        Активность
                                      </label>
                                      <p
                                          :id="'helper-checkbox-event-session-text-active'"
                                          class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        {{
                                          item.isActive ? 'Активна (Да)' : 'Не активна (Нет)'
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <button
                                    type="button"
                                    @click="onHandleDeleteSessionGenerate(index)"
                                    style="margin-bottom: 30px"
                                    class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                  Удалить
                                </button>
                              </div>
                            </div>
                            <div
                                v-for="(session, index) in sessions"
                                :key="index"
                                class="session-item">
                              <div class="grid gap-4 mb-4 sm:grid-cols-2">
                                <div class="sm:col-span-1">
                                  <div>
                                    <input-text
                                        type="number"
                                        @blur="onHandleBlurQuota(index)"
                                        v-model="session.name"
                                        label="Квота на сеанс *"/>
                                    <p v-if="session.nameError" class="mt-2 text-sm text-red-600"> {{
                                        session.nameError
                                      }} </p>
                                  </div>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата начала *</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="session.dateStart"
                                      locale="ru"
                                      model-type="yyyy-MM-dd"
                                      :format="'dd.MM.yyyy'"
                                      auto-apply
                                      :enable-time-picker="false"
                                  />
                                  <p v-if="session.dateStartError" class="mt-2 text-sm text-red-600">
                                    {{ session.dateStartError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <div>
                                    <span class="title text-gray-400">Время начала *</span>
                                    <VueDatePicker
                                        class="mt-2"
                                        v-model="session.timeStart"
                                        locale="ru"
                                        model-type="HH:mm"
                                        :format="'HH:mm'"
                                        auto-apply
                                        :enable-time-picker="true"
                                        time-picker
                                        @input="validateSessionTime(index)"
                                    />
                                    <p v-if="session.timeStartError" class="mt-2 text-sm text-red-600">
                                      {{ session.timeStartError }} </p>
                                  </div>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Время окончания *</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="session.timeEnd"
                                      locale="ru"
                                      model-type="HH:mm"
                                      :format="'HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                      time-picker
                                      @input="validateSessionTime(index)"
                                  />
                                  <p v-if="session.timeEndError" class="mt-2 text-sm text-red-600">
                                    {{ session.timeEndError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата планового открытия *</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="session.datePlanOpen"
                                      locale="ru"
                                      model-type="yyyy-MM-dd HH:mm"
                                      :format="'dd.MM.yyyy HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                  />
                                  <p v-if="session.datePlanOpenError" class="mt-2 text-sm text-red-600">
                                    {{ session.datePlanOpenError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата закрытия регистрации</span>
                                  <VueDatePicker
                                      class="mt-2"
                                      v-model="session.registrationOffDate"
                                      locale="ru"
                                      model-type="yyyy-MM-dd HH:mm"
                                      :format="'dd.MM.yyyy HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                  />
                                  <p v-if="session.registrationOffDateError" class="mt-2 text-sm text-red-600">
                                    {{ session.registrationOffDateError }} </p>
                                </div>

                                <div class="sm:col-span-2"/>

                                <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                                  <div
                                      class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input
                                        :id="'bordered-checkbox-reg-not-generate-' + index"
                                        type="checkbox"
                                        v-model="session.isOpenRegistration"
                                        name="bordered-checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <div class="ml-2 text-sm">
                                      <label :for="'bordered-checkbox-reg-not-generate-' + index"
                                             class="font-medium text-basic-gray dark:text-gray-300">
                                        Признак открытия регистрации
                                      </label>
                                      <p
                                          :id="'helper-checkbox-text-reg-not-generate-' + index"
                                          class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        {{
                                          session.isOpenRegistration ? 'Активна (Да)' : 'Не активна (Нет)'
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                      class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input
                                        :id="'bordered-checkbox-active-not-generate-' + index"
                                        type="checkbox"
                                        v-model="session.isActive"
                                        name="bordered-checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <div class="ml-2 text-sm">
                                      <label :for="'bordered-checkbox-active-not-generate-' + index"
                                             class="font-medium text-basic-gray dark:text-gray-300">
                                        Активность
                                      </label>
                                      <p
                                          :id="'helper-checkbox-text-active-not-generate-' + index"
                                          class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        {{
                                          session.isActive ? 'Активна (Да)' : 'Не активна (Нет)'
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <button
                                    @click="onHandleDeleteSession(index)"
                                    style="margin-bottom: 30px"
                                    class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                                @click="onHandleClickSessionCreate"
                                class="session-add">
                              <div class="session-btn">
                                Добавить новый сеанс
                              </div>
                            </div>
                            <div
                                @click="onHandleAddSessionGenerate"
                                class="session-add">
                              <div class="session-btn">
                                Сгенерировать сеанс
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                            v-if="tabKey === 'main'"
                            class="tab-content">
                          <div class="grid gap-4 mb-4">
                            <div class="sm:col-span-2">
                              <div>
                                <input-text v-model="nameRu" label="Наименование (RU) *"/>
                                <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
                              </div>
                              <div>
                                <input-text v-model="nameEn" label="Наименование (EN)"/>
                                <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
                              </div>
                            </div>
                          </div>

                          <hr class="mb-4 mb-2">

                          <div class="grid gap-4 mb-5 sm:grid-cols-2">
                            <div class="sm:col-span-2">
                              <input-textarea
                                  class="placeholder-gray-100"
                                  placeholder=""
                                  v-model="shortDescriptionRu"
                                  label="Краткое описание (RU) *"
                              />
                              <small :style="{color: shortDescriptionRu?.length > 600 ? 'red' : ''}">Количество символов {{shortDescriptionRu?.length}} из 600</small>
                              <p v-if="shortDescriptionRuError" class="mt-2 text-sm text-red-600">
                                {{ shortDescriptionRuError }} </p>
                            </div>
                            <div class="sm:col-span-2">
                              <input-textarea
                                  class="placeholder-gray-100"
                                  placeholder=""
                                  v-model="shortDescriptionEn"
                                  label="Краткое описание (EN)"
                              />
                              <small :style="{color: shortDescriptionEn?.length > 600 ? 'red' : ''}">Количество символов {{shortDescriptionEn?.length}} из 600</small>
                              <p v-if="shortDescriptionEnError" class="mt-2 text-sm text-red-600">
                                {{ shortDescriptionEnError }} </p>
                            </div>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <div class="grid gap-4 mb-5 sm:grid-cols-2">
                            <div class="sm:col-span-2">
                              <input-textarea
                                  class="placeholder-gray-100"
                                  placeholder=""
                                  v-model="descriptionRu"
                                  label="Подробное описание (RU) *"
                              />
                              <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600"> {{
                                  descriptionRuError
                                }} </p>
                            </div>
                            <div class="sm:col-span-2">
                              <input-textarea
                                  class="placeholder-gray-100"
                                  placeholder=""
                                  v-model="descriptionEn"
                                  label="Подробное описание (EN)"
                              />
                              <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600"> {{
                                  descriptionEnError
                                }} </p>
                            </div>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <div
                              v-if="checkRole(['admin', 'manager', 'content_manager'])"
                              class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите доп участников</label>
                            <Multiselect
                                v-model="selectedParticipants"
                                :options="participants"
                                :valueProp="'id'"
                                track-by="nameRu"
                                label="nameRu"
                                :mode="'tags'"
                                :searchable="true"/>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите контур</label>
                            <Multiselect
                                v-model="selectedContours"
                                :options="contours"
                                :mode="'tags'"
                                :valueProp="'id'"
                                max="1"
                                track-by="name"
                                label="name"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedContourError" class="mt-2 text-sm text-red-600">
                              {{
                                selectedContourError
                              }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите категорию мероприятия *</label>
                            <Multiselect
                                v-model="selectedCategories"
                                :options="categories"
                                :mode="'tags'"
                                :valueProp="'id'"
                                max="1"
                                track-by="nameRu"
                                label="nameRu"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedCategoryError" class="mt-2 text-sm text-red-600">
                              {{ selectedCategoryError }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите организатора мероприятия
                              *</label>
                            <Multiselect
                                v-model="selectedOrganizer"
                                :options="organizations"
                                :valueProp="'id'"
                                track-by="nameRu"
                                label="nameRu"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedOrganizerError" class="mt-2 text-sm text-red-600">
                              {{ selectedOrganizerError }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите доп организаторов мероприятия</label>
                            <Multiselect
                                v-model="selectedOrganizations"
                                :options="organizations?.filter(item => item !== selectedOrganizer)"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="nameRu"
                                label="nameRu"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedOrganizationError" class="mt-2 text-sm text-red-600">
                              {{ selectedOrganizationError }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите ограничение по возрасту
                              *</label>
                            <Multiselect
                                v-model="selectedAgeLimits"
                                :options="ageLimits"
                                :mode="'tags'"
                                :valueProp="'id'"
                                max="1"
                                track-by="nameRu"
                                label="nameRu"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedAgeLimitError" class="mt-2 text-sm text-red-600">
                              {{ selectedAgeLimitError }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите тип мероприятия *</label>
                            <Multiselect
                                v-model="selectedTypes"
                                :options="types"
                                :mode="'tags'"
                                :valueProp="'id'"
                                max="1"
                                track-by="titleRu"
                                label="titleRu"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedTypeError" class="mt-2 text-sm text-red-600">
                              {{ selectedTypeError }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите павильон *</label>
                            <Multiselect
                                v-model="selectedPavilions"
                                :options="pavilions"
                                :mode="'tags'"
                                :valueProp="'id'"
                                max="1"
                                track-by="name"
                                label="name"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedPavilionError" class="mt-2 text-sm text-red-600">
                              {{ selectedPavilionError }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите площадку</label>
                            <Multiselect
                                :disabled="!selectedPavilions?.length"
                                v-model="selectedVenue"
                                :options="venues"
                                :mode="'single'"
                                :valueProp="'id'"
                                track-by="nameRu"
                                label="nameRu"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedVenueError" class="mt-2 text-sm text-red-600">
                              {{ selectedVenueError }}
                            </p>
                          </div>

                          <div class="mb-5">
                            <label class="block mb-2 font-medium text-basic-gray">Выберите группу *</label>
                            <Multiselect
                                v-model="selectedGroups"
                                :options="groups"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="nameRu"
                                label="nameRu"
                                :close-on-select="false"
                                :searchable="true"/>
                            <p v-if="selectedGroupError" class="mt-2 text-sm text-red-600">
                              {{ selectedGroupError }}
                            </p>
                          </div>

                          <div>
                            <span class="title text-gray-400">Дата публикации *</span>
                            <VueDatePicker
                                class="mt-2"
                                v-model="publishedAt"
                                locale="ru"
                                model-type="yyyy-MM-dd HH:mm"
                                :format="'dd.MM.yyyy HH:mm'"
                                auto-apply
                                :enable-time-picker="true"
                            />
                            <p v-if="publishedAtError" class="mt-2 text-sm text-red-600"> {{ publishedAtError }} </p>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <!--                        <div class="sm:col-span-1">-->
                          <!--                          <span class="title text-gray-400">Длительность</span>-->
                          <!--                          <VueDatePicker-->
                          <!--                              class="mt-2"-->
                          <!--                              v-model="duration"-->
                          <!--                              locale="ru"-->
                          <!--                              model-type="HH:mm"-->
                          <!--                              :format="'HH:mm'"-->
                          <!--                              auto-apply-->
                          <!--                              :enable-time-picker="true"-->
                          <!--                              time-picker-->
                          <!--                          />-->
                          <!--                          <p v-if="durationError" class="mt-2 text-sm text-red-600">-->
                          <!--                            {{ durationError }} </p>-->
                          <!--                        </div>-->

                          <div>
                            <input-text
                                type="number"
                                @blur="onHandleBlurQuotaPerOrder"
                                v-model="quotaPerOrder"
                                label="Квота на количество билетов в одном заказе"/>
                            <p v-if="quotaPerOrderError" class="mt-2 text-sm text-red-600"> {{ quotaPerOrderError }} </p>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <!--                      <div class="grid gap-4 mb-4">-->
                          <!--                        <div class="sm:col-span-2">-->
                          <!--                          <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>-->
                          <!--                          <drop-file-->
                          <!--                              v-model="image"-->
                          <!--                              :type="imageTypes"-->
                          <!--                              :single="true"-->
                          <!--                          />-->
                          <!--                          <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>-->
                          <!--                        </div>-->
                          <!--                      </div>-->

                          <div class="grid gap-4 mb-4">
                            <div
                                v-if="!image?.length"
                                class="sm:col-span-2">
                              <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                              <drop-file
                                  v-model="image"
                                  :type="imageTypes"
                                  :single="true"
                              />
                              <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>
                            </div>
                            <div v-else>
                              <div class="image-content"
                                   style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                                <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                  <span class="mr-2">{{image[0].name}}</span>
                                  <button @click="onHandleClickImageDelete" type="button" class="hover:opacity-60">
                                    <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="grid gap-4 mb-4">
                            <div class="sm:col-span-2">
                              <input-text
                                  v-model="authorImageTitleNameRu"
                                  label="Наименование автора изображения (RU)"/>
                              <p v-if="authorImageTitleNameRuError" class="mt-2 text-sm text-red-600"> {{ authorImageTitleNameRuError }} </p>
                            </div>
                          </div>

                          <div class="grid gap-4 mb-4">
                            <div class="sm:col-span-2">
                              <input-text
                                  v-model="authorImageTitleNameEn"
                                  label="Наименование автора изображения (EN)"/>
                              <p v-if="authorImageTitleNameEnError" class="mt-2 text-sm text-red-600"> {{ authorImageTitleNameEnError }} </p>
                            </div>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <div>
                            <input-text v-model="externalLinkAccreditation" label="Внешняя ссылка для аккредитации /авторизации"/>
                          </div>

                          <div class="sm:col-span-1">
                            <div>
                              <input-text v-model="schedulePeriodTimeRu" label="Расписание (RU)"/>
                            </div>
                            <div>
                              <input-text v-model="schedulePeriodTimeEn" label="Расписание (EN)"/>
                            </div>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <div
                              class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-4"
                                type="checkbox"
                                v-model="isEventRegister"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-4" class="font-medium text-basic-gray dark:text-gray-300">
                                Скрыть кнопку регистрации
                              </label>
                              <p
                                  id="helper-checkbox-text-4"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isEventRegister ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">

                          <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
<!--                            <div-->
<!--                                class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">-->
<!--                              <input-->
<!--                                  id="bordered-checkbox-slider-mp"-->
<!--                                  type="checkbox"-->
<!--                                  v-model="isSliderMP"-->
<!--                                  name="bordered-checkbox"-->
<!--                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">-->
<!--                              <div class="ml-2 text-sm">-->
<!--                                <label for="bordered-checkbox-slider-mp" class="font-medium text-basic-gray dark:text-gray-300">-->
<!--                                  Слайдер МП-->
<!--                                </label>-->
<!--                                <p-->
<!--                                    id="helper-checkbox-text-slider-mp"-->
<!--                                    class="text-xs font-normal text-gray-500 dark:text-gray-300">-->
<!--                                  {{-->
<!--                                    isSliderMP ? 'Слайдер МП (Да)' : 'Слайдер МП (Нет)'-->
<!--                                  }}-->
<!--                                </p>-->
<!--                              </div>-->
<!--                            </div>-->
                            <div
                                class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                              <input
                                  id="bordered-checkbox-44"
                                  type="checkbox"
                                  v-model="isSlider"
                                  name="bordered-checkbox"
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <div class="ml-2 text-sm">
                                <label for="bordered-checkbox-44" class="font-medium text-basic-gray dark:text-gray-300">
                                  Программа / Слайдер
                                </label>
                                <p
                                    id="helper-checkbox-text-44"
                                    class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                  {{
                                    isSlider ? 'Активна (Да)' : 'Не активна (Нет)'
                                  }}
                                </p>
                              </div>
                            </div>
                            <div
                                class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                              <input
                                  id="bordered-checkbox-55"
                                  type="checkbox"
                                  v-model="isMainEvent"
                                  name="bordered-checkbox"
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <div class="ml-2 text-sm">
                                <label for="bordered-checkbox-55" class="font-medium text-basic-gray dark:text-gray-300">
                                  Программа / Главные события
                                </label>
                                <p
                                    id="helper-checkbox-text-55"
                                    class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                  {{
                                    isMainEvent ? 'Активна (Да)' : 'Не активна (Нет)'
                                  }}
                                </p>
                              </div>
                            </div>
                            <div
                                class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                              <input
                                  id="bordered-checkbox-66"
                                  type="checkbox"
                                  v-model="isRecommend"
                                  name="bordered-checkbox"
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <div class="ml-2 text-sm">
                                <label for="bordered-checkbox-66" class="font-medium text-basic-gray dark:text-gray-300">
                                  Программа / Рекомендуем
                                </label>
                                <p
                                    id="helper-checkbox-text-66"
                                    class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                  {{
                                    isRecommend ? 'Активна (Да)' : 'Не активна (Нет)'
                                  }}
                                </p>
                              </div>
                            </div>
                            <div
                                class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                              <input
                                  id="bordered-checkbox-4"
                                  type="checkbox"
                                  v-model="isActiveRu"
                                  name="bordered-checkbox"
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <div class="ml-2 text-sm">
                                <label for="bordered-checkbox-4" class="font-medium text-basic-gray dark:text-gray-300">
                                  Активность для (RU)
                                </label>
                                <p
                                    id="helper-checkbox-text-4"
                                    class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                  {{
                                    isActiveRu ? 'Активна (Да)' : 'Не активна (Нет)'
                                  }}
                                </p>
                              </div>
                            </div>
                            <div
                                class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                              <input
                                  id="bordered-checkbox-5"
                                  type="checkbox"
                                  v-model="isActiveEn"
                                  name="bordered-checkbox"
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <div class="ml-2 text-sm">
                                <label for="bordered-checkbox-5" class="font-medium text-basic-gray dark:text-gray-300">
                                  Активность для (EN)
                                </label>
                                <p
                                    id="helper-checkbox-text-5"
                                    class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                  {{
                                    isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)'
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSessionGenerateEmpty || isErrorValSessionGenerate"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageSessionGenerateEmpty || 'Ошибки в сессиях' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSliderEmpty || isErrorValSlider"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageSliderEmpty || 'Ошибки в слайдере' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSessionEmpty || isErrorValSession"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageSessionEmpty || 'Ошибки в сеансах' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageArtistEmpty || isErrorValArtist"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageArtistEmpty || 'Ошибки в артистах' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSpeakerEmpty || isErrorValSpeaker"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageSpeakerEmpty || 'Ошибки в спикерах' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageLecturerEmpty || isErrorValLecturer"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageLecturerEmpty || 'Ошибки в докладчиках' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageModeratorEmpty || isErrorValModerator"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageModeratorEmpty || 'Ошибки в модераторах' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageOrganizerEmpty || isErrorValOrganizer"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageOrganizerEmpty || 'Ошибки в организаторах' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messagePointRouteEmpty || isErrorValPointRoute"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messagePointRouteEmpty || 'Ошибки в точке маршрута' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageDurationEmpty || isErrorValDuration"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageDurationEmpty || 'Ошибки в продолжительности экскурсий' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messagePartnerEmpty || isErrorValPartner"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messagePartnerEmpty || 'Ошибки в партнерах' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageLanguageEmpty || isErrorValLanguage"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageLanguageEmpty || 'Ошибки в языках для экскурсий' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageThematicEmpty || isErrorValThematic"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageThematicEmpty || 'Ошибки в тематике деловой недели' }}
                    </div>
                  </div>

                  <div
                      v-if="isErrorVal"
                      style="padding: 5px 15px; margin-top: 40px; color: red;"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      <ul style="list-style: initial; padding: 15px;">
                        <li v-if="nameRuError"><span style="font-weight: bold">Наименование (RU):</span>
                          {{ nameRuError }}
                        </li>
                        <li v-if="nameEnError"><span style="font-weight: bold">Наименование (EN):</span>
                          {{ nameEnError }}
                        </li>
                        <li v-if="shortDescriptionRuError"><span style="font-weight: bold">Краткое описание (RU):</span>
                          {{ shortDescriptionRuError }}
                        </li>
                        <li v-if="shortDescriptionEnError"><span style="font-weight: bold">Краткое описание (EN):</span>
                          {{ shortDescriptionEnError }}
                        </li>
                        <li v-if="descriptionRuError"><span style="font-weight: bold">Подробное описание (RU):</span>
                          {{ descriptionRuError }}
                        </li>
                        <li v-if="descriptionEnError"><span style="font-weight: bold">Подробное описание (EN):</span>
                          {{ descriptionEnError }}
                        </li>
                        <li v-if="selectedContourError"><span style="font-weight: bold">Контур:</span>
                          {{ selectedContourError }}
                        </li>
                        <li v-if="selectedCategoryError"><span style="font-weight: bold">Категория мероприятия:</span>
                          {{ selectedCategoryError }}
                        </li>
                        <li v-if="selectedOrganizerError"><span
                            style="font-weight: bold">Организатор мероприятия:</span> {{ selectedOrganizerError }}
                        </li>
                        <li v-if="selectedAgeLimitError"><span style="font-weight: bold">Ограничение по возрасту:</span>
                          {{ selectedAgeLimitError }}
                        </li>
                        <li v-if="selectedTypeError"><span style="font-weight: bold">Тип мероприятия:</span>
                          {{ selectedTypeError }}
                        </li>
                        <li v-if="selectedPavilionError"><span style="font-weight: bold">Павильон:</span>
                          {{ selectedPavilionError }}
                        </li>
                        <li v-if="selectedVenueError"><span style="font-weight: bold">Площадку:</span>
                          {{ selectedVenueError }}
                        </li>
                        <li v-if="selectedGroupError"><span style="font-weight: bold">Группы:</span>
                          {{ selectedGroupError }}
                        </li>
                        <li v-if="publishedAtError"><span style="font-weight: bold">Дата публикации:</span>
                          {{ publishedAtError }}
                        </li>
                        <li v-if="imageError"><span style="font-weight: bold">Изображение:</span> {{ imageError }}</li>
                        <li v-if="authorImageTitleNameRuError"><span style="font-weight: bold">Наименование автора изображения (RU):</span>
                          {{ authorImageTitleNameRuError }}
                        </li>
                        <li v-if="authorImageTitleNameEnError"><span style="font-weight: bold">Наименование автора изображения (EN):</span>
                          {{ authorImageTitleNameEnError }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="p-7 flex justify-between">
                    <div class="p-7 flex justify-between">
                      <div v-if="!isLoadingCreate">
                        <div
                            v-if="checkRole(['admin', 'manager'])"
                            class="flex flex-wrap">
                          <div class="flex items-center mb-4">
                            <label @click.prevent="onHandleClickCreate(6)"
                                   class="bg-status-6 inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                              <span>Согласовать</span>
                            </label>
                          </div>
                        </div>
                        <div
                            v-else
                            class="flex flex-wrap">
                          <div class="flex items-center mb-4">
                            <label @click.prevent="onHandleClickCreate(5)"
                                   class="bg-status-5 inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                              <span>Отправить организатору</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-span-2 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center p-7 border-b border-gray-200">
            <div class="text-3xl font-medium">
              {{ $t('document.remarksTitle') }}
            </div>
          </div>
          <div class="p-7 pt-4">
            <div class="flex-1 justify-between flex flex-col h-[50vh]">
              <div id="messages"
                   class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                <div class="chat-message" v-for="(message, index) in messages">
                  <div class="flex items-end" :class="{'justify-end': !message.isAnswer}">
                    <div style="position: relative" class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                      <div>
                        <div
                            @click="onHandleClickDeleteMessage(index)"
                            style="position: absolute; right: 10px; top: 10px; font-size: 12px; cursor: pointer"
                            class="icon-delete">
                          <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true"
                               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                        </div>
                        <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none"
                             :class="{'bg-gray-300 text-gray-600': message.isAnswer, 'bg-blue-600 text-white': !message.isAnswer}">
                          <div class="text-xs">
                            {{ message.user.name }}
                          </div>
                          <div class="text-xs">
                            {{ message.createdAt }}
                          </div>
                          <div class="text-sm">
                            {{ message.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                <div class="relative flex">
                          <textarea
                              :placeholder="$t('chat.write_message')"
                              v-model="messageData"
                              rows="5"
                              class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                          ></textarea>
                </div>
                <div v-if="checkRole(['admin', 'manager', 'content_manager'])" class="items-center mt-2">
                  <button :disabled="messages.length" type="button"
                          @click.prevent="onHandleClickSendMessage"
                          class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                    <template v-if="!loadDataMessage">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                           class="h-6 w-6 ml-2 transform rotate-90">
                        <path
                            d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                      </svg>
                    </template>
                    <template v-else>
                      <svg aria-hidden="true"
                           class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                           viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"/>
                      </svg>
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {checkRole} from "../../../../utilits/functions.js";
import Spinner from "../../../flowbite/spinner.vue";
import {ref, watch} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect'
import {useRoute, useRouter} from 'vue-router'
import moment from "moment";
import VueDatePicker from '@vuepic/vue-datepicker';
import {serviceEventOrganizationList} from "../../../../service/lists/serviceListEventOrganization.js";
import {serviceEventCategoryList} from "../../../../service/lists/serviceListEventCategory.js";
import {serviceAgeLimitList} from "../../../../service/lists/serviceListAgeLimit.js";
import {serviceEventTypeList} from "../../../../service/lists/serviceListEventType.js";
import {serviceContentEventCreate} from "../../../../service/contents/news/serviceContentEvent.js";
import {serviceEventGroupList} from "../../../../service/lists/serviceListEventGroup.js";
import {servicePavilionList} from "../../../../service/lists/serviceListPavilion.js";
import {serviceMyParticipant, serviceSelectedUserContours} from "../../../../service/user/user.js";
import {serviceEventTypeCardList} from "../../../../service/lists/serviceListEventTypeCard.js";
import EventSpeaker from "./sections/EventSpeaker.vue";
import {serviceEventOfficialList} from "../../../../service/lists/serviceListEventOfficial.js";
import {serviceEventThematicList} from "../../../../service/lists/serviceListEventThematic.js";
import {serviceEventDurationList} from "../../../../service/lists/serviceListEventDuration.js";
import {serviceRoutePointList} from "../../../../service/lists/serviceListRoutePoint.js";
import EventPointRoute from "./sections/EventPointRoute.vue";
import {servicePartnerList} from "../../../../service/lists/serviceListPartner.js";
import EventPartner from "./sections/EventPartner.vue";
import {serviceEventLanguageList} from "../../../../service/lists/serviceListEventLanguage.js";
import {serviceEventArtistList} from "../../../../service/lists/serviceListEventArtist.js";
import EventArtist from "./sections/EventArtist.vue";
import {contentEventCardTypeEnum} from "../../../../constants/contentEventCardTypeEnum.js";
import {
  validateSession,
  validateSessionGenerate,
  validateSessionGenerates
} from "../../../../helpers/helperValidationEvent.js";
import {validateSlider} from "../../../../helpers/helperValidationSlider.js";
import {
  validateArtist,
  validateOfficial,
  validatePartner,
  validatePointRoute
} from "../../../../helpers/helperValidationLists.js";
import {validateTimeRangeEndToStart} from "../../../../helpers/helperValidateDate.js";
import {serviceParticipantList} from "../../../../service/lists/serviceListParticipant.js";
import {servicePavilionVenuesList} from "../../../../service/lists/serviceListVenues.js";

const router = useRouter();
const route = useRoute();

const isLoadingData = ref(true);
const saveMessageResponse = ref(null);

const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;
const messages = ref([]);
const messageData = ref(null);
const tabKey = ref('main');

const isLoadingCreate = ref(false);

const nameRu = ref('');
const nameRuError = ref('');
const nameEn = ref('');
const nameEnError = ref('');
const shortDescriptionRu = ref('');
const shortDescriptionRuError = ref('');
const shortDescriptionEn = ref('');
const shortDescriptionEnError = ref('');
const descriptionRu = ref('');
const descriptionRuError = ref('');
const descriptionEn = ref('');
const descriptionEnError = ref('');
const authorImageTitleNameRu = ref('');
const authorImageTitleNameRuError = ref('');
const authorImageTitleNameEn = ref('');
const authorImageTitleNameEnError = ref('');
const publishedAt = ref(null);
const publishedAtError = ref(null);
const isActiveRu = ref(true);
const isActiveEn = ref(true);
const selectedCategories = ref([]);
const selectedCategoryError = ref('');
const selectedOrganizations = ref([]);
const selectedOrganizationError = ref('');
const selectedOrganizer = ref(null);
const selectedOrganizerError = ref(null);
const selectedAgeLimits = ref([]);
const selectedAgeLimitError = ref('');
const selectedTypes = ref([]);
const selectedTypeError = ref('');
const sessions = ref([]);
const pavilions = ref([]);
const selectedPavilions = ref([]);
const selectedPavilionError = ref('');
const groups = ref([]);
const oldGroups = ref([]);
const selectedGroups = ref([]);
const selectedGroupError = ref('');
const duration = ref('');
const durationError = ref('');
const quotaPerOrder = ref(5);
const quotaPerOrderError = ref(null);
const sliders = ref([]);
const isSlider = ref(false);
const isMainEvent = ref(false);
const isRecommend = ref(false);
const externalLinkAccreditation = ref('');
const schedulePeriodTimeRu = ref('');
const schedulePeriodTimeEn = ref('');
const selectedContours = ref([]);
const contours = ref([]);
const selectedContourError = ref('');
const isEventRegister = ref(false);

const officials = ref([]);
const selectedSpeakers = ref([]);
const speakerItems = ref([]);

const selectedModerators = ref([]);
const moderatorItems = ref([]);

const selectedLecturers = ref([]);
const lecturersItems = ref([]);

const eventOrganizers = ref([]);
const selectedEventOrganizers = ref(null);
const eventOrganizerItems = ref([]);
const organizerId = ref(null);
const organizerNameRu = ref('');
const organizerNameRuError = ref('');
const organizerNameEn = ref('');
const organizerNameEnError = ref('');
const organizerLinkRu = ref('');
const organizerLinkRuError = ref('');
const organizerLinkEn = ref('');
const organizerLinkEnError = ref('');
const organizerEmail = ref('');
const organizerEmailError = ref('');
const organizerPhone = ref('');
const organizerPhoneError = ref('');
const organizerInn = ref('');
const organizerInnError = ref('');

const participants = ref([]);
const selectedParticipants = ref([]);

const isSliderMP = ref(false);

const selectedVenue = ref(null);
const venues = ref([]);
const selectedVenueError = ref(null);

const weekThematics = ref([]);
const selectedWeekThematics = ref([]);
const thematicId = ref(null);
const thematicNameRu = ref('');
const thematicNameRuError = ref('');
const thematicNameEn = ref('');
const thematicNameEnError = ref('');

const eventDurationId = ref(null);
const eventDurationNameRu = ref('');
const eventDurationNameRuError = ref('');
const eventDurationNameEn = ref('');
const eventDurationNameEnError = ref('');
const eventDurations = ref([]);
const selectedEventDurations = ref([]);

const partners = ref([]);
const selectedPartners = ref([]);
const partnerItems = ref([]);

const pointRoutes = ref([]);
const selectedPointRoutes = ref([]);
const pointRouteItems = ref([]);

const languages = ref([]);
const selectedLanguages = ref(null);
const languageItems = ref([]);
const languageId = ref(null);
const languageNameRu = ref('');
const languageNameRuError = ref('');
const languageNameEn = ref('');
const languageNameEnError = ref('');

const artists = ref([]);
const selectedArtists = ref([]);
const artistItems = ref([]);

const selectedEventTypeCard = ref([]);
const eventTypeCards = ref([]);

const sessionGenerates = ref([]);

const sessionGenerate = ref({
  weeks: [
    {
      name: 'ПН',
      value: false,
    },
    {
      name: 'ВТ',
      value: false,
    },
    {
      name: 'СР',
      value: false,
    },
    {
      name: 'ЧТ',
      value: false,
    },
    {
      name: 'ПТ',
      value: false,
    },
    {
      name: 'СБ',
      value: false,
    },
    {
      name: 'ВС',
      value: false,
    },
  ],
  weekError: null,
  name: '',
  nameError: null,
  dateStart: null,
  dateStartError: null,
  dateEnd: null,
  dateEndError: null,
  timeStart: null,
  timeStartError: null,
  timeEnd: null,
  timeEndError: null,
  datePlanOpen: null,
  registrationOffDate: null,
  registrationOffDateError: null,
  datePlanOpenError: null,
  isOpenRegistration: true,
  isActive: true,
  isHidden: true,
});
const isErrorValSessionGenerate = ref(false);
const messageSessionGenerateEmpty = ref('');

const messageSliderEmpty = ref('');
const messageSessionEmpty = ref('');
const isErrorVal = ref(false);
const isErrorValSession = ref(false);
const isErrorValSlider = ref(false);

const messageArtistEmpty = ref('');
const isErrorValArtist = ref(false);

const messageSpeakerEmpty = ref('');
const isErrorValSpeaker = ref(false);

const messageModeratorEmpty = ref('');
const isErrorValModerator = ref(false);

const messageLecturerEmpty = ref('');
const isErrorValLecturer = ref(false);

const messageOrganizerEmpty = ref('');
const isErrorValOrganizer = ref(false);

const messageThematicEmpty = ref('');
const isErrorValThematic = ref(false);

const messagePointRouteEmpty = ref('');
const isErrorValPointRoute = ref(false);

const messagePartnerEmpty = ref('');
const isErrorValPartner = ref(false);

const messageLanguageEmpty = ref('');
const isErrorValLanguage = ref(false);

const messageDurationEmpty = ref('');
const isErrorValDuration = ref(false);

const categories = ref([]);
const oldCategories = ref([]);
const organizations = ref([]);
const ageLimits = ref([]);
const types = ref([]);
// requiredValue - нужно для того, чтобы выставить (*) где обязательность заполнение для администрации
const requiredValue = checkRole(['admin', 'manager', 'content_manager']) ? '*' : '';

const image = ref(null);
const imageError = ref('');
const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

if (checkRole(['admin', 'manager', 'content_manager'])) {
  serviceParticipantList().then(response => {
    participants.value = response.data || [];
  });
}

const onHandleDeleteSessionGenerate = index => {
  sessionGenerates.value?.splice(index, 1);
}

const onHandleBlurQuotaPerOrder = () => {
  if (quotaPerOrder.value < 0)
    quotaPerOrder.value = 5;
}

const onHandleBlurQuota = (index) => {
  if (sessions.value[index]?.name < 0)
    sessions.value[index].name = null;
}

const onHandleClickTabKey = (key) => {
  tabKey.value = key;
}

serviceSelectedUserContours().then(response => {
  contours.value = response.data || [];
});

Promise.all([serviceEventCategoryList(), serviceEventOrganizationList(), serviceAgeLimitList(), serviceEventTypeList(), serviceEventGroupList(), servicePavilionList(), serviceEventTypeCardList()])
    .then(([responseEventCategory, responseEventOrganization, responseAgeLimit, responseEventType, responseEventGroup, responsePavilion, responseEventTypeCard]) => {
      isLoadingData.value = false;
      categories.value = responseEventCategory.data || [];
      oldCategories.value = categories.value;
      organizations.value = responseEventOrganization.data || [];
      ageLimits.value = responseAgeLimit.data || [];
      types.value = responseEventType.data || [];
      groups.value = responseEventGroup.data || [];
      oldGroups.value = groups.value;
      pavilions.value = responsePavilion.data || [];
      eventTypeCards.value = responseEventTypeCard.data || [];
    })
    .catch(error => {
      console.error('Ошибка при выполнении запросов:', error);
    });

const onHandleClickCreate = (newStatus) => {
  const isValExhibitor = validateExhibitor();

  let isValAdministrationAgreed = null;
  if (!checkRole(['content_exhibitor']) && newStatus === 6) {
    isValAdministrationAgreed = validateAdministrationAgreed();
  }

  if (sessionGenerates.value) {
    const resSessionGenerate = validateSessionGenerates(sessionGenerates.value);
    isErrorValSessionGenerate.value = resSessionGenerate.isError;
    sessionGenerates.value = resSessionGenerate.item;
    messageSessionGenerateEmpty.value = resSessionGenerate.messageSessionEmpty;
  }

  const resSession = validateSession(sessions.value, sessionGenerates.value);
  isErrorValSession.value = resSession.isError;
  sessions.value = resSession.items;
  messageSessionEmpty.value = resSession.messageSessionEmpty;

  const resSlider = validateSlider(sliders.value);
  isErrorValSlider.value = resSlider.isError;
  sliders.value = resSlider.items;
  messageSliderEmpty.value = resSlider.messageEmpty;

  isErrorVal.value = isValExhibitor || isValAdministrationAgreed;

  isErrorValArtist.value = false;
  messageArtistEmpty.value = '';

  if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && item.code === 'cultural_program')) {
    const resArtist = validateArtist(artistItems.value);
    isErrorValArtist.value = resArtist.isError;
    artistItems.value = resArtist.items;
    messageArtistEmpty.value = resArtist.messageEmpty;

    if (isErrorValArtist.value)
      return null;
  }

  isErrorValSpeaker.value = false;
  messageSpeakerEmpty.value = '';
  isErrorValLecturer.value = false;
  messageLecturerEmpty.value = '';
  isErrorValModerator.value = false;
  messageModeratorEmpty.value = '';
  isErrorValThematic.value = false;
  messageThematicEmpty.value = '';

  let isThematic = true;

  if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && item.code === 'business_event')) {
    const resSpeaker = validateOfficial(speakerItems.value, contentEventCardTypeEnum.Speaker);
    isErrorValSpeaker.value = resSpeaker.isError;
    speakerItems.value = resSpeaker.items;
    messageSpeakerEmpty.value = resSpeaker.messageEmpty;

    const resLecturer = validateOfficial(lecturersItems.value, contentEventCardTypeEnum.Lecturer);
    isErrorValLecturer.value = resLecturer.isError;
    lecturersItems.value = resLecturer.items;
    messageLecturerEmpty.value = resLecturer.messageEmpty;

    const resModerator = validateOfficial(moderatorItems.value, contentEventCardTypeEnum.Moderator);
    isErrorValModerator.value = resModerator.isError;
    moderatorItems.value = resModerator.items;
    messageModeratorEmpty.value = resModerator.messageEmpty;

    // isErrorValThematic.value = validateThematic();

    if (!thematicId.value?.length && !thematicNameRu?.value?.length)
      isThematic = false;

    if ((isErrorValSpeaker.value || isErrorValLecturer.value || isErrorValModerator.value) || isErrorValThematic.value)
      return null;
  }

  isErrorValPointRoute.value = false;
  messagePointRouteEmpty.value = '';
  isErrorValPartner.value = false;
  messagePartnerEmpty.value = '';
  isErrorValLanguage.value = false;
  messageLanguageEmpty.value = '';
  isErrorValDuration.value = false;
  messageDurationEmpty.value = '';

  if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && item.code === 'excursion')) {
    const resSpeaker = validateOfficial(speakerItems.value, contentEventCardTypeEnum.Speaker);
    isErrorValSpeaker.value = resSpeaker.isError;
    speakerItems.value = resSpeaker.items;
    messageSpeakerEmpty.value = resSpeaker.messageEmpty;

    const resLecturer = validateOfficial(lecturersItems.value, contentEventCardTypeEnum.Lecturer);
    isErrorValLecturer.value = resLecturer.isError;
    lecturersItems.value = resLecturer.items;
    messageLecturerEmpty.value = resLecturer.messageEmpty;

    const resModerator = validateOfficial(moderatorItems.value, contentEventCardTypeEnum.Moderator);
    isErrorValModerator.value = resModerator.isError;
    moderatorItems.value = resModerator.items;
    messageModeratorEmpty.value = resModerator.messageEmpty;

    const resPointRoute = validatePointRoute(pointRouteItems.value);
    isErrorValPointRoute.value = resPointRoute.isError;
    pointRouteItems.value = resPointRoute.items;
    messagePointRouteEmpty.value = resPointRoute.messageEmpty;

    const resPartner = validatePartner(partnerItems.value);
    isErrorValPartner.value = resPartner.isError;
    partnerItems.value = resPartner.items;
    messagePartnerEmpty.value = resPartner.messageEmpty;

    isErrorValLanguage.value = validateLanguage();
    isErrorValDuration.value = validateDuration();

    if (isErrorValSpeaker.value || isErrorValLecturer.value || isErrorValModerator.value || isErrorValPointRoute.value || isErrorValPartner.value || isErrorValLanguage.value || isErrorValDuration.value)
      return null;
  }

  if (isValExhibitor || isValAdministrationAgreed || isErrorValSlider.value || (isErrorValSession.value || isErrorValSessionGenerate.value))
    return null;

  let messageItems = [];
  if (checkRole(['content_exhibitor'])) {
    if (messageData.value)
      messageItems = [messageData.value];
  } else messageItems = messages.value.length ? [messages.value[0].message] : [];

  const params = {
    contourId: selectedContours.value?.length ? selectedContours.value[0] : null,
    status: newStatus,
    nameRu: nameRu.value,
    nameEn: nameEn.value,
    shortDescriptionRu: shortDescriptionRu.value,
    shortDescriptionEn: shortDescriptionEn.value,
    descriptionRu: descriptionRu.value,
    descriptionEn: descriptionEn.value,
    categoryId: selectedCategories.value.length ? selectedCategories.value[0] : null,
    organizationId: selectedOrganizer.value,
    ageLimitId: selectedAgeLimits.value.length ? selectedAgeLimits.value[0] : null,
    typeId: selectedTypes.value.length ? selectedTypes.value[0] : null,
    pavilionId: selectedPavilions.value.length ? selectedPavilions.value[0] : null,
    groups: selectedGroups.value.length ? selectedGroups.value : [],
    duration: duration.value,
    quotaPerOrder: quotaPerOrder.value,
    publishedAt: publishedAt.value,
    image: image.value.length ? image.value[0] : null,
    authorImageTitleNameRu: authorImageTitleNameRu.value,
    authorImageTitleNameEn: authorImageTitleNameEn.value,
    activeRu: isActiveRu.value,
    activeEn: isActiveEn.value,
    messages: messageItems,
    sessions: sessions.value,
    sliders: sliders.value,
    isSlider: isSlider.value,
    isMainEvent: isMainEvent.value,
    isRecommend: isRecommend.value,
    externalLinkAccreditation: externalLinkAccreditation.value,
    schedulePeriodTimeRu: schedulePeriodTimeRu.value,
    schedulePeriodTimeEn: schedulePeriodTimeEn.value,
    typeCardId: selectedEventTypeCard.value,
    eventOrganizers: eventOrganizerItems.value,
    thematicId: isThematic ? thematicId.value : null,
    thematicNameRu: isThematic ? thematicNameRu.value : null,
    thematicNameEn: isThematic ? thematicNameEn.value : null,
    routePoints: pointRouteItems.value,
    durationId: eventDurationId.value,
    durationNameRu: eventDurationNameRu.value,
    durationNameEn: eventDurationNameEn.value,
    partners: partnerItems.value,
    eventLanguageId: languageId.value,
    eventLanguageNameRu: languageNameRu.value,
    eventLanguageNameEn: languageNameEn.value,
    eventOrganizationId: organizerId.value,
    eventOrganizationNameRu: organizerNameRu.value,
    eventOrganizationNameEn: organizerNameEn.value,
    eventOrganizationLinkRu: organizerLinkRu.value,
    eventOrganizationLinkEn: organizerLinkEn.value,
    eventOrganizationEmail: organizerEmail.value,
    eventOrganizationPhone: organizerPhone.value,
    eventOrganizationInn: organizerInn.value,
    eventArtists: artistItems.value,
    eventSpeakers: speakerItems.value || [],
    eventModerators: moderatorItems.value || [],
    eventLecturers: lecturersItems.value || [],
    sessionGenerate: sessionGenerate.value,
    sessionGenerates: sessionGenerates.value || [],
    isSliderMP: Number(isSliderMP.value),
    organizers: selectedOrganizations.value || [],
    participants: selectedParticipants.value || [],
    venueId: selectedVenue.value || [],
    isEventRegister: isEventRegister.value,
  };

  isLoadingCreate.value = true;
  isLoadingData.value = true;
  serviceContentEventCreate(params).then(response => {
    isLoadingData.value = false;
    const status = response?.success;
    const message = status ? 'Успешно добавлено' : 'Ошибка, данные не были созданы';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      isLoadingCreate.value = false;

      saveMessageResponse.value = null;
    }, 3000);

    if (response.success && response?.data?.id) {
      router.push({name: 'ContentEventEdit', params: {id: response?.data?.id}});
    } else {
      console.log('error')
    }
  }).catch(() => {
    isLoadingCreate.value = false;
  });
}

const onHandleDeSelectOfficial = (id, action) => {
  if (contentEventCardTypeEnum.Speaker === action)
    speakerItems.value = speakerItems.value?.filter(item => item?.id !== id);
  else if (contentEventCardTypeEnum.Lecturer === action)
    lecturersItems.value = lecturersItems.value?.filter(item => item?.id !== id);
  else if (contentEventCardTypeEnum.Moderator === action)
    moderatorItems.value = moderatorItems.value?.filter(item => item?.id !== id);
}

const onHandleDeSelectOrganizer = (id) => {
  organizerId.value = null;
  organizerNameRu.value = '';
  organizerNameRuError.value = '';
  organizerNameEn.value = '';
  organizerNameEnError.value = '';
  organizerLinkRu.value = '';
  organizerLinkRuError.value = '';
  organizerLinkEn.value = '';
  organizerEmail.value = '';
  organizerEmailError.value = '';
  organizerPhone.value = '';
  organizerPhoneError.value = '';
  organizerInn.value = '';
  organizerInnError.value ='';
}

const onHandleSelectPartner = (id) => {
  const partner = partners.value?.find(item => item.id === id);
  if (!partner)
    return;

  if (partnerItems.value?.find(item => item.id === id))
    return;

  partnerItems.value.push({
    id: partner.id,
    nameRu: partner.titleRu,
    nameRuError: '',
    nameEn: partner.titleRu,
    nameEnError: '',
    shortDescriptionRu: partner.shortDescriptionRu,
    shortDescriptionRuError: '',
    shortDescriptionEn: partner.shortDescriptionEn,
    shortDescriptionEnError: '',
    image: partner.image,
    imageError: '',
    authorImageRu: partner.authorImageRu,
    authorImageRuError: '',
    authorImageEn: partner.authorImageEn,
    authorImageEnError: '',
    linkRu: partner.linkRu,
    linkRuError: partner.linkRuError,
    linkEn: partner.linkEn,
    linkEnError: partner.linkEnError,
    isMain: partner.isMain,
    isAbout: partner.isAbout,
    isPartners: partner.isPartners,
    isActiveRu: partner.isActiveRu,
    isActiveEn: partner.isActiveEn,
    isStatusRu: partner.isStatusRu,
    isStatusEn: partner.isStatusEn,
  });
}

const onHandleDeSelectPartner = (id) => {
  partnerItems.value = partnerItems.value?.filter(item => item?.id !== id);
}

const onHandleSelectArtist = (id) => {
  const artist = artists.value?.find(item => item.id === id);
  if (!artist)
    return;

  if (artistItems.value?.find(item => item.id === id))
    return;

  artistItems.value.push({
    id: artist.id,
    nameRu: artist.titleRu,
    nameRuError: '',
    nameEn: artist.titleEn,
    nameEnError: '',
    descriptionRu: artist.descriptionRu,
    descriptionRuError: '',
    descriptionEn: artist.descriptionEn,
    descriptionEnError: '',
    positionRu: artist.positionRu,
    positionRuError: '',
    positionEn: artist.positionEn,
    positionEnError: '',
    image: artist.image || null,
    imageError: '',
    authorRu: artist.authorRu,
    authorRuError: '',
    authorEn: artist.authorEn,
    authorEnError: '',
    activeRu: artist.activeRu,
    activeEn: artist.activeEn,
  });
}

const onHandleDeSelectArtist = (id) => {
  artistItems.value = artistItems.value?.filter(item => item?.id !== id);
}

const onHandleSelectLanguage = (id) => {
  const language = languages.value?.find(item => item.id === id);
  if (!language)
    return;

  languageId.value = language.id;
  languageNameRu.value = language.titleRu;
  languageNameRuError.value = '';
  languageNameEn.value = language.titleEn;
  languageNameEnError.value = '';
}

const onHandleDeSelectLanguage = (id) => {
  languageId.value = null;
  languageNameRu.value = '';
  languageNameRuError.value = '';
  languageNameEn.value = '';
  languageNameEnError.value = '';
}

const onHandleSelectPointRoute = (id) => {
  const route = pointRoutes.value?.find(item => item.id === id);
  if (!route)
    return;

  if (pointRouteItems.value?.find(item => item.id === id))
    return;

  pointRouteItems.value.push({
    id: route.id,
    nameRu: route.titleRu,
    nameRuError: '',
    nameEn: route.titleEn,
    nameEnError: '',
    selectedPavilion: route.pavilion?.id,
    selectedPavilionError: '',
    descriptionRu: route.descriptionRu,
    descriptionRuError: '',
    descriptionEn: route.descriptionEn,
    descriptionEnError: '',
    audio: route.audio,
    audioError: '',
    audioTextRu: route.audioTextRu,
    audioTextRuError: '',
    audioTextEn: route.audioTextEn,
    audioTextEnError: '',
    image: route.image,
    imageError: '',
    imageAuthorRu: route.imageAuthorRu,
    imageAuthorRuError: '',
    imageAuthorEn: route.imageAuthorEn,
    imageAuthorEnError: '',
    isActiveRu: true,
  });
}

const onHandleDeSelectPointRoute = (id) => {
  pointRouteItems.value = pointRouteItems.value?.filter(item => item?.id !== id);
}

const onHandleSelectThematic = (id) => {
  const data = weekThematics.value?.find(item => item.id === id);
  if (data) {
    thematicNameRu.value = data.titleRu;
    thematicNameRuError.value = '';
    thematicNameEn.value = data.titleEn;
    thematicNameEnError.value = '';
    thematicId.value = data.id;
  }
}

const onHandleDeSelectThematic = (id) => {
  selectedWeekThematics.value = [];
  thematicNameRu.value = '';
  thematicNameEn.value = '';
  thematicId.value = null;
}

const onHandleSelectEventDuration = (id) => {
  const data = eventDurations.value?.find(item => item.id === id);
  if (data) {
    eventDurationNameRu.value = data.titleRu;
    eventDurationNameRuError.value = '';
    eventDurationNameEn.value = data.titleEn;
    eventDurationNameEnError.value = '';
    eventDurationId.value = data.id;
  }
}

const onHandleDeSelectEventDuration = (id) => {
  selectedEventDurations.value = [];
  eventDurationNameRu.value = '';
  eventDurationNameRuError.value = '';
  eventDurationNameEn.value = '';
  eventDurationNameEnError.value = '';
  thematicId.value = null;
}

const onHandleSelectOfficial = (id, action) => {
  const official = officials.value?.find(item => item.id === id);
  if (!official)
    return;

  const params = {
    id: official.id,
    typeSpeaker: null,
    nameRu: official.titleRu,
    nameRuError: '',
    nameEn: official.titleEn,
    nameEnError: '',
    descriptionRu: official.descriptionRu,
    descriptionRuError: '',
    descriptionEn: official.descriptionEn,
    descriptionEnError: '',
    positionRu: official.positionRu,
    positionRuError: '',
    positionEn: official.positionEn,
    positionEnError: '',
    image: official.image || null,
    imageError: '',
    authorRu: official.authorRu,
    authorRuError: '',
    authorEn: official.authorEn,
    authorEnError: '',
  }

  if (contentEventCardTypeEnum.Speaker === action) {
    if (speakerItems.value?.find(item => item.id === id))
      return;

    speakerItems.value.push(params);
  } else if (contentEventCardTypeEnum.Lecturer === action) {
    if (lecturersItems.value?.find(item => item.id === id))
      return;

    lecturersItems.value.push(params);
  } else if (contentEventCardTypeEnum.Moderator === action) {
    if (moderatorItems.value?.find(item => item.id === id))
      return;

    moderatorItems.value.push(params);
  }
}

const onHandleSelectOrganizer = (value) => {
  const organizer = eventOrganizers.value?.find(item => item.id === value);
  if (!organizer)
    return;

  organizerId.value = organizer.id;
  organizerNameRu.value = organizer.nameRu;
  organizerNameRuError.value = '';
  organizerNameEn.value = organizer.nameEn;
  organizerNameEnError.value = '';
  organizerLinkRu.value = organizer.linkRu;
  organizerLinkRuError.value = '';
  organizerLinkEn.value = organizer.linkEn;
  organizerLinkEnError.value = '';
  organizerEmail.value = organizer.email;
  organizerEmailError.value = '';
  organizerPhone.value = organizer.phone;
  organizerPhoneError.value = '';
  organizerInn.value = organizer.inn;
  organizerInnError.value = '';
}

const onHandleClickDeleteCard = (value) => {

  const {action, index} = value;

  if (action === contentEventCardTypeEnum.Speaker) {
    speakerItems.value?.splice(index, 1);
    selectedSpeakers.value = speakerItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  }
  if (action === contentEventCardTypeEnum.Lecturer) {
    lecturersItems.value?.splice(index, 1);
    selectedLecturers.value = lecturersItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  }
  if (action === contentEventCardTypeEnum.Moderator) {
    moderatorItems.value?.splice(index, 1);
    selectedModerators.value = moderatorItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  } else if (action === contentEventCardTypeEnum.Organizer) {
    eventOrganizerItems.value?.splice(index, 1);
    selectedOrganizations.value = eventOrganizerItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  } else if (action === contentEventCardTypeEnum.Artists) {
    artistItems.value?.splice(index, 1);
    selectedArtists.value = artistItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  } else if (action === contentEventCardTypeEnum.Language) {
    languageItems.value?.splice(index, 1);
    selectedLanguages.value = languageItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  } else if (action === contentEventCardTypeEnum.Partner) {
    partnerItems.value?.splice(index, 1);
    selectedPartners.value = partnerItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  } else if (action === contentEventCardTypeEnum.PointRoute) {
    pointRouteItems.value?.splice(index, 1);
    selectedPointRoutes.value = pointRouteItems.value?.filter(item => typeof item?.id === 'string').map(item => item.id);
  }
}

const clearSelectedEventTypeCard = () => {
  selectedSpeakers.value = [];
  speakerItems.value = [];

  selectedLecturers.value = [];
  lecturersItems.value = [];

  selectedModerators.value = [];
  moderatorItems.value = [];

  selectedEventOrganizers.value = null;
  organizerId.value = null;
  organizerNameRu.value = '';
  organizerNameRuError.value = '';
  organizerNameEn.value = '';
  organizerNameEnError.value = '';
  organizerLinkRu.value = '';
  organizerLinkRuError.value = '';
  organizerLinkEn.value = '';
  organizerEmail.value = '';
  organizerEmailError.value = '';
  organizerPhone.value = '';
  organizerPhoneError.value = '';
  organizerInn.value = '';
  organizerInnError.value = '';

  selectedWeekThematics.value = [];
  thematicNameRu.value = '';
  thematicNameRuError.value = '';
  thematicNameEn.value = '';
  thematicNameEnError.value = '';

  selectedPointRoutes.value = [];
  pointRouteItems.value = [];

  selectedEventDurations.value = [];
  eventDurationNameRu.value = '';
  eventDurationNameRuError.value = '';
  eventDurationNameEn.value = '';
  eventDurationNameEnError.value = '';

  selectedPartners.value = [];
  partnerItems.value = [];

  selectedLanguages.value = null;
  languageId.value = '';
  languageNameRu.value = '';
  languageNameRuError.value = '';
  languageNameEn.value = '';
  languageNameEnError.value = '';

  selectedArtists.value = [];
  artistItems.value = [];
}

watch(selectedEventTypeCard, () => {
  clearSelectedEventTypeCard();

  categories.value = oldCategories.value;
  groups.value = oldGroups.value;

  if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && (item.code === 'business_event' || item.code === 'excursion'))) {
    Promise.all([serviceEventOfficialList(), serviceEventOrganizationList()])
        .then(([responseEventOfficialList, responseEventOrganizationList]) => {
          officials.value = responseEventOfficialList?.data || [];
          eventOrganizers.value = responseEventOrganizationList?.data || [];
        })
        .catch(error => {
          console.error('Ошибка при выполнении запросов:', error);
        });
  }

  if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && item.code === 'business_event')) {
    Promise.all([serviceEventThematicList()])
        .then(([responseEventThematicList]) => {
          weekThematics.value = responseEventThematicList?.data || [];
        })
        .catch(error => {
          console.error('Ошибка при выполнении запросов:', error);
        });
  } else if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && item.code === 'excursion')) {
    Promise.all([serviceEventDurationList(), serviceRoutePointList(), servicePartnerList(), serviceEventLanguageList()])
        .then(([responseEventDurationList, responseRoutePointList, responsePartnerList, responseLanguageList]) => {
          eventDurations.value = responseEventDurationList?.data || [];
          pointRoutes.value = responseRoutePointList?.data || [];
          partners.value = responsePartnerList?.data || [];
          languages.value = responseLanguageList?.data || [];
        })
        .catch(error => {
          console.error('Ошибка при выполнении запросов:', error);
        });
  } else if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && item.code === 'cultural_program')) {

    Promise.all([serviceEventArtistList()])
        .then(([responseEventArtistList]) => {
          artists.value = responseEventArtistList?.data || [];
        })
        .catch(error => {
          console.error('Ошибка при выполнении запросов:', error);
        });
  } else if (eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && item.code === 'default_event')) {
    categories.value = categories.value?.filter(item => item.nameRu !== 'Деловая программа');
    groups.value = groups.value?.filter(item => item.nameRu !== 'Деловая программа');
  }
});

const validateExhibitor = () => {
  let isError = false;

  const valueName = nameRu.value?.trim();
  nameRuError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameRuError.value)
    isError = true;

  const valueShortDescriptionRu = shortDescriptionRu.value?.trim();
  shortDescriptionRuError.value = valueShortDescriptionRu?.length ? '' : 'Поле не должно быть пустым';

  if (!shortDescriptionRuError.value?.length && valueShortDescriptionRu?.length > 600)
    shortDescriptionRuError.value = 'Не должно превышать более 600 символов';

  if (shortDescriptionRuError.value)
    isError = true;

  const valueDescriptionRu = descriptionRu.value?.trim();
  descriptionRuError.value = valueDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionRuError.value)
    isError = true;

  selectedCategoryError.value = selectedCategories.value?.length ? '' : 'Вы не выбрали категорию';
  if (selectedCategoryError.value)
    isError = true;

  selectedOrganizerError.value = selectedOrganizer.value?.length ? '' : 'Вы не выбрали организатора';
  if (selectedOrganizerError.value)
    isError = true;

  selectedAgeLimitError.value = selectedAgeLimits.value?.length ? '' : 'Вы не выбрали возрастное ограничение';
  if (selectedAgeLimitError.value)
    isError = true;

  selectedTypeError.value = selectedTypes.value?.length ? '' : 'Вы не выбрали тип мероприятия';
  if (selectedTypeError.value)
    isError = true;

  publishedAtError.value = publishedAt.value ? '' : 'Поле не должно быть пустым';
  if (publishedAtError.value)
    isError = true;

  imageError.value = image.value ? '' : 'Вы не выбрали файл';
  if (imageError.value)
    isError = true;

  selectedPavilionError.value = selectedPavilions.value?.length ? '' : 'Вы не выбрали павильон';
  if (selectedPavilionError.value)
    isError = true;

  selectedGroupError.value = selectedGroups.value?.length ? '' : 'Вы не выбрали группы';
  if (selectedGroupError.value)
    isError = true;

  return isError;
}

const validateAdministrationAgreed = () => {
  return false;
}

const onHandleClickDeleteMessage = (index) => {
  messages.value.splice(index, 1);
}

const onHandleClickSendMessage = () => {
  if (messages.value.length)
    return null;

  messages.value.push({
    message: messageData.value,
    createdAt: moment().format('DD.MM.YYYY HH:mm'),
    isAnswer: false,
    user,
  });

  messageData.value = null;
}

const onHandleClickImageDelete = () => {
  image.value = null;
}

const onHandleClickSliderImageDelete = (index) => {
  sliders.value[index].image = null;
}

const validateLanguage = () => {
  let isError = false;

  if (languageId.value)
    return false;

  languageNameRuError.value = languageNameRu.value?.length ? '' : 'Обязательно для заполнения';
  if (languageNameRuError.value?.length)
    isError = true;

  return isError;
}

const validateThematic = () => {
  if (thematicId.value?.length)
    return false;

  thematicNameRuError.value = thematicNameRu.value?.length ? '' : 'Обязательно для заполнения';
  if (thematicNameRuError.value?.length) {
    messageThematicEmpty.value = 'Добавьте хотя-бы одну тематику деловой недели';
  }

  return !!thematicNameRuError.value?.length;
}

const validateDuration = () => {
  if (eventDurationId.value)
    return false;

  eventDurationNameRuError.value = eventDurationNameRu.value?.length ? '' : 'Обязательно для заполнения';
  if (eventDurationNameRuError.value?.length) {
    messageDurationEmpty.value = 'Добавьте хотя-бы одну продолжительность для экскурсий';
  }

  return !!eventDurationNameRuError.value?.length;
}

const onHandleDeleteSession = (index) => {
  if (!sessions.value?.length)
    return null;

  sessions.value.splice(index, 1);
}

const onHandleClickSessionCreate = () => {
  sessions.value.push({
    name: '',
    nameError: '',
    dateStart: null,
    dateStartError: '',
    timeStart: null,
    timeStartError: '',
    timeEnd: null,
    timeEndError: null,
    datePlanOpen: null,
    datePlanOpenError: '',
    registrationOffDate: null,
    registrationOffDateError: null,
    isOpenRegistration: true,
    isActive: true,
  });
}

const onHandleAddSessionGenerate = () => {
  sessionGenerates.value?.push(
      {
        weeks: [
          {
            name: 'ПН',
            value: false,
          },
          {
            name: 'ВТ',
            value: false,
          },
          {
            name: 'СР',
            value: false,
          },
          {
            name: 'ЧТ',
            value: false,
          },
          {
            name: 'ПТ',
            value: false,
          },
          {
            name: 'СБ',
            value: false,
          },
          {
            name: 'ВС',
            value: false,
          },
        ],
        weekError: null,
        name: '',
        nameError: null,
        dateStart: null,
        dateStartError: null,
        dateEnd: null,
        dateEndError: null,
        timeStart: null,
        timeStartError: null,
        timeEnd: null,
        timeEndError: null,
        datePlanOpen: null,
        datePlanOpenError: null,
        registrationOffDate: null,
        registrationOffDateError: null,
        isOpenRegistration: true,
        isActive: true,
        isHidden: true,
      }
  );
}

const onHandleClickSliderCreate = () => {
  sliders.value.push({
    image: null,
    imageError: null,
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
    sort: 100,
    sortError: '',
  });
}

const onHandleClickOfficialCreate = (action) => {
  saveMessageResponse.value = {
    status: true,
    message: 'Добавлена карточка, создания нового '
  };

  const params = {
    typeSpeaker: null,
    nameRu: '',
    nameRuError: '',
    nameEn: '',
    nameEnError: '',
    descriptionRu: '',
    descriptionRuError: '',
    descriptionEn: '',
    descriptionEnError: '',
    positionRu: '',
    positionRuError: '',
    positionEn: '',
    positionEnError: '',
    image: null,
    imageError: '',
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
  };

  if (action === contentEventCardTypeEnum.Speaker) {
    saveMessageResponse.value.message += 'спикера';
    speakerItems.value.push(params);
  } else if (action === contentEventCardTypeEnum.Lecturer) {
    saveMessageResponse.value.message += 'докладчика';
    lecturersItems.value.push(params);
  } else if (action === contentEventCardTypeEnum.Moderator) {
    saveMessageResponse.value.message += 'модератора';
    moderatorItems.value.push(params);
  }

  setTimeout(() => {
    saveMessageResponse.value = null;
  }, 2000);
}

const onHandleClickPartnerCreate = () => {
  saveMessageResponse.value = {
    status: true,
    message: 'Добавлена карточка, создания нового партнера'
  };

  setTimeout(() => {
    saveMessageResponse.value = null;
  }, 2000);

  partnerItems.value.push({
    nameRu: '',
    nameRuError: '',
    nameEn: '',
    nameEnError: '',
    shortDescriptionRu: '',
    shortDescriptionRuError: '',
    shortDescriptionEn: '',
    shortDescriptionEnError: '',
    image: null,
    imageError: '',
    authorImageRu: '',
    authorImageRuError: '',
    authorImageEn: '',
    authorImageEnError: '',
    linkRu: '',
    linkRuError: '',
    linkEn: '',
    linkEnError: '',
    isMain: false,
    isAbout: true,
    isPartners: true,
    isActiveRu: true,
    isActiveEn: true,
    isStatusRu: true,
    isStatusEn: true,
  });
}

const onHandleClickArtistCreate = () => {
  saveMessageResponse.value = {
    status: true,
    message: 'Добавлена карточка, создания нового артиста'
  };

  setTimeout(() => {
    saveMessageResponse.value = null;
  }, 2000);

  artistItems.value.push({
    nameRu: '',
    nameRuError: '',
    nameEn: '',
    nameEnError: '',
    descriptionRu: '',
    descriptionRuError: '',
    descriptionEn: '',
    descriptionEnError: '',
    positionRu: '',
    positionRuError: '',
    positionEn: '',
    positionEnError: '',
    image: null,
    imageError: '',
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
    activeRu: '',
    activeEn: '',
  });
}

const onHandleClickPointRouteCreate = () => {
  saveMessageResponse.value = {
    status: true,
    message: 'Добавлена карточка, создания новой точки маршрута'
  };

  setTimeout(() => {
    saveMessageResponse.value = null;
  }, 2000);

  pointRouteItems.value.push({
    nameRu: '',
    nameRuError: '',
    nameEn: '',
    nameEnError: '',
    selectedPavilion: [],
    selectedPavilionError: '',
    descriptionRu: '',
    descriptionRuError: '',
    descriptionEn: '',
    descriptionEnError: '',
    audio: null,
    audioError: null,
    audioTextRu: '',
    audioTextRuError: '',
    audioTextEn: '',
    audioTextEnError: '',
    image: null,
    imageError: '',
    imageAuthorRu: null,
    imageAuthorRuError: null,
    imageAuthorEn: null,
    imageAuthorEnError: null,
    isActiveRu: true,
  });
}

const onHandleDeleteSlider = (index) => {
  sliders.value.splice(index, 1);
}

const validateSessionDateEvent = (dateStart, datePlanOpen) => {
  if (dateStart && datePlanOpen) {
    if (dateStart < datePlanOpen) {
      return {dateStartError: 'Время старта должно быть больше планового открытия', datePlanOpenError: 'Дата планового открытия должно быть меньше старта'};
    } else {
      return {dateStartError: '', datePlanOpenError: ''};
    }
  }

  return {dateStartError: '', datePlanOpenError: ''};
}

const validateSessionTime = (index) => {
  const timeStart = sessions.value[index].timeStart;
  const timeEnd = sessions.value[index].timeEnd;

  const error = validateTimeRangeEndToStart(timeStart, timeEnd, 'Время начала не может быть позже даты окончания', 'Время окончания не может быть раньше даты начала');

  sessions.value[index].timeStartError = error.timeStartError;
  sessions.value[index].timeEndError = error.timeEndError;
}

const isBusinessRoleError = ref(false)

watch(selectedEventTypeCard, () => {
  const typeCard = eventTypeCards.value?.find(item => item.id === selectedEventTypeCard.value && (item.code === 'business_event' || item.code === 'cultural_program' || item.code === 'excursion'));
  isBusinessRoleError.value = !!(!checkRole(['admin', 'content_manager', 'manager']) && typeCard);
});

watch(selectedOrganizer, () => {
  if (selectedOrganizer.value?.length) {
    selectedOrganizations.value = selectedOrganizations.value?.filter(id => id !== selectedOrganizer.value)
  }
});

watch(selectedPavilions, () => {
  selectedVenue.value = null;
  if (selectedPavilions.value?.length) {
    servicePavilionVenuesList(selectedPavilions.value[0]).then(response => {
      venues.value = response.data;
    });
  }
});

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.tabs {
  margin-bottom: 35px;
}

.tabs .tab.active {
  background: #c6d6f4;
}

.tabs .tab {
  background: #f0f8ff;
  transition: all .3s;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.tabs .tab:hover {
  cursor: pointer;
  transition: all .3s;
  background: #d0e9ff;
}

.sessions .session-item {
  padding: 15px 15px 0 15px;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.session-add {
  margin-top: 15px;
}

.session-add .session-btn {
  text-align: center;
  padding: 15px;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #c6d6f4;
  transition: all .3s;
}

.session-add .session-btn:hover {
  background: #edf7ff;
  border-color: #92b8ff;
  transition: all .3s;
}

.content-item-add {
  margin-top: 30px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #c6d6f4;
  transition: all .3s;
}

.content-item-add .content-item-btn {
  transition: all .3s;
  padding: 15px;
  border-radius: 30px;
}

.content-item-add .content-item-btn:hover {
  background: #edf7ff;
  border-color: #92b8ff;
  transition: all .3s;
}

.sessions .session-content-week .session-week {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.sessions .session-content-week .session-week .session-week-item .week {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sessions .session-content-week .session-week .session-week-item .week label {
  width: 100%;
  margin-left: 0;
  text-align: center;
  margin-top: 5px;
}

.card-event .tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.card-event .tabs .tab {
  padding: 10px;
  margin: 5px;
}


</style>
