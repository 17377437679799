<template>
  <nav class="fixed z-30 w-full bg-white border-b border-gray-200">
    <div class="py-3 px-3 lg:px-5 lg:pl-3">
      <div class="flex justify-between items-center">
        <div class="flex justify-start items-center">
          <button @click="clickToggleBtn()" id="toggleSidebar" aria-expanded="true" aria-controls="sidebar"
                  class="hidden p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"></path>
            </svg>
          </button>
          <button @click="clickToggleBtnMobile()" id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar"
                  class="p-2 mr-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100">
            <svg id="toggleSidebarMobileHamburger" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"></path>
            </svg>
            <svg id="toggleSidebarMobileClose" class="hidden w-6 h-6" fill="currentColor"
                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
          </button>
          <router-link :to="{name: 'Dashboard'}" href="./" class="flex mr-14">
<!--            <svg width="82" height="19" viewBox="0 0 82 19" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M0.04 0.599999V18H3.088V11.424H5.824C9.928 11.424 13.168 10.464 13.168 6.072C13.168 1.2 9.592 0.599999 5.776 0.599999H0.04ZM5.776 3.048C7.912 3.048 10 3.288 10 6.096C10 8.592 8.224 8.928 5.92 8.928H3.088V3.048H5.776ZM17.1882 11.568C17.1882 8.592 18.7722 7.128 20.5962 7.128C22.4202 7.128 24.0042 8.592 24.0042 11.568C24.0042 14.544 22.4202 15.984 20.5962 15.984C18.7722 15.984 17.1882 14.544 17.1882 11.568ZM20.5962 4.8C17.4522 4.8 14.2362 6.912 14.2362 11.568C14.2362 16.248 17.4522 18.312 20.5962 18.312C23.7402 18.312 26.9562 16.248 26.9562 11.568C26.9562 6.912 23.7402 4.8 20.5962 4.8ZM34.8919 4.8C31.0279 4.8 28.5559 7.728 28.5559 11.568C28.5559 15.408 31.0279 18.312 34.7719 18.312C37.5319 18.312 40.1959 16.608 40.7719 13.584H37.9879C37.6039 15.312 36.2359 15.936 34.8199 15.936C33.1399 15.936 31.5079 14.616 31.5079 11.544C31.5079 8.496 33.0919 7.176 34.8919 7.176C36.4519 7.176 37.6279 8.136 37.8919 9.504H40.6759C40.1719 6.648 37.7239 4.8 34.8919 4.8ZM48.4857 4.8C44.6217 4.8 42.1497 7.728 42.1497 11.568C42.1497 15.408 44.6217 18.312 48.3657 18.312C51.1257 18.312 53.7897 16.608 54.3657 13.584H51.5817C51.1977 15.312 49.8297 15.936 48.4137 15.936C46.7337 15.936 45.1017 14.616 45.1017 11.544C45.1017 8.496 46.6857 7.176 48.4857 7.176C50.0457 7.176 51.2217 8.136 51.4857 9.504H54.2697C53.7657 6.648 51.3177 4.8 48.4857 4.8ZM68.5606 5.088H65.7046L59.5606 14.088V5.088H56.7046V18H59.5606L65.7046 9.048V18H68.5606V5.088ZM81.8789 5.088H76.3829C73.1429 5.088 71.4389 6.576 71.4389 9.192C71.4389 11.04 72.4469 12.24 73.8149 12.792L70.5749 18H73.8149L76.4549 13.536H79.0229V18H81.8789V5.088ZM79.0229 7.344V11.28H76.6709C75.2309 11.28 74.3429 10.776 74.3429 9.408C74.3429 8.064 75.2789 7.344 76.7909 7.344H79.0229Z" fill="#060606"/>-->
<!--            </svg>-->
            <span style="font-size: 24px">Личный кабинет</span>
          </router-link>

        </div>
        <div class="flex items-center">
          <div class="relative" v-if="false">
            <button id="actionsDropdownButton" @click.prevent="openActionsDropdown = !openActionsDropdown" class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
              <svg class="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
              <span>{{ locales.find(item => item.code === t.locale.value).label }}</span>
            </button>
            <div id="actionsDropdown" class="mt-1 w-full z-10 bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 absolute" :class="{hidden: !openActionsDropdown}">
              <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                <li v-for="locale in locales">
                  <a href="#" @click.prevent="setLocale(locale.code); openActionsDropdown = !openActionsDropdown;" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{ locale.label }}</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="flex items-center ml-3">
            <div class="p-2">
              {{ user ? user.name : null }}
            </div>
            <button @click="authStore.logout()" type="button"
                    class="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useAuthStore} from "../../../stores/AuthStore.js";
import {useInterfaceStore} from "../../../stores/InterfaceStore.js";

import {useI18n} from "vue-i18n";

const t = useI18n();

const authStore = useAuthStore();
const interfaceStore = useInterfaceStore();

const openActionsDropdown = ref(false)

let user = ref(null);

const locales = [
  {
    code: 'ru',
    label: 'RU',
  },
  {
    code: 'en',
    label: 'EN',
  },
  {
    code: 'zh',
    label: 'CN',
  },
  {
    code: 'ar',
    label: 'AR',
  },
];

authStore.getProfile().then((r) => {
  user.value = r.data.data;
}).catch((e) => {
  localStorage.removeItem('authUser');
  localStorage.removeItem('token');
})

import {toggleSidebar, toggleSidebarMobile} from "../../../utilits/sidebar.js";

onMounted(() => {
  // toggleSidebar(interfaceStore.sidebarExpanded)
})

const clickToggleBtn = () => {
  interfaceStore.toggleSidebar()
  toggleSidebar(interfaceStore.sidebarExpanded)
}

const clickToggleBtnMobile = () => {
  interfaceStore.toggleSidebar()
  toggleSidebarMobile(interfaceStore.sidebarExpanded)
}

const setLocale = (code) => {
  localStorage.setItem('locale', code);
  t.locale.value = code
}
</script>
