import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceDocumentTypContour(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/document-types-contours/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}
