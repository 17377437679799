<template>
  <spinner v-if="isEntitySaved" />

  <div v-if="!isEntitySaved" class="grid grid-cols-2 gap-7 px-7 pt-7">
    <div class="col-span-1 mb-4 xl:mb-2">
      <div v-if="isEntitySaved" class="w-full p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
        {{ $t('common.documentSaved') }}
      </div>


      <div
          v-if="saveMessageResponse"
          style="z-index: 100; top: 80px"
          id="toast-top-right"
          :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
          class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
          role="alert">
        <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
      </div>

      <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">{{ $t('common.contour_type_create') }}</h1>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <div class="p-7 pb-12 border-b border-gray-200">
                  <form action="">
                    <div class="grid gap-4 mb-4 sm:grid-cols-2">
                      <div class="sm:col-span-2">
                        <input-text
                            v-model="valueName"
                            :label="$t('directoryDocuments.name')" />
                        <p
                            v-if="errorName"
                            class="mt-2 text-sm text-red-600">
                          {{ errorName }}
                        </p>
                      </div>
                      <div class="sm:col-span-2">
                        <Multiselect v-model="selectedDocType"
                                     :max="checkRole(['admin', 'manager']) ? -1 : 1"
                                     :options="docType"
                                     :mode="'tags'"
                                     :valueProp="'id'"
                                     track-by="name"
                                     label="name"
                                     :close-on-select="false"
                                     :searchable="true"
                                     :placeholder="'Тип карточки'" />
                      </div>
                      <div class="sm:col-span-2">
                        <input-text v-model="valueSort" :label="$t('directoryDocuments.sort')"/>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <drop-file v-model="file" :type="fileType" :single="true" />
                    </div>
                  </form>
                </div>
                <div class="p-7 flex justify-end">
                  <button @click.prevent="onHandleSave" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                    <span>{{ $t('common.save') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import {useRoute, useRouter} from 'vue-router'
import {serviceContourSave, serviceContourUniqueName} from "../../../../service/lists/serviceListContour.js";
import Spinner from "../../../flowbite/spinner.vue";
import Multiselect from '@vueform/multiselect';
import {checkRole} from "../../../../utilits/functions";
import {serviceCoordinationTypeList} from "../../../../service/lists/serviceListCoordinationType.js";
import DropFile from "../../../flowbite/form/dropFile.vue";

const isEntitySaved = ref(false);
const valueName = ref('');
const errorName = ref(null);
const saveMessageResponse = ref(null);
const file = ref(null);
const fileType = {
      extensions: ["doc", "docx", "xlsx", "pdf", "png", "svg", "mp3", "mp4", 'psd', 'tiff', 'eps', 'ai'],
      max_filesize: 10,
};

const valueSort = ref(500);
const docType = ref([]);
const selectedDocType = ref([]);

// Первоначальные данные
const route = useRoute()
const router = useRouter()

serviceCoordinationTypeList().then(response => {
  docType.value = response?.data || [];
});

const deleteFile = () => {

}

const onHandleSave = () => {
  isEntitySaved.value = true;

  errorName.value = !valueName.value?.length ? "Не должно быть пустым" : null;
  if (errorName.value) {
    return null;
  }

  serviceContourSave({name: valueName.value, sort: valueSort.value, documentTypes: selectedDocType.value, file: file.value}).then(response => {
    isEntitySaved.value = false;

    const status = response?.success;
    const message = status ? 'Успешно добавлено' : 'Ошибка, данные не были созданы';

    saveMessageResponse.value = {
      message,
      status,
    };

    if (status) {
      valueName.value = '';
      valueSort.value = 500;
      selectedDocType.value = [];
    }

    setTimeout(() => {
      saveMessageResponse.value = null;
    }, 3000);
  });
};

const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

</script>
