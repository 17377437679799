import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from '../stores/AuthStore.js'
import TheLoginPage from '../components/auth/TheLoginPage.vue'
import TheUsers from '../components/pages/TheUsers.vue'
import TheUserEdit from '../components/pages/TheUserEdit.vue'
import TheForgotPassword from '../components/auth/TheForgotPassword.vue'
import TheRegistry from '../components/pages/TheRegistry.vue'
import TheRegistryEdit from '../components/pages/TheRegistryEdit.vue'
import TheDocsDirectory from '../components/pages/TheDocsDirectory.vue'
import TheDocsDirectoryEdit from '../components/pages/TheDocsDirectoryEdit.vue'
import TheExhibitorTypesDirectory from '../components/pages/TheExhibitorTypesDirectory.vue'
import TheExhibitorTypesDirectoryEdit from '../components/pages/TheExhibitorTypesEdit.vue'
import TheFederalDistrictsDirectory from '../components/pages/TheFederalDistrictsDirectory.vue'
import TheFederalDistrictsDirectoryEdit from '../components/pages/TheFederalDistrictsEdit.vue'
import CoordinationCreate from '../components/pages/coordination/CoordinationCreate.vue'
import CoordinationEdit from '../components/pages/coordination/CoordinationEdit.vue'
import ListContour from '../components/pages/lists/contour/Contour.vue'
import ListContourCreate from '../components/pages/lists/contour/ContourCreate.vue'
import ListContourEdit from '../components/pages/lists/contour/ContourEdit.vue'
import ListVenue from '../components/pages/lists/venue/Venue.vue'
import ListVenueCreate from '../components/pages/lists/venue/VenueCreate.vue'
import ListVenueEdit from '../components/pages/lists/venue/VenueEdit.vue'
import TheLogs from '../components/pages/TheLogs.vue';
import i18n from "../i18n.js";
import {checkRole} from "../utilits/functions";
import AdminMessageExhibitorCreate from '../components/pages/messageExhibitor/admin/MessageExhibitorCreate.vue';
import AdminMessageExhibitorEdit from '../components/pages/messageExhibitor/admin/MessageExhibitorEdit.vue';
import ExhibitorMessageExhibitorEdit from '../components/pages/messageExhibitor/exhibitor/MessageExhibitorEdit.vue';

import AdminDocumentCreate from '../components/pages/document/admin/DocumentCreate.vue';
import AdminDocumentEdit from '../components/pages/document/admin/DocumentEdit.vue';
import ExhibitorDocumentCreate from '../components/pages/document/exhibitor/DocumentCreate.vue';
import ExhibitorDocumentEdit from '../components/pages/document/exhibitor/DocumentEdit.vue';

import Contents from '../components/pages/contents/Contents.vue';

import ContentNewsCreate from '../components/pages/contents/news/NewsCreate.vue';
import ContentNewsEdit from '../components/pages/contents/news/NewsEdit.vue';

import ContentProfileCreate from '../components/pages/contents/profile/ProfileCreate.vue';
import ContentProfileEdit from '../components/pages/contents/profile/ProfileEdit.vue';

import ContentEventCreate from '../components/pages/contents/event/EventCreate.vue';
import ContentEventEdit from '../components/pages/contents/event/EventEdit.vue';

import ContentProductCreate from '../components/pages/contents/product/ProductCreate.vue';
import ContentProductEdit from '../components/pages/contents/product/ProductEdit.vue';

import ContentAudioGuildCreate from '../components/pages/contents/audioGuilde/AudioGuildeCreate.vue';
import ContentAudioGuildEdit from '../components/pages/contents/audioGuilde/AudioGuildeEdit.vue';

import NewsCategory from '../components/pages/lists/contents/newsCategory/NewsCategory.vue';
import NewsCategoryShow from '../components/pages/lists/contents/newsCategory/NewsCategoryShow.vue';
import AgeLimit from '../components/pages/lists/contents/ageLimit/AgeLimit.vue';
import AgeLimitShow from '../components/pages/lists/contents/ageLimit/AgeLimitShow.vue';
import EventType from '../components/pages/lists/contents/eventType/EventType.vue';
import EventTypeShow from '../components/pages/lists/contents/eventType/EventTypeShow.vue';
import ParticipantCategory from '../components/pages/lists/contents/participantCategory/ParticipantCategory.vue';
import ParticipantCategoryShow from '../components/pages/lists/contents/participantCategory/ParticipantCategoryShow.vue';
import Pavilion from '../components/pages/lists/contents/pavition/Pavilion.vue';
import PavilionShow from '../components/pages/lists/contents/pavition/PavilionShow.vue';
import Venue from '../components/pages/lists/contents/eventVenue/EventVenue.vue';
import VenueShow from '../components/pages/lists/contents/eventVenue/EventVenueShow.vue';
import EventOrganization from '../components/pages/lists/contents/eventOrganization/EventOrganization.vue';
import EventOrganizationShow from '../components/pages/lists/contents/eventOrganization/EventOrganizationShow.vue';
import EventCategory from '../components/pages/lists/contents/eventCategory/EventCategory.vue';
import EventCategoryShow from '../components/pages/lists/contents/eventCategory/EventCategoryShow.vue';
import EventOfficial from "../components/pages/lists/contents/eventOfficial/EventOfficial.vue";
import EventOfficialShow from "../components/pages/lists/contents/eventOfficial/EventOfficialShow.vue";
import EventArtist from "../components/pages/lists/contents/eventArtist/EventArtist.vue";
import EventArtistShow from "../components/pages/lists/contents/eventArtist/EventArtistShow.vue";
import EventThematic from "../components/pages/lists/contents/eventThematic/EventThematic.vue";
import EventThematicShow from "../components/pages/lists/contents/eventThematic/EventThematicShow.vue";
import RoutePoint from "../components/pages/lists/contents/routePoint/RoutePoint.vue";
import RoutePointShow from "../components/pages/lists/contents/routePoint/RoutePointShow.vue";
import Partner from "../components/pages/lists/contents/partner/Partner.vue";
import PartnerShow from "../components/pages/lists/contents/partner/PartnerShow.vue";
import EventDuration from "../components/pages/lists/contents/eventDuration/EventDuration.vue";
import EventDurationShow from "../components/pages/lists/contents/eventDuration/EventDurationShow.vue";
import EventLanguage from "../components/pages/lists/contents/eventLanguage/EventLanguage.vue";
import EventLanguageShow from "../components/pages/lists/contents/eventLanguage/EventLanguageShow.vue";
import ParticipantShow from "../components/pages/lists/contents/participant/ParticipantShow.vue";
import Participant from "../components/pages/lists/contents/participant/Participant.vue";
import EventGroup from "../components/pages/lists/contents/eventGroup/eventGroup.vue";
import EventGroupShow from "../components/pages/lists/contents/eventGroup/eventGroupShow.vue";

import Setting from "../components/pages/setting/Setting.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: TheLoginPage,
        meta: {
            layout: 'TheExternal',
            header: 'common.enter',
            roles: '*',
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: TheForgotPassword,
        meta: {
            layout: 'TheExternal',
            header: 'common.forgotPassword',
            roles: '*',
        }
    },
    {
        path: '/admin',
        name: 'Dashboard',
        component: TheRegistry,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager', 'exhibitor'],
        },
    },
    {
        path: '/coordination/create/:contour?',
        name: 'CoordinationCreate',
        component: CoordinationCreate,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager', 'exhibitor'],
        },
    },
    {
        path: '/coordination/edit/:id',
        name: 'CoordinationEdit',
        component: CoordinationEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager', 'exhibitor'],
        },
    },
    {
        path: '/admin/lists/contour',
        name: 'ListContour',
        component: ListContour,
        meta: {
            layout: 'TheInternal',
            header: 'common.reference_contour_type',
            breadCrumbs: [
                {
                    title: 'common.reference_contour_type',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        },
    },
    {
        path: '/admin/lists/contour/create',
        name: 'ListContourCreate',
        component: ListContourCreate,
        meta: {
            layout: 'TheInternal',
            header: 'common.contour_type_create',
            breadCrumbs: [
                {
                    title: 'common.contour_type_create',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        },
    },
    {
        path: '/admin/lists/contour/edit/:id',
        name: 'ListContourEdit',
        component: ListContourEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.contour_type_edit',
            breadCrumbs: [
                {
                    title: 'common.contour_type_edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        },
    },
    {
        path: '/admin/lists/venue',
        name: 'ListVenue',
        component: ListVenue,
        meta: {
            layout: 'TheInternal',
            header: 'common.reference_venues',
            breadCrumbs: [
                {
                    title: 'common.reference_venues',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/lists/venue/create',
        name: 'ListVenueCreate',
        component: ListVenueCreate,
        meta: {
            layout: 'TheInternal',
            header: 'common.venues_create',
            breadCrumbs: [
                {
                    title: 'common.venues_create',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/lists/venue/edit/:id',
        name: 'ListVenueEdit',
        component: ListVenueEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.venues_edit',
            breadCrumbs: [
                {
                    title: 'common.venues_edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/registry/edit/:id/:contour?/:type?',
        name: 'RegistryEdit',
        component: TheRegistryEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: 'Dashboard',
                    current: false
                },
                {
                    title: 'common.documentCard',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager', 'exhibitor'],
        },
    },
    {
        path: '/admin/message-exhibitor/create',
        name: 'AdminMessageExhibitorCreate',
        component: AdminMessageExhibitorCreate,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/message-exhibitor/:id/edit',
        name: 'AdminMessageExhibitorEdit',
        component: AdminMessageExhibitorEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/exhibitor/message-exhibitor/:id/edit',
        name: 'ExhibitorMessageExhibitorEdit',
        component: ExhibitorMessageExhibitorEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['exhibitor'],
        },
    },
    {
        path: '/admin/document/create',
        name: 'AdminDocumentCreate',
        component: AdminDocumentCreate,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/document/:id/edit',
        name: 'AdminDocumentEdit',
        component: AdminDocumentEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/exhibitor/document/create/:contour?',
        name: 'ExhibitorDocumentCreate',
        component: ExhibitorDocumentCreate,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['exhibitor'],
        },
    },
    {
        path: '/exhibitor/document/:id/edit',
        name: 'ExhibitorDocumentEdit',
        component: ExhibitorDocumentEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.coordination',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['exhibitor'],
        },
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: TheUsers,
        meta: {
            layout: 'TheInternal',
            header: 'common.users',
            breadCrumbs: [
                {
                    title: 'common.users',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        },
    },
    {
        path: '/admin/users/edit/:id',
        name: 'UserEdit',
        component: TheUserEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.users',
            breadCrumbs: [
                {
                    title: 'common.users',
                    linkName: 'Users',
                    current: false
                },
                {
                    title: 'common.edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        },
    },
    {
        path: '/admin/directory/docs',
        name: 'DocsDirectory',
        component: TheDocsDirectory,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentType',
            breadCrumbs: [
                {
                    title: 'common.documentType',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/docs/edit/:id',
        name: 'DocsDirectoryEdit',
        component: TheDocsDirectoryEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentType',
            breadCrumbs: [
                {
                    title: 'common.documentType',
                    linkName: 'DocsDirectory',
                    current: false
                },
                {
                    title: 'common.edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/exhibitor-types',
        name: 'ExhibitorTypes',
        component: TheExhibitorTypesDirectory,
        meta: {
            layout: 'TheInternal',
            header: 'common.exhibitorType',
            breadCrumbs: [
                {
                    title: 'common.exhibitorType',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/exhibitor-types/edit/:id',
        name: 'ExhibitorTypesEdit',
        component: TheExhibitorTypesDirectoryEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.exhibitorType',
            breadCrumbs: [
                {
                    title: 'common.exhibitorType',
                    linkName: 'ExhibitorTypes',
                    current: false
                },
                {
                    title: 'common.edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/federal-districts',
        name: 'FederalDistricts',
        component: TheFederalDistrictsDirectory,
        meta: {
            layout: 'TheInternal',
            header: 'common.federalDistrict',
            breadCrumbs: [
                {
                    title: 'common.federalDistrict',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/federal-districts/edit/:id',
        name: 'FederalDistrictsEdit',
        component: TheFederalDistrictsDirectoryEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.federalDistrict',
            breadCrumbs: [
                {
                    title: 'common.federalDistrict',
                    linkName: 'FederalDistricts',
                    current: false
                },
                {
                    title: 'common.edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/contents',
        name: 'Contents',
        component: Contents,
        meta: {
            layout: 'TheInternal',
            header: 'Контент',
            breadCrumbs: [
                {
                    title: 'Контент',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/news/create',
        name: 'ContentNewsCreate',
        component: ContentNewsCreate,
        meta: {
            layout: 'TheInternal',
            header: 'Создание новости',
            breadCrumbs: [
                {
                    title: 'Создание новости',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/news/:id/edit',
        name: 'ContentNewsEdit',
        component: ContentNewsEdit,
        meta: {
            layout: 'TheInternal',
            header: 'Редактирование новости',
            breadCrumbs: [
                {
                    title: 'Редактирование новости',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/profile/create',
        name: 'ContentProfileCreate',
        component: ContentProfileCreate,
        meta: {
            layout: 'TheInternal',
            header: 'Добавление профиля',
            breadCrumbs: [
                {
                    title: 'Добавление профиля',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/profile/:id/edit',
        name: 'ContentProfileEdit',
        component: ContentProfileEdit,
        meta: {
            layout: 'TheInternal',
            header: 'Редактирование профиля',
            breadCrumbs: [
                {
                    title: 'Редактирование профиля',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/product/create',
        name: 'ContentProductCreate',
        component: ContentProductCreate,
        meta: {
            layout: 'TheInternal',
            header: 'Создание ярмарки',
            breadCrumbs: [
                {
                    title: 'Создание ярмарки',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/product/:id/edit',
        name: 'ContentProductEdit',
        component: ContentProductEdit,
        meta: {
            layout: 'TheInternal',
            header: 'Ярмарка',
            breadCrumbs: [
                {
                    title: 'Ярмарка',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/audio-guild/create',
        name: 'ContentAudioGuildCreate',
        component: ContentAudioGuildCreate,
        meta: {
            layout: 'TheInternal',
            header: 'Создание аудиогиды',
            breadCrumbs: [
                {
                    title: 'Создание аудиогиды',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/audio-guild/:id/edit',
        name: 'ContentAudioGuildEdit',
        component: ContentAudioGuildEdit,
        meta: {
            layout: 'TheInternal',
            header: 'Редактирование аудиогид',
            breadCrumbs: [
                {
                    title: 'Редактирование аудиогид',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/event/create',
        name: 'ContentEventCreate',
        component: ContentEventCreate,
        meta: {
            layout: 'TheInternal',
            header: 'Создание мероприятие (контент)',
            breadCrumbs: [
                {
                    title: 'Создание мероприятие (контент)',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/contents/event/:id/edit',
        name: 'ContentEventEdit',
        component: ContentEventEdit,
        meta: {
            layout: 'TheInternal',
            header: 'Мероприятие',
            breadCrumbs: [
                {
                    title: 'Мероприятие',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/news-categories',
        name: 'NewsCategory',
        component: NewsCategory,
        meta: {
            layout: 'TheInternal',
            header: 'Категории новостей',
            breadCrumbs: [
                {
                    title: 'Категории новостей',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/news-categories/:id/show',
        name: 'NewsCategoryShow',
        component: NewsCategoryShow,
        meta: {
            layout: 'TheInternal',
            header: 'Категории новостей',
            breadCrumbs: [
                {
                    title: 'Категории новостей',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/age-limits',
        name: 'AgeLimit',
        component: AgeLimit,
        meta: {
            layout: 'TheInternal',
            header: 'Возрастные ограничения',
            breadCrumbs: [
                {
                    title: 'Возрастные ограничения',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/age-limits/:id/show',
        name: 'AgeLimitShow',
        component: AgeLimitShow,
        meta: {
            layout: 'TheInternal',
            header: 'Возрастное ограничение',
            breadCrumbs: [
                {
                    title: 'Возрастное ограничение',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-types',
        name: 'EventType',
        component: EventType,
        meta: {
            layout: 'TheInternal',
            header: 'Типы мероприятий',
            breadCrumbs: [
                {
                    title: 'Типы мероприятий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-types/:id/show',
        name: 'EventTypeShow',
        component: EventTypeShow,
        meta: {
            layout: 'TheInternal',
            header: 'Тип мероприятия',
            breadCrumbs: [
                {
                    title: 'Тип мероприятия',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/participant-category',
        name: 'ParticipantCategory',
        component: ParticipantCategory,
        meta: {
            layout: 'TheInternal',
            header: 'Категории участников',
            breadCrumbs: [
                {
                    title: 'Типы мероприятий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/participant-category/:id/show',
        name: 'ParticipantCategoryShow',
        component: ParticipantCategoryShow,
        meta: {
            layout: 'TheInternal',
            header: 'Категория участника',
            breadCrumbs: [
                {
                    title: 'Тип мероприятий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/pavilions',
        name: 'Pavilion',
        component: Pavilion,
        meta: {
            layout: 'TheInternal',
            header: 'Павильоны',
            breadCrumbs: [
                {
                    title: 'Павильоны',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/pavilions/:id/show',
        name: 'PavilionShow',
        component: PavilionShow,
        meta: {
            layout: 'TheInternal',
            header: 'Павильон',
            breadCrumbs: [
                {
                    title: 'Павильон',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/venues',
        name: 'Venue',
        component: Venue,
        meta: {
            layout: 'TheInternal',
            header: 'Площадки',
            breadCrumbs: [
                {
                    title: 'Площадки',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/venues/:id/show',
        name: 'VenueShow',
        component: VenueShow,
        meta: {
            layout: 'TheInternal',
            header: 'Площадка',
            breadCrumbs: [
                {
                    title: 'Площадка',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-organizations',
        name: 'EventOrganization',
        component: EventOrganization,
        meta: {
            layout: 'TheInternal',
            header: 'Организаторы мероприятий',
            breadCrumbs: [
                {
                    title: 'Организаторы мероприятий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-organizations/:id/show',
        name: 'EventOrganizationShow',
        component: EventOrganizationShow,
        meta: {
            layout: 'TheInternal',
            header: 'Организатор мероприятия',
            breadCrumbs: [
                {
                    title: 'Организатор мероприятия',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-categories',
        name: 'EventCategory',
        component: EventCategory,
        meta: {
            layout: 'TheInternal',
            header: 'Категории мероприятий',
            breadCrumbs: [
                {
                    title: 'Категории мероприятий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-categories/:id/show',
        name: 'EventCategoryShow',
        component: EventCategoryShow,
        meta: {
            layout: 'TheInternal',
            header: 'Категория мероприятия',
            breadCrumbs: [
                {
                    title: 'Категория мероприятия',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-officials',
        name: 'EventOfficial',
        component: EventOfficial,
        meta: {
            layout: 'TheInternal',
            header: 'Докладчики, спикеры, модераторы',
            breadCrumbs: [
                {
                    title: 'Докладчики, спикеры, модераторы',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-officials/:id/show',
        name: 'EventOfficialShow',
        component: EventOfficialShow,
        meta: {
            layout: 'TheInternal',
            header: 'Докладчики, спикеры, модераторы',
            breadCrumbs: [
                {
                    title: 'Докладчики, спикеры, модераторы',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-artists',
        name: 'EventArtist',
        component: EventArtist,
        meta: {
            layout: 'TheInternal',
            header: 'Артисты',
            breadCrumbs: [
                {
                    title: 'Артисты',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-artists/:id/show',
        name: 'EventArtistShow',
        component: EventArtistShow,
        meta: {
            layout: 'TheInternal',
            header: 'Артисты',
            breadCrumbs: [
                {
                    title: 'Артисты',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-thematic',
        name: 'EventThematic',
        component: EventThematic,
        meta: {
            layout: 'TheInternal',
            header: 'Тематика деловой недели',
            breadCrumbs: [
                {
                    title: 'Тематика деловой недели',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-thematic/:id/show',
        name: 'EventThematicShow',
        component: EventThematicShow,
        meta: {
            layout: 'TheInternal',
            header: 'Тематика деловой недели',
            breadCrumbs: [
                {
                    title: 'Тематика деловой недели',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-duration',
        name: 'EventDuration',
        component: EventDuration,
        meta: {
            layout: 'TheInternal',
            header: 'Продолжительность для экскурсий',
            breadCrumbs: [
                {
                    title: 'Продолжительность для экскурсий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-duration/:id/show',
        name: 'EventDurationShow',
        component: EventDurationShow,
        meta: {
            layout: 'TheInternal',
            header: 'Продолжительность для экскурсий',
            breadCrumbs: [
                {
                    title: 'Продолжительность для экскурсий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-language',
        name: 'EventLanguage',
        component: EventLanguage,
        meta: {
            layout: 'TheInternal',
            header: 'Языки для экскурсий',
            breadCrumbs: [
                {
                    title: 'Языки для экскурсий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-language/:id/show',
        name: 'EventLanguageShow',
        component: EventLanguageShow,
        meta: {
            layout: 'TheInternal',
            header: 'Языки для экскурсий',
            breadCrumbs: [
                {
                    title: 'Языки для экскурсий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/route-points',
        name: 'RoutePoint',
        component: RoutePoint,
        meta: {
            layout: 'TheInternal',
            header: 'Точки маршрута',
            breadCrumbs: [
                {
                    title: 'Точки маршрута',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/route-points/:id/show',
        name: 'RoutePointShow',
        component: RoutePointShow,
        meta: {
            layout: 'TheInternal',
            header: 'Точки маршрута',
            breadCrumbs: [
                {
                    title: 'Точки маршрута',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/partners',
        name: 'Partner',
        component: Partner,
        meta: {
            layout: 'TheInternal',
            header: 'Партнеры',
            breadCrumbs: [
                {
                    title: 'Партнеры',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/partners/:id/show',
        name: 'PartnerShow',
        component: PartnerShow,
        meta: {
            layout: 'TheInternal',
            header: 'Партнеры',
            breadCrumbs: [
                {
                    title: 'Партнеры',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/participants',
        name: 'Participant',
        component: Participant,
        meta: {
            layout: 'TheInternal',
            header: 'Участники',
            breadCrumbs: [
                {
                    title: 'Участники',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/participants/:id/show',
        name: 'ParticipantShow',
        component: ParticipantShow,
        meta: {
            layout: 'TheInternal',
            header: 'Участники',
            breadCrumbs: [
                {
                    title: 'Участники',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager', 'content_exhibitor'],
        },
    },
    {
        path: '/lists/contents/event-groups',
        name: 'EventGroup',
        component: EventGroup,
        meta: {
            layout: 'TheInternal',
            header: 'Группы мероприятий',
            breadCrumbs: [
                {
                    title: 'Группы мероприятий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager'],
        },
    },
    {
        path: '/lists/contents/event-groups/:id/show',
        name: 'EventGroupShow',
        component: EventGroupShow,
        meta: {
            layout: 'TheInternal',
            header: 'Группы мероприятий',
            breadCrumbs: [
                {
                    title: 'Группы мероприятий',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'content_manager', 'manager'],
        },
    },
    {
        path: '/admin/log',
        name: 'UserLog',
        component: TheLogs,
        meta: {
            layout: 'TheInternal',
            header: 'common.logs',
            breadCrumbs: [
                {
                    title: 'common.logs',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        }
    },
    {
        path: '/setting',
        name: 'Setting',
        component: Setting,
        meta: {
            layout: 'TheInternal',
            header: 'Настройки',
            breadCrumbs: [
                {
                    title: 'Настройки',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    const publicPages = ['Login', 'ForgotPassword'];
    const authRequired = !publicPages.includes(to.name);
    const authStore = useAuthStore();
    const token = localStorage.getItem("token");

    document.title = (i18n.global.t(to.meta.header) || 'Личный кабинет') + ' | Россия';

    await authStore.getProfile().then((r) => {
        if (r.data.success === true && r.data.data.active === false) {
            authStore.clearAuthUser();
        }
    }).catch((e) => {
        authStore.clearAuthUser();
    })

    await authStore.getProfile;

    if (import.meta.env.VITE_AUTH_DISABLED) {
        if (localStorage.getItem('authUser') !== null) {
            localStorage.clear()
            window.location.reload()
        }
    }

    if (authRequired && !authStore.authUser) {
        localStorage.setItem('auth_back', window.location.pathname);

        next({name: 'Login'})
    } else if (!authRequired && authStore.authUser) {
        next({name: 'Dashboard'})
    } else {
        if (to.meta.roles !== '*' && !checkRole(to.meta.roles)) {
            if (checkRole(['content_exhibitor', 'content_manager'])) {
                next({name: 'Contents'})
            }
            else next({name: 'Dashboard'})
        }

        next()
    }
})

export default router;
