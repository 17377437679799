<template>
  <div v-if="isLoadingData">
    <spinner />
  </div>
  <div v-if="isLoadingUpdate">
    <spinner />
    <div style="text-align: center; color: #afafaf; margin-top: -30px;">Обновление новости ...</div>
  </div>

  <div
      v-if="saveMessageResponse"
      style="z-index: 100; top: 80px"
      id="toast-top-right"
      :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
      class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
      role="alert">
    <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
  </div>

  <modal
      v-if="isShowModalSliderDelete"
      @closeDialog="isShowModalSliderDelete=false"
  >
    <template #title>Удаление слайда</template>
    <template #body>
      <form action="#">
        <div class="grid gap-4 mb-5 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <label class="block mb-2 font-medium text-basic-gray">Вы точно хотите удалить данный слайд?</label>
          </div>
        </div>
        <div
            class="flex items-center space-x-4">
          <button @click.prevent="onHandleSliderDelete" type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Да
          </button>
          <button @click="onHandleModalSliderClose" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            {{ $t('common.cancel') }}
          </button>
        </div>
      </form>
    </template>
  </modal>

  <modal
      v-if="isShowModalQuoteDelete"
      @closeDialog="isShowModalQuoteDelete=false"
  >
    <template #title>Удаление цитаты</template>
    <template #body>
      <form action="#">
        <div class="grid gap-4 mb-5 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <label class="block mb-2 font-medium text-basic-gray">Вы точно хотите удалить данную цитату?</label>
          </div>
        </div>
        <div
            class="flex items-center space-x-4">
          <button @click.prevent="onHandleQuoteDelete" type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Да
          </button>
          <button @click="onHandleModalQuoteClose" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            {{ $t('common.cancel') }}
          </button>
        </div>
      </form>
    </template>
  </modal>

  <div v-if="!isLoadingData && !isLoadingUpdate">
    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div class="grid gap-4 mb-10 sm:grid-cols-3">
                        <div class="sm:col-span-2">
                          <div>
                            <div>
                              <span class="text-3xl font-medium mr-4">{{ nameRu }}</span>
                            </div>
                          </div>

                          <div class="mt-2" v-if="newsId">
                            <p style="color: #9b9b9b;">Участник: <span style="color: #000;">{{participant?.nameRu}}</span></p>
                            <p v-if="checkRole(['admin', 'manager', 'content_manager']) && ((contentParticipant?.id !== participant?.id) && (contentParticipant?.id || participant?.id))" style="color: #9b9b9b;">Участник для контента: <span style="color: #000;">{{contentParticipant?.nameRu}}</span></p>
                            <p style="color: #9b9b9b;">Пользователь: <span style="color: #000;">{{userCreated?.name}}</span></p>
                          </div>
                        </div>

                        <div class="sm:col-span-1">
                          <div class="text-right text-sm">
                            <p class="mb-2"><span class="text-gray-500">{{ $t('document.created') }}:</span>
                              {{ moment(createdAt).format('DD.MM.YYYY') }}</p>
                            <p class="mb-2"><span class="text-gray-500">{{ $t('document.updated') }}:</span>
                              {{ moment(updatedAt).format('DD.MM.YYYY') }}</p>
                            <p class="mb-2" v-if="publishedDatetime"><span class="text-gray-500">{{ $t('document.published') }}:</span>
                              {{ integrationPublicationDate ? moment(integrationPublicationDate).format('DD.MM.YYYY') : '-' }}</p>
                            <p class="mb-2" v-if="checkRole('admin')">
                              <router-link
                                  :to="{name: 'UserLog', query: {object: 'App\\Models\\Api\\V1\\ContentNews', id: newsId}}"
                                  target="_blank"
                                  class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">
                                {{ $t('common.toLogs') }}
                              </router-link>
                            </p>
                          </div>
                        </div>
                        <button
                            @click="onHandleGoBackPage"
                            style="padding: 10px; background: aliceblue; border-radius: 10px; display: flex; align-items: center; justify-content: flex-start; width: 176px;"
                            type="button"
                            class="hover:opacity-60">
                          <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6668 17.5L4.16683 10L11.6668 2.5" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          вернуться назад
                        </button>
                      </div>
                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <div class="grid gap-4 mb-4 sm:grid-cols-2 mt-5" v-if="status">
                            <div class="sm:col-span-2">
                              <div
                                  class="inline-block px-4 py-1 rounded-xl mr-4 whitespace-nowrap opacity-100 cursor-default"
                                  :class="['bg-status-' + (status)]">
                                <span>{{ statusAction?.name }}</span>
                              </div>
                            </div>
                          </div>
                          <br />
                          <hr/>
                        </div>
                      </div>

                      <div class="tabs">
                        <div
                            :class="{'active': tabKey === 'main'}"
                            @click="tabKey='main'"
                            class="tab">
                          <div class="tab-label">
                            Основное
                          </div>
                        </div>
                        <div
                            :class="{'active': tabKey === 'sliders'}"
                            @click="tabKey='sliders'"
                            class="tab">
                          <div class="tab-label">
                            Слайдер
                          </div>
                        </div>
                        <div
                            :class="{'active': tabKey === 'quotes'}"
                            @click="tabKey='quotes'"
                            class="tab">
                          <div class="tab-label">
                            Цитаты
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'sliders'">
                        <div
                            style="padding: 5px 15px; color: red; text-align: center;"
                            v-if="messageSliderEmpty" class="alert-message-error">
                          {{messageSliderEmpty}}
                        </div>
                        <div
                            v-for="(slider, index) in sliders"
                            :key="index"
                            class="achievement-content-item">
                          <div class="achievement-content-item-image mb-7">
                            <div v-if="typeof slider.image !== 'string' && slider.image?.length">
                              <div class="image-content"
                                   style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                                <h2 class="mb-2" style="text-align: center">Изображение *</h2>
                                <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                  <span class="mr-2">{{slider.image[0].name}}</span>
                                  <button @click="onHandleDeleteImage" type="button" class="hover:opacity-60">
                                    <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                                v-else-if="typeof slider.image !== 'string'"
                                class="grid gap-4 mb-4">
                              <div class="sm:col-span-2">
                                <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                <drop-file
                                    :disabled="!isNewsEdit"
                                    v-model="slider.image"
                                    :type="imageTypes"
                                    :single="true"
                                />
                                <p v-if="slider.imageError" class="mt-2 text-sm text-red-600"> {{ slider.imageError }} </p>
                              </div>
                            </div>
                            <div
                                v-else
                                class="image-content" style="text-align: center; width: 250px; margin: auto;">
                              <img
                                  style="width: 250px; height: auto; object-fit: cover"
                                  :src="slider.image"
                                  alt="">
                              <button
                                  :disabled="!isNewsEdit"
                                  @click="slider.image=null"
                                  class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                          <hr/>
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="slider.authorRu"
                                  label="Наименование автора изображения (RU)"/>
                              <p v-if="slider.authorRuError" class="mt-2 text-sm text-red-600"> {{ slider.authorRuError }} </p>
                            </div>
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="slider.authorEn"
                                  label="Наименование автора изображения (EN)"/>
                              <p v-if="slider.authorEnError" class="mt-2 text-sm text-red-600"> {{ slider.authorEnError }} </p>
                            </div>
                          </div>
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  v-model="slider.sort"
                                  label="Сортировка"/>
                              <p v-if="slider.sortError" class="mt-2 text-sm text-red-600"> {{ slider.sortError }} </p>
                            </div>
                          </div>
                          <hr />
                          <button
                              :disabled="!isNewsEdit"
                              type="button"
                              @click="onHandleDeleteActionModelSlider(index, slider?.id)"
                              class="px-7 mr-5 mt-5 py-3 mb-5 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                            Удалить
                          </button>
                        </div>

                        <div
                            v-if="isNewsEdit"
                            @click="onHandleClickSliderCreate"
                            class="session-add">
                          <div class="session-btn">
                            Добавить новый слайд
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'quotes'">
                        <div
                            style="padding: 5px 15px; color: red; text-align: center;"
                            v-if="messageQuoteEmpty" class="alert-message-error">
                          {{ messageQuoteEmpty }}
                        </div>
                        <div
                            v-for="(quote, index) in quotes"
                            :key="index"
                            class="achievement-content-item">
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  :disabled="true"
                                  v-model="quote.id"
                                  label="ID"/>
                            </div>
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="quote.nameRu"
                                  label="Цитата (RU) *"/>
                              <p v-if="quote.nameRuError" class="mt-2 text-sm text-red-600"> {{
                                  quote.nameRuError
                                }} </p>
                            </div>
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="quote.nameEn"
                                  label="Цитата (EN)"/>
                              <p v-if="quote.nameEnError" class="mt-2 text-sm text-red-600"> {{
                                  quote.nameEnError
                                }} </p>
                            </div>
                          </div>
                          <hr/>
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="quote.authorRu"
                                  label="Автор цитаты (RU)"/>
                              <p v-if="quote.authorRuError" class="mt-2 text-sm text-red-600"> {{
                                  quote.authorRuError
                                }} </p>
                            </div>
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="quote.authorEn"
                                  label="Автор цитаты (EN)"/>
                              <p v-if="quote.authorEnError" class="mt-2 text-sm text-red-600"> {{
                                  quote.authorEnError
                                }} </p>
                            </div>
                          </div>
                          <hr/>
                          <button
                              type="button"
                              :disabled="!isNewsEdit"
                              @click="onHandleDeleteActionModelQuote(index, quote?.id)"
                              class="px-7 mr-5 mt-5 py-3 mb-5 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                            Удалить
                          </button>
                        </div>

                        <div
                            v-if="quotes.length < 1"
                            @click="onHandleClickQuoteCreate"
                            class="session-add">
                          <div class="session-btn">
                            Добавить новую цитату
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'main'">
                        <div>
                          <input-text
                              :disabled="!isNewsEdit"
                              v-model="nameRu"
                              :max-length="MAX_LENGTH"
                              :is-show-max-and-min-error="true"
                              label="Наименование (RU) *"/>
                          <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
                        </div>
                        <div class="mt-5">
                          <input-text
                              :disabled="!isNewsEdit"
                              v-model="nameEn"
                              :max-length="MAX_LENGTH"
                              :is-show-max-and-min-error="true"
                              label="Наименование (EN)"/>
                          <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
                        </div>

                        <hr class="mb-4 mb-2">

                      <div
                          v-if="checkRole(['admin', 'manager', 'content_manager']) && (newContentStatus === 6 || newContentStatus === 14)"
                          class="mb-5">
                        <label class="block mb-2 font-medium text-basic-gray">{{participant?.id ? 'Выберите участника для карточки' : 'Прикрепите участника для карточки'}}</label>
                        <Multiselect
                            :disabled="!isNewsEdit"
                            v-model="selectedParticipant"
                            :options="participants"
                            :valueProp="'id'"
                            track-by="nameRu"
                            label="nameRu"
                            :close-on-select="true"
                            :searchable="true"/>
                        <p v-if="selectedParticipantError" class="mt-2 text-sm text-red-600">
                          {{
                            selectedParticipantError
                          }}
                        </p>
                      </div>

                      <hr class="mb-4 mb-2">

                      <div v-if="checkRole(['admin', 'manager', 'content_manager'])">
                        <div class="mt-5">
                          <input-text
                              :disabled="true"
                              v-model="eventExternalId"
                              label="Внешний идентификатор для связывание с russia"/>
                        </div>

                          <hr class="mb-4 mb-5 mt-5">
                        </div>

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionRu"
                                label="Краткое описание (RU) *"
                            />
                            <p v-if="shortDescriptionRuError" class="mt-2 text-sm text-red-600"> {{ shortDescriptionRuError }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionEn"
                                label="Краткое описание (EN)"
                            />
                            <p v-if="shortDescriptionEnError" class="mt-2 text-sm text-red-600"> {{ shortDescriptionEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionRu"
                                label="Подробное описание (RU) *"
                            />
                            <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600"> {{ descriptionRuError }} </p>
                            <ul
                                style="font-size: 14px; color: #d06258; font-style: italic;"
                                v-if="quoteRuErrors.length">
                              <li v-for="quoteRuError in quoteRuErrors">{{quoteRuError}}</li>
                            </ul>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionEn"
                                label="Подробное описание (EN)"
                            />
                            <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600"> {{ descriptionEnError }} </p>
                            <ul
                                style="font-size: 14px; color: #d06258; font-style: italic;"
                                v-if="quoteEnErrors.length">
                              <li v-for="quoteEnError in quoteEnErrors">{{quoteEnError}}</li>
                            </ul>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="sourceRu"
                                :max-length="MAX_LENGTH"
                                :is-show-max-and-min-error="true"
                                label="Источник (RU)"/>
                            <p v-if="sourceRuError" class="mt-2 text-sm text-red-600"> {{ sourceRuError }} </p>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="sourceEn"
                                :max-length="MAX_LENGTH"
                                :is-show-max-and-min-error="true"
                                label="Источник (EN)"/>
                            <p v-if="sourceEnError" class="mt-2 text-sm text-red-600"> {{ sourceEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите контур</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedContours"
                              :options="contours"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="name"
                              label="name"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedContourError" class="mt-2 text-sm text-red-600">
                            {{
                              selectedContourError
                            }}
                          </p>
                        </div>

                        <div
                            v-if="!checkRole(['content_exhibitor'])"
                            class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите категорию новости *</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedCategories"
                              :options="categories"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="name"
                              label="name"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedCategoryError" class="mt-2 text-sm text-red-600">
                            {{
                              selectedCategoryError
                            }}
                          </p>
                        </div>

                        <div>
                          <span class="title text-gray-400">Дата публикации *</span>
                          <VueDatePicker
                              :disabled="!isNewsEdit"
                              class="mt-2"
                              v-model="publishedDatetime"
                              locale="ru"
                              model-type="yyyy-MM-dd HH:mm"
                              :format="'dd.MM.yyyy HH:mm'"
                              auto-apply
                              :enable-time-picker="true"
                          />
                          <p v-if="publishedDatetimeError" class="mt-2 text-sm text-red-600">
                            {{
                              publishedDatetimeError
                            }}
                          </p>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="typeof previewImage !== 'string' || !previewImage?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить изображение для анонса *</h2>
                            <drop-file
                                v-model="previewImage"
                                :type="previewImageTypes"
                                :single="true"
                            />
                            <p v-if="previewImageError" class="mt-2 text-sm text-red-600"> {{ previewImageError }} </p>
                          </div>
                          <div v-else>
                            <div class="image-content" style="text-align: center; width: 250px; margin: auto;">
                              <img
                                  style="width: 250px; height: auto; object-fit: cover"
                                  :src="previewImage"
                                  alt="">
                              <button
                                  :disabled="!isNewsEdit"
                                  @click="onHandleDeletePreviewImage"
                                  class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="typeof image !== 'string' || !image?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                            <drop-file
                                v-model="image"
                                :type="imageTypes"
                                :single="true"
                            />
                            <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>
                          </div>
                          <div v-else>
                            <div class="image-content"
                                 style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                              <img
                                  style="width: 250px; height: auto; object-fit: cover"
                                  :src="image"
                                  alt="">
                              <button
                                  :disabled="!isNewsEdit"
                                  @click="onHandleDeleteImage"
                                  class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="authorImageTitleNameRu"
                                :max-length="MAX_LENGTH"
                                :is-show-max-and-min-error="true"
                                label="Наименование автора изображения (RU) *"/>
                            <p v-if="authorImageTitleNameRuError" class="mt-2 text-sm text-red-600">
                              {{ authorImageTitleNameRuError }}
                            </p>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="authorImageTitleNameEn"
                                :max-length="MAX_LENGTH"
                                :is-show-max-and-min-error="true"
                                label="Наименование автора изображения (EN)"/>
                            <p v-if="authorImageTitleNameEnError" class="mt-2 text-sm text-red-600">
                              {{ authorImageTitleNameEnError }}
                            </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="typeof video !== 'string' || !video?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить видео файл</h2>
                            <drop-file
                                :isDisabled="!isNewsEdit"
                                v-model="video"
                                :type="videoTypes"
                                :single="true"
                            />
                            <p v-if="videoError" class="mt-2 text-sm text-red-600"> {{ videoError }} </p>
                          </div>
                          <div v-else class="mb-5">
                            <video
                                style="width: 100%"
                                controls width="250">
                              <source :src="video" type="video/webm" />

                              <source :src="video" type="video/mp4" />
                            </video>
                            <button
                                :disabled="!isNewsEdit"
                                @click="onHandleDeleteVideo"
                                class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                              Удалить
                            </button>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
<!--                          <div-->
<!--                              class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">-->
<!--                            <input-->
<!--                                id="bordered-checkbox-slider-mp"-->
<!--                                type="checkbox"-->
<!--                                v-model="isSliderMP"-->
<!--                                name="bordered-checkbox"-->
<!--                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">-->
<!--                            <div class="ml-2 text-sm">-->
<!--                              <label for="bordered-checkbox-slider-mp" class="font-medium text-basic-gray dark:text-gray-300">-->
<!--                                Слайдер МП-->
<!--                              </label>-->
<!--                              <p-->
<!--                                  id="helper-checkbox-text-slider-mp"-->
<!--                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">-->
<!--                                {{-->
<!--                                  isSliderMP ? 'Слайдер МП (Да)' : 'Слайдер МП (Нет)'-->
<!--                                }}-->
<!--                              </p>-->
<!--                            </div>-->
<!--                          </div>-->
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="!isNewsEdit"
                                id="bordered-checkbox-2"
                                type="checkbox"
                                v-model="isTopNews"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-2" class="font-medium text-basic-gray dark:text-gray-300">
                                Главная и новости / Слайдер
                              </label>
                              <p
                                  id="helper-checkbox-text-2"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isTopNews ? 'Да' : 'Нет'
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="!isNewsEdit"
                                id="bordered-checkbox-3"
                                type="checkbox"
                                v-model="isMainAbout"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-3" class="font-medium text-basic-gray dark:text-gray-300">
                                Страница "О выставке"
                              </label>
                              <p
                                  id="helper-checkbox-text-3"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isMainAbout ? 'Да' : 'Нет'
                                }}
                              </p>
                            </div>
                          </div>

                          <hr class="mb-4 mb-9 mt-10">
                        </div>

                        <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-is_publish_news_section"
                                type="checkbox"
                                v-model="isPublishNewsSection"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-is_publish_news_section" class="font-medium text-basic-gray dark:text-gray-300">
                                Публиковать в разделе "Новости"
                              </label>
                              <p
                                  id="helper-checkbox-text-is_publish_news_section"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isPublishNewsSection ? 'Публиковать (Да)' : 'Не публиковать (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-is_publish_detailed_member"
                                type="checkbox"
                                v-model="isPublishDetailedMember"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-is_publish_detailed_member" class="font-medium text-basic-gray dark:text-gray-300">
                                Публиковать на детальной участника
                              </label>
                              <p
                                  id="helper-checkbox-text-is_publish_detailed_member"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isPublishDetailedMember ? 'Публиковать (Да)' : 'Не публиковать (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <hr class="mb-4 mb-2">

                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="!isNewsEdit"
                                id="bordered-checkbox-4"
                                type="checkbox"
                                v-model="isActiveRu"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-4" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (RU)
                              </label>
                              <p
                                  id="helper-checkbox-text-4"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveRu ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                :disabled="!isNewsEdit"
                                id="bordered-checkbox-5"
                                type="checkbox"
                                v-model="isActiveEn"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-5" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (EN)
                              </label>
                              <p
                                  id="helper-checkbox-text-5"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>

                          <hr class="mb-4 mb-2">
                        </div>
                      </div>

                    </form>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSliderEmpty || isErrorValSlider"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ messageSliderEmpty || 'Ошибки в слайдере' }}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="isErrorValQuote"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{ 'Ошибки в цитатах' }}
                    </div>
                  </div>

                  <div
                      v-if="isErrorVal"
                      style="padding: 5px 15px; margin-top: 40px; color: red;"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      <ul style="list-style: initial; padding: 15px;">
                        <li v-if="selectedParticipantError"><span style="font-weight: bold">Участник:</span> {{selectedParticipantError}}</li>
                        <li v-if="nameRuError"><span style="font-weight: bold">Наименование (RU):</span> {{nameRuError}}</li>
                        <li v-if="nameEnError"><span style="font-weight: bold">Наименование (EN):</span> {{nameEnError}}</li>
                        <li v-if="sourceRuError"><span style="font-weight: bold">Источник (RU):</span> {{sourceRuError}}</li>
                        <li v-if="sourceEnError"><span style="font-weight: bold">Источник (EN):</span> {{sourceEnError}}</li>
                        <li v-if="shortDescriptionRuError"><span style="font-weight: bold">Краткое описание (RU):</span> {{shortDescriptionRuError}}</li>
                        <li v-if="shortDescriptionEnError"><span style="font-weight: bold">Краткое описание (EN):</span> {{shortDescriptionEnError}}</li>
                        <li v-if="descriptionRuError"><span style="font-weight: bold">Подробное описание (RU):</span> {{descriptionRuError}}</li>
                        <li v-if="descriptionEnError"><span style="font-weight: bold">Подробное описание (EN):</span> {{descriptionEnError}}</li>
                        <li v-if="quoteRuErrors?.length"><span style="font-weight: bold">Подробное описание (RU):</span>
                          Вы не добавили цитат{{quoteRuErrors?.length <= 1 ? 'у' : 'ы'}}
                        </li>
                        <li v-if="quoteEnErrors?.length"><span style="font-weight: bold">Подробное описание (EN):</span>
                          Вы не добавили цитат{{quoteEnErrors?.length <= 1 ? 'у' : 'ы'}}
                        </li>
                        <li v-if="selectedContourError"><span style="font-weight: bold">Контур:</span> {{selectedContourError}}</li>
                        <li v-if="selectedCategoryError"><span style="font-weight: bold">Категория новости:</span> {{selectedCategoryError}}</li>
                        <li v-if="publishedDatetimeError"><span style="font-weight: bold">Дата публикации:</span> {{publishedDatetimeError}}</li>
                        <li v-if="previewImageError"><span style="font-weight: bold">Изображение для анонса:</span> {{previewImageError}}</li>
                        <li v-if="imageError"><span style="font-weight: bold">Изображение:</span> {{imageError}}</li>
                        <li v-if="authorImageTitleNameRuError"><span style="font-weight: bold">Наименование автора изображения (RU):</span> {{authorImageTitleNameRuError}}</li>
                        <li v-if="authorImageTitleNameEnError"><span style="font-weight: bold">Наименование автора изображения (EN):</span> {{authorImageTitleNameEnError}}</li>
                        <li v-if="authorVideoTitleNameRuError"><span style="font-weight: bold">Наименование автора видео (RU):</span> {{authorVideoTitleNameRuError}}</li>
                        <li v-if="authorVideoTitleNameEnError"><span style="font-weight: bold">Наименование автора видео (EN):</span> {{authorVideoTitleNameEnError}}</li>
                      </ul>
                    </div>
                  </div>

                  <div class="p-7 flex justify-between">
                    <div>
                      <div class="flex flex-wrap">
                        <div
                            v-if="(checkRole(['exhibitor', 'content_exhibitor']) && user?.id === authorUserId) || checkRole(['admin', 'content_manager', 'manager'])"
                            v-for="process in statusProcess"
                            class="flex items-center mb-4">
                          <label @click.prevent="onHandleClickUpdate(process.id)"
                                 :class="['bg-status-' + (process.id)]"
                                 class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                            <span>{{ process.name }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="col-span-2 mb-4 xl:mb-2">
              <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
                <div class="flex justify-between items-center p-7 border-b border-gray-200">
                  <div class="text-3xl font-medium">
                    {{ $t('document.remarksTitle') }}
                  </div>
                </div>
                <div class="p-7 pt-4">
                  <div class="flex-1 justify-between flex flex-col h-[50vh]">
                    <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                      <div class="chat-message" v-for="(message, index) in messages">
                        <div class="flex items-end" :class="{'justify-end': !message.isAnswer}">
                          <div style="position: relative" class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                            <div>
                              <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none" :class="{'bg-gray-300 text-gray-600': message.isAnswer, 'bg-blue-600 text-white': !message.isAnswer}">
                                <div class="text-xs">
                                  {{ message.user.name }}
                                </div>
                                <div class="text-xs">
                                  {{ message.createdAt }}
                                </div>
                                <div class="text-sm">
                                  {{ message.name }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                      <div class="relative flex">
                          <textarea
                              :placeholder="$t('chat.write_message')"
                              v-model="messageData"
                              rows="5"
                              class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                          ></textarea>
                      </div>
                      <div v-if="checkRole(['admin', 'manager', 'content_manager'])" class="items-center mt-2">
                        <button type="button"
                                @click.prevent="onHandleClickSendMessage"
                                class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                          <template v-if="!loadDataMessage">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>
                          </template>
                          <template v-else>
                            <svg aria-hidden="true" class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </template>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  </div>
</template>

<script setup>
import Modal from "../../../flowbite/modal.vue";
import {checkRole} from "../../../../utilits/functions.js";
import Spinner from "../../../flowbite/spinner.vue";
import {ref} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect'
import {
  serviceContentNewsFindId,
  serviceContentNewsMessageCreate,
  serviceContentNewsQuoteDelete,
  serviceContentNewsSliderDelete,
  serviceContentNewsUpdate
} from "../../../../service/contents/news/serviceContentNews.js";
import {serviceNewsCategoryList} from "../../../../service/lists/serviceListNewsCategory.js";
import {useRoute, useRouter} from 'vue-router';
import moment from "moment";
import {serviceSelectedUserContours} from "../../../../service/user/user.js";
import {serviceParticipantList} from "../../../../service/lists/serviceListParticipant.js";
import {validateSlider} from "../../../../helpers/helperValidationSlider.js";
import {uuid4} from "../../../../helpers/helperGenerateUuid.js";

const route = useRoute();
const router = useRouter();

const isLoadingData = ref(true);
const saveMessageResponse = ref(null);
const messageData = ref(null);
const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;
const loadDataMessage = ref(false);
const isLoadingUpdate = ref(false);

const isNewsEdit = ref(false);
const newsId = ref(route.params?.id);
const status = ref(null);
const newContentStatus = ref(null);
const statusAction = ref(null);
const statusProcess = ref([]);
const createdAt = ref(null);
const updatedAt = ref(null);

const userCreated = ref(null);

const integrationPublicationDate = ref(null);

const tabKey = ref('main');

const selectedParticipant = ref(null);
const participants = ref([]);
const selectedParticipantError = ref(null);

const authorUserId = ref(null);
const nameRu = ref('');
const nameRuError = ref('');
const nameEn = ref('');
const nameEnError = ref('');
const eventExternalId = ref(null);
const shortDescriptionRu = ref('');
const shortDescriptionRuError = ref('');
const shortDescriptionEn = ref('');
const shortDescriptionEnError = ref('');
const descriptionRu = ref('');
const descriptionRuError = ref('');
const descriptionEn = ref('');
const descriptionEnError = ref('');
const sourceRu = ref('');
const sourceRuError = ref('');
const sourceEn = ref('');
const sourceEnError = ref('');
const categories = ref([]);
const selectedCategories = ref([]);
const selectedCategoryError = ref('');
const authorImageTitleNameRu = ref('');
const authorImageTitleNameRuError = ref('');
const authorImageTitleNameEn = ref('');
const authorImageTitleNameEnError = ref('');
const authorVideoTitleNameRu = ref('');
const authorVideoTitleNameRuError = ref('');
const authorVideoTitleNameEn = ref('');
const authorVideoTitleNameEnError = ref('');
const isCardPreview = ref(false);
const isMain = ref(false);
const isTopNews = ref(false);
const isMainAbout = ref(false);
const isPublishNewsSection = ref(false);
const isPublishDetailedMember = ref(true);
const isActiveRu = ref(true);
const isActiveEn = ref(true);
const publishedDatetime = ref(null);
const publishedDatetimeError = ref(null);
const selectedContours = ref([]);
const contours = ref([]);
const selectedContourError = ref('');

const isSliderMP = ref(false);

const messageQuoteEmpty = ref('');
const isErrorValQuote = ref(false);
const quotes = ref([]);
const isShowModalQuoteDelete = ref(false);
const helperQuoterId = ref(null);

const sliders = ref([]);
const isShowModalSliderDelete = ref(false);
const helperSliderId = ref(null);
const messageSliderEmpty = ref('');
const isErrorValSlider = ref(false);

const quoteRuErrors = ref([]);
const quoteEnErrors = ref([]);

const messages = ref([]);
const participant = ref(null);
const contentParticipant = ref(null);

const MAX_LENGTH = 255;

const isErrorVal = ref(false);
// requiredValue - нужно для того, чтобы выставить (*) где обязательность заполнение для администрации
const requiredValue = checkRole(['admin', 'manager', 'content_manager']) ? '*' : '';

const previewImage = ref(null);
const previewImageError = ref('');
const previewImageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 1,
};

const image = ref(null);
const imageError = ref('');
const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

const video = ref(null);
const videoError = ref('');
const videoTypes = {
  extensions: ["mp4"],
  max_filesize: 1000,
};

if (checkRole(['admin', 'manager', 'content_manager'])) {
  serviceParticipantList().then(response => {
    participants.value = response.data || [];
  });
}

const onHandleDeleteActionModelSlider = (index, id) => {
  if (!id) {
    sliders.value.splice(index, 1);
  } else {
    helperSliderId.value = id;
    isShowModalSliderDelete.value = true;
  }
}

const onHandleDeleteActionModelQuote = (index, id) => {
  if (!id) {
    quotes.value.splice(index, 1);
  } else {
    helperQuoterId.value = id;
    isShowModalQuoteDelete.value = true;
  }
}

const onHandleClickSliderCreate = () => {
  sliders.value.push({
    image: null,
    imageError: null,
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
    sort: 100,
    sortError: '',
  });
}

const onHandleClickQuoteCreate = () => {
  quotes.value.push({
    id: uuid4(),
    nameRu: '',
    nameRuError: '',
    nameEn: '',
    nameEnError: '',
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
    isNew: true,
  });
}

const onHandleModalSliderClose = () => {
  helperSliderId.value = null;
  isShowModalSliderDelete.value = false;
}

const onHandleModalQuoteClose = () => {
  helperQuoterId.value = null;
  isShowModalQuoteDelete.value = false;
}

const onHandleSliderDelete = () => {
  if (!newsId.value || !helperSliderId.value)
    return null;

  serviceContentNewsSliderDelete(newsId.value, helperSliderId.value).then(response => {
    if (response.code !== 200)
      return null;

    sliders.value = sliders.value.filter(item => item?.id !== helperSliderId.value);

    helperSliderId.value = null;
    isShowModalSliderDelete.value = false;
  });
}

const onHandleQuoteDelete = () => {
  if (!newsId.value || !helperQuoterId.value)
    return null;

  serviceContentNewsQuoteDelete(newsId.value, helperQuoterId.value).then(response => {
    if (response.code !== 200)
      return null;

    quotes.value = quotes.value.filter(item => item?.id !== helperQuoterId.value);

    helperQuoterId.value = null;
    isShowModalQuoteDelete.value = false;
  });
}

const onHandleGoBackPage = () => {
  router.go(-1);
}

serviceSelectedUserContours().then(response => {
  contours.value = response.data || [];
});

const validateExhibitor = () => {
  let isError = false;

  const valueName = nameRu.value?.trim();
  nameRuError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameRuError.value) {
    isError = true;
  }
  else if (valueName?.length > MAX_LENGTH) {
    nameRuError.value = 'Превышен лимит символов';
    isError = true;
  }

  if (nameEn.value?.length > MAX_LENGTH) {
    nameEnError.value = 'Превышен лимит символов';
    isError = true;
  }

  const valueShortDescriptionRu = shortDescriptionRu.value?.trim();
  shortDescriptionRuError.value = valueShortDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionRuError.value)
    isError = true;

  const valueDescriptionRu = descriptionRu.value?.trim();
  descriptionRuError.value = valueDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionRuError.value)
    isError = true;

  const valueAuthorImageTitleNameRu = authorImageTitleNameRu.value?.trim();
  authorImageTitleNameRuError.value = valueAuthorImageTitleNameRu?.length ? '' : 'Поле не должно быть пустым';
  if (authorImageTitleNameRuError.value) {
    isError = true;
  }
  else if (valueAuthorImageTitleNameRu?.length > MAX_LENGTH) {
    authorImageTitleNameRuError.value = 'Превышен лимит символов';
    isError = true;
  }

  if (authorImageTitleNameEn.value?.length > MAX_LENGTH) {
    authorImageTitleNameEnError.value = 'Превышен лимит символов';
    isError = true;
  }

  if (authorVideoTitleNameRu.value?.length > MAX_LENGTH) {
    authorVideoTitleNameRuError.value = 'Превышен лимит символов';
    isError = true;
  }
  if (authorVideoTitleNameEn.value?.length > MAX_LENGTH) {
    authorVideoTitleNameEnError.value = 'Превышен лимит символов';
    isError = true;
  }

  if (sourceRu.value?.length > MAX_LENGTH) {
    sourceRuError.value = 'Превышен лимит символов';
    isError = true;
  }
  if (sourceEn.value?.length > MAX_LENGTH) {
    sourceEnError.value = 'Превышен лимит символов';
    isError = true;
  }

  publishedDatetimeError.value = publishedDatetime.value ? '' : 'Поле не должно быть пустым';
  if (publishedDatetimeError.value)
    isError = true;

  previewImageError.value = previewImage.value ? '' : 'Вы не выбрали файл';
  if (previewImageError.value)
    isError = true;

  imageError.value = image.value ? '' : 'Вы не выбрали файл';
  if (imageError.value)
    isError = true;

  quoteRuErrors.value = [];
  quoteEnErrors.value = [];

  // Шорткоды
  if (true) {
    if (sliders.value?.length) {
      if (!descriptionRu.value?.includes('#SLIDER#')) {
        descriptionRuError.value = 'Вы не добавили шорткод #SLIDER# в подробное описание';
        isError = true;
      }
      if (descriptionEn.value?.length) {
        if (!descriptionEn.value?.includes('#SLIDER#')) {
          descriptionEnError.value = 'Вы не добавили шорткод #SLIDER# в подробное описание';
          isError = true;
        }
      }
    }

    quotes.value?.map(item => {
      console.log(descriptionRu.value?.includes(`#QUOTE_${item.id}#`), 'item', descriptionRu.value, item)
      if (!descriptionRu.value?.includes(`#QUOTE_${item.id}#`))
        quoteRuErrors.value.push('- Вы не добавили цитату: #QUOTE_' + item.id + '#');

      if (descriptionEn.value?.length) {
        if (!descriptionEn.value?.includes(`#QUOTE_${item.id}#`))
          quoteEnErrors.value.push('- Вы не добавили цитату: #QUOTE_' + item.id + '#');
      }
    });

    if (quoteRuErrors.value?.length)
      isError = true;

    if (quoteEnErrors.value?.length)
      isError = true;
  }

  return isError;
}

const validateAdministrationAgreed = () => {
  let isError = false;

  selectedCategoryError.value = selectedCategories.value?.length ? '' : 'Вы не выбрали категорию новости';
  if (selectedCategoryError.value)
    isError = true;

  // if (newContentStatus.value === 7)
  //   return isError;
  //
  // if (!participant.value?.id) {
  //   selectedParticipantError.value = !selectedParticipant.value?.length && (newContentStatus.value === 6 || newContentStatus.value === 14) ? 'Прикрепите к пользователю участника' : '';
  //   isError = !!selectedParticipantError.value?.length
  // }

  return isError;
}

const validateQuote = () => {
  let isError = false;

  quotes.value?.map(quote => {
    quote.nameRuError = quote?.nameRu?.length ? '' : 'Не должно быть пустым';
    if (quote.nameRuError)
      isError = true;
  });

  return isError;
}

const writeResponseData = (news) => {
  status.value = news.status;
  newContentStatus.value = status.value;

  statusAction.value = news.statusProcess;
  statusProcess.value = [];
  isNewsEdit.value = false;

  if (checkRole(['admin', 'manager'])) {
    statusProcess.value = news.statusProcess?.process?.org || [];
  }
  if (checkRole(['admin', 'content_manager'])) {
    if (status.value === 6)
      statusProcess.value?.push({id: 14, name: 'Опубликовать'});

    if (status.value === 7)
      statusProcess.value?.push({id: 5, name: 'Повторное рассмотрение'});
  }
  if (checkRole(['content_exhibitor'])) {
    statusProcess.value = news.statusProcess?.process?.exp || [];

    // if (status.value === 14)
    //   statusProcess.value?.push({id: 1, name: 'Вернуть на редактирование',});

    if (status.value === 6)
      statusProcess.value?.push({id: 14, name: 'Опубликовать'});
  }
  if (checkRole(['content_exhibitor']) && (status.value === 6 || status.value === 14)) {
    statusProcess.value.push({id: 8, name: 'Запросить редактирование'});
  }

  if (checkRole(['admin', 'manager', 'content_manager'])) {
    isNewsEdit.value = true;
  } else if (checkRole(['content_exhibitor', 'exhibitor']) && (news.status === 1 || news.status === 2 || news.status === 7)) {
    isNewsEdit.value = true;
  }

  messageData.value = '';

  integrationPublicationDate.value = news?.integrationPublicationDate;

  selectedParticipant.value = news?.contentParticipant?.id || news.participant?.id;
  contentParticipant.value = news?.contentParticipant || news?.participant;

  eventExternalId.value = news?.externalId;
  authorUserId.value = news?.user?.id;
  selectedContours.value = news.contour?.id ? [news.contour?.id] : [];
  userCreated.value = news.user;
  nameRu.value = news.nameRu;
  nameEn.value = news.nameEn;
  shortDescriptionRu.value = news.shortDescriptionRu;
  shortDescriptionEn.value = news.shortDescriptionEn;
  descriptionRu.value = news.descriptionRu;
  descriptionEn.value = news.descriptionEn;
  sourceRu.value = news.sourceRu;
  sourceEn.value = news.sourceEn;
  authorImageTitleNameRu.value = news.authorRu;
  authorImageTitleNameEn.value = news.authorEn;
  video.value = news.video;
  authorVideoTitleNameRu.value = news.authorVideoRu;
  authorVideoTitleNameEn.value = news.authorVideoEn;
  isCardPreview.value = news.isCardPreview;
  isMain.value = news.isMain;
  isTopNews.value = news.isTop;
  isMainAbout.value = news.isAboutExhibition;
  isPublishNewsSection.value = news.isPublishNewsSection;
  isPublishDetailedMember.value = news.isPublishDetailedMember;
  isActiveRu.value = news.isActiveRu;
  isActiveEn.value = news.isActiveEn;
  publishedDatetime.value = news.publishedAt;
  previewImage.value = news.previewImage;
  image.value = news.image;
  selectedCategories.value = news?.category?.id ? [news?.category?.id] : [];
  createdAt.value = news.createdAt;
  updatedAt.value = news.updatedAt;
  participant.value = news.participant;
  messages.value = news.messages || [];

  nameRuError.value = '';
  nameEnError.value = '';
  sourceRuError.value = '';
  sourceEnError.value = '';
  shortDescriptionRuError.value = '';
  shortDescriptionEnError.value = '';
  descriptionRuError.value = '';
  descriptionEnError.value = '';
  selectedContourError.value = '';
  selectedCategoryError.value = '';
  publishedDatetimeError.value = '';
  previewImageError.value = '';
  imageError.value = '';
  authorImageTitleNameRuError.value = '';
  authorImageTitleNameEnError.value = '';
  authorVideoTitleNameRuError.value = '';
  authorVideoTitleNameEnError.value = '';

  isSliderMP.value = news.isSliderMp;

  const sliderItems = news.sliders || [];
  sliders.value = [];

  sliderItems?.map(slider => {
    sliders.value.push({
      id: slider.id,
      image: slider.image,
      imageError: '',
      authorRu: slider.authorRu,
      authorRuError: '',
      authorEn: slider.authorEn,
      authorEnError: '',
      sort: slider.sort,
      sortError: '',
    });
  });

  const quoteItems = news.quotes || [];
  quotes.value = [];

  quoteItems?.map(quote => {
    quotes.value.push({
      id: quote.id,
      nameRu: quote.nameRu,
      nameRuError: '',
      nameEn: quote.nameEn,
      nameEnError: '',
      authorRu: quote.authorRu,
      authorRuError: '',
      authorEn: quote.authorEn,
      authorEnError: '',
    });
  });

  if (!selectedCategories.value) {
    const idCategory = categories.value.find(item => item.name === 'Новости участников')?.id;
    if (idCategory)
      selectedCategories.value.push(idCategory);
  }
}

const onHandleDeletePreviewImage = () => {
  previewImage.value = null;
}

const onHandleDeleteImage = () => {
  image.value = null;
}

const onHandleDeleteVideo = () => {
  video.value = null;
}

serviceNewsCategoryList().then(response => {
  isLoadingData.value = false;
  categories.value = response.data || [];
});

if (newsId.value) {
  serviceContentNewsFindId(newsId.value).then(response => {
    if (!response?.success || !response?.data)
      return null;

    const news = response.data;
    writeResponseData(news);
  });
}

const onHandleClickUpdate = (newStatus) => {
  newContentStatus.value = newStatus;

  const isValExhibitor = validateExhibitor();

  let isValAdministrationAgreed = null;
  if (!checkRole(['content_exhibitor']) && (newStatus === 6 || newStatus === 14)) {
    isValAdministrationAgreed = validateAdministrationAgreed();
  }

  const resSlider = validateSlider(sliders.value);
  isErrorValSlider.value = resSlider.isError;
  sliders.value = resSlider.items;
  messageSliderEmpty.value = resSlider.messageEmpty;

  isErrorValQuote.value = validateQuote();

  isErrorVal.value = isValExhibitor || isValAdministrationAgreed;

  if (isValExhibitor || isValAdministrationAgreed || isErrorValSlider.value || isErrorValQuote.value)
    return null;

  const params = {
    status: newStatus,
    nameRu: nameRu.value,
    nameEn: nameEn.value,
    shortDescriptionRu: shortDescriptionRu.value,
    shortDescriptionEn: shortDescriptionEn.value,
    descriptionRu: descriptionRu.value,
    descriptionEn: descriptionEn.value,
    sourceRu: sourceRu.value,
    sourceEn: sourceEn.value,
    categoryId: selectedCategories.value.length ? selectedCategories.value[0] : null,
    contourId: selectedContours.value?.length ? selectedContours.value[0] : null,
    publishedDatetime: publishedDatetime.value,
    previewImage: previewImage.value.length ? previewImage.value[0] : null,
    image: image.value.length ? image.value[0] : null,
    authorImageTitleNameRu: authorImageTitleNameRu.value,
    authorImageTitleNameEn: authorImageTitleNameEn.value,
    video: video.value?.length ? video.value[0] : null,
    videoAuthorRu: authorVideoTitleNameRu.value,
    videoAuthorEn: authorVideoTitleNameEn.value,
    isCardPreview: isCardPreview.value,
    isMain: isMain.value,
    isTopNews: isTopNews.value,
    isMainAbout: isMainAbout.value,
    isPublishNewsSection: isPublishNewsSection.value,
    isPublishDetailedMember: isPublishDetailedMember.value,
    isActiveRu: isActiveRu.value,
    isActiveEn: isActiveEn.value,
    messages: messageData.value ? [messageData.value] : [],
    sliders: sliders.value,
    quotes: quotes.value,
    participantId: selectedParticipant.value,
    isSliderMP: Number(isSliderMP.value),
  };

  isLoadingUpdate.value = true;
  serviceContentNewsUpdate(newsId.value, params).then(response => {
    const status = response?.success;
    const message = status ? 'Успешно обновлено' : 'Ошибка, данные не были обновлены';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      isLoadingUpdate.value = false;

      saveMessageResponse.value = null;
    }, 3000);

    if (response.success) {
      const news = response.data;
      writeResponseData(news);
    } else {
      console.error('log');
    }
  }).catch(() => {
    isLoadingUpdate.value = false;
  });
}

const onHandleClickSendMessage = () => {
  loadDataMessage.value = true;
  serviceContentNewsMessageCreate(newsId.value, {message: messageData.value}).then(response => {
    loadDataMessage.value = false;

    if (response.success) {
      messages.value.push({
        name: messageData.value,
        createdAt: moment().format('DD.MM.YYYY HH:mm'),
        user,
        isAnswer: !!checkRole(['content-exhibitor']),
      });

      messageData.value = null;
    }
  });
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.tabs {
  margin-bottom: 35px;
}

.tabs .tab.active {
  background: #c6d6f4;
}

.tabs .tab {
  background: #f0f8ff;
  transition: all .3s;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.tabs .tab:hover {
  cursor: pointer;
  transition: all .3s;
  background: #d0e9ff;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.tabs .tab {
  padding: 10px;
  margin: 5px;
}
</style>
