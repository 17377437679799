<template>
  <div class="audio-player">
    <div class="audio-player-wrapper">
      <div class="audio-player-info">
        <img v-if="!isPlay && !isLoad" src="/icons/control/play.svg" alt="" @click="onHandlePlay">
        <img v-if="isPlay && !isLoad" src="/icons/control/pause.svg" alt="" @click="onHandlePlay">
        <img v-if="isLoad" src="/icons/control/loading.gif" alt="" @click="onHandlePlay">
        <span>{{ time || '00:00' }}</span>
      </div>
      <div class="audio-player-mixer">
        <audio element-loading-spinner="" :class="('audio_' + id) + ' audio'" :src="src" hidden preload="none"
               controls/>
        <div :style="{width: timeLine}" class="line-time"></div>
        <div :class="('mask-line-time_' + id) + ' mask-line-time'" @click="onHandleClick"></div>
        <div v-for="(count, index) in diagram" v-bind:key="index" :style="{height: count}" class="item"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import './mt-audio-player.css';
import {defineComponent} from "vue";

export default defineComponent({
  name: "mt-audio-player",

  props: {
    src: String,
    fileName: String,
    fileFormat: String,
    mode: String,
  },

  setup() {
    const audio: HTMLAudioElement | any = null;
    const diagram: any = [];
    for (let i = 1; i < 57; i++) {
      const number = ((Math.random() * (3 - 28)) + 28) + 'px';
      diagram.push(number);
    }

    const id = 234234;

    return {
      id,
      audio,
      widthMask: 0,
      duration: 0,
      diagram,
    }
  },

  data() {
    const isLoad: any = null;
    return {time: '', isPlay: false, isLoad, timeLine: '',}
  },

  mounted() {
    this.audio = this.$el.querySelector('.audio_' + this.id);
    this.widthMask = this.$el.querySelector('.mask-line-time_' + this.id).getBoundingClientRect().width;

    this.audio.addEventListener('loadeddata', this.listenerLoadeddata);
    this.audio.addEventListener('timeupdate', this.listenerTimeupdate);
    this.audio.addEventListener('ended', this.listenerEnded);
  },

  unmounted() {
    this.audio.removeEventListener('loadeddata', this.listenerLoadeddata);
    this.audio.removeEventListener('timeupdate', this.listenerTimeupdate);
    this.audio.removeEventListener('ended', this.listenerEnded);
  },

  methods: {
    // Получаем полное время трека, когда трек загружен
    listenerLoadeddata() {
      this.duration = this.audio.duration;
      this.isLoad = false;
    },
    // Получаем время
    listenerTimeupdate() {
      const currentTime = Math.floor(this.audio.currentTime);

      // расчет полосы закраски при проигрывании мелодии
      const time = (((1 / this.duration) * 100) * this.widthMask) / 100;
      this.timeLine = (currentTime * time) + 'px';

      this.time = this.formatTime(currentTime);
    },
    // Ждем, если аудио доиграла до конца
    listenerEnded() {
      this.isPlay = false;
      this.time = '';
      this.timeLine = '100%';
    },

    // Получаем ширину, например максимальная ширина 300px если мы выбрали середину то будет 50%, нужно для расчета времени
    onHandleClick(e) {
      // Получаем ширину клика
      const widthMaskSelect = e.layerX;
      // Получаем процент по клику, ширина * на максимальную ширину
      const changeWidthMaskSelect = this.widthMask ? ((widthMaskSelect / this.widthMask) * 100) : 0;

      // Получаем числовое время при выборе ширины и перематываем мелодию по времени
      this.audio.currentTime = this.duration * (changeWidthMaskSelect / 100);
    },

    // Событие на pause или stop
    onHandlePlay() {
      if (this.isPlay) {
        this.audio.pause();
        this.isPlay = false;
      } else {
        // Начинаем загрузку
        if (!this.isLoad) {
          this.isLoad = true;
          this.audio.load();
        }
        this.audio.play();
        this.isPlay = true;
      }
    },

    // Выводим красиво время 245,56787 ---> (00:45)
    formatTime(seconds: number): string {
      let minutes: any = Math.floor(seconds / 60);
      let secs: any = Math.floor(seconds % 60);

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      if (secs < 10) {
        secs = '0' + secs;
      }

      return minutes + ':' + secs;
    },
  },

});
</script>
