<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <modal
            v-if="isShowModalAnyDocument"
            @closeDialog="isShowModalAnyDocument=false"
            :load-data="loadDataInModalAnyDocument">
          <template #title>Контур документа</template>
          <template #body>
            <form action="#">
              <div class="grid gap-4 mb-12 sm:grid-cols-2">
                <div class="sm:col-span-2">

                  <label class="block mb-2 font-medium text-basic-gray">Выберите контур:</label>
                  <Multiselect
                      v-model="selectedContours"
                      :options="documentAction === 'document' ? createdContours.documents : createdContours.coordinations"
                      max="1"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true" />
                  <p v-if="docSelectedContourError" class="mt-2 text-sm text-red-600"> {{ docSelectedContourError }}</p>

                </div>
              </div>

              <div
                  style="margin-top: 180px;"
                  class="flex items-center space-x-4">
                <button @click.prevent="onHandleNextSteep" type="submit"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  Продолжить
                </button>
                <button @click="isShowModalAnyDocument=false" type="button"
                        class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  {{ $t('common.cancel') }}
                </button>
              </div>
            </form>
          </template>
        </modal>

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">
              {{ $t('common.coordination') }}
            </h1>
          </div>
          <div class="flex-shrink-0">
            <button
                v-if="checkRole(['admin', 'manager'])"
                type="button"
                @click="download"
                :disabled="loadDataDownload || !entityStore.entities.length"
                :class="{'opacity-50': loadDataDownload || !entityStore.entities.length}"
                class="mr-3 p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100"
            >
              {{ $t('document.download') }}
            </button>

            <button
                @click="isDropdownOpen = !isDropdownOpen"
                id="dropdown-button-types"
                data-dropdown-toggle="dropdown-types"
                class="mr-3 p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100 inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
            >
              {{ $t('document.add') }}
              <svg
                  class="w-2.5 h-2.5 ml-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"/>
              </svg>
            </button>
            <!-- Dropdown menu -->
            <div
                :class="!isDropdownOpen ? 'show' : 'hidden'"
                id="dropdown-types"
                class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
              <ul
                  class="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdown-button-types">
                <li
                    style="cursor: pointer; color: #1c64f2"
                    @click="onHandleClickDocCreate('coordination')"
                    v-if="isCreateCoordination"
                    class="p-2 w-full hover:bg-gray-100">
<!--                  <router-link-->
<!--                      :to="{name: 'CoordinationCreate'}"-->
<!--                      class="text-sm font-medium text-blue-700">-->
<!--                    &plus; {{ $t('common.event') }}-->
<!--                  </router-link>-->
                  &plus; {{ $t('common.event') }}
                </li>
                <li
                    style="cursor: pointer; color: #1c64f2"
                    v-if="isCreateDocument"
                    @click="onHandleClickDocCreate('document')"
                    class="p-2 w-full hover:bg-gray-100">
<!--                  <router-link-->
<!--                      v-if="isCreateDocument"-->
<!--                      :to="{name: 'RegistryEdit', params: {id: 0}}"-->
<!--                      class="text-sm font-medium text-blue-700">-->
<!--                    &plus; {{ $t('common.document') }}-->
<!--                  </router-link>-->
                  &plus; {{ $t('common.document') }}
                </li>
                <li
                    v-if="checkRole(['manager', 'admin'])"
                    class="p-2 w-full hover:bg-gray-100">
                  <router-link
                      :to="{name: 'AdminMessageExhibitorCreate'}"
                      class="text-sm font-medium text-blue-700">
                    &plus; Сообщение экспоненту
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-full">
            <div
                class="flex flex-col w-full">
              <div
                  style="display: flex; align-items: center; flex-wrap: wrap; justify-content: flex-start;" class="flex w-full mb-4">
                <button
                    v-for="contour in contours"
                    @click.prevent="onHandleFilterContour(contour.id)"
                    :class="selectedFilterContours.findIndex(itemId => itemId === contour.id) !== -1 ? 'bg-blue-500' : 'bg-gray-400'"
                    class="px-7 mr-5 mt-5 py-3 rounded-md text-white font-medium hover:bg-blue-500">
                  <span>{{ contour.name }}</span>
                </button>
              </div>

              <div class="w-full mt-10">
                <input-text
                    :disabled="isLoadingFilter"
                    v-model="search"
                    label="Поиск" />
              </div>

              <div class="flex w-full mb-4 mt-5" v-if="checkRole(['admin', 'manager'])">
                <div class="flex-col w-1/2 mr-4">
                  <Multiselect
                      :disabled="isLoadingFilter"
                      v-model="exhibitorType"
                      :options="exhibitorTypes"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="$t('document.byExhibitorType')"/>
                </div>
                <div class="flex-col w-1/2">
                  <Multiselect
                      :disabled="isLoadingFilter"
                      v-model="exhibitor"
                      :options="exhibitors.filter(item => !exhibitorType.length || exhibitorType.includes(item.type_id))"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="$t('document.byExhibitor')"/>
                </div>
              </div>
              <div class="flex w-full mb-4" v-if="checkRole(['admin', 'manager']) && exhibitorTypes.find(item => exhibitorType.includes(item.id) && item.is_federal)">
                <div class="flex-col w-full">
                  <Multiselect
                      :disabled="isLoadingFilter"
                      v-model="federalDistrict"
                      :options="federalDistricts"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="$t('document.byFederalDistrict')"/>
                </div>
              </div>

              <div class="flex w-full mb-4">
                <div class="flex-col w-1/2 mr-4">
                  <Multiselect
                      :disabled="isLoadingFilter"
                      v-model="type"
                      :options="typeList"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="$t('document.byType')"/>
                </div>
                <div
                    v-if="type.find(item => item === 3)"
                    class="flex-col w-1/2 mr-4">
                  <Multiselect
                      :disabled="isLoadingFilter"
                      v-model="selectedInternationalParticipation"
                      :options="internationalParticipationList"
                      :mode="'tags'"
                      max="1"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="'Международное участие'"/>
                </div>
                <div class="flex-col w-1/2">
                  <Multiselect
                      :disabled="isLoadingFilter"
                      v-model="status"
                      :options="statusList"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="$t('document.byStatus')"/>
                </div>
              </div>
              <div class="flex w-full">
                <div class="flex-col w-1/3 mr-4">
                  <VueDatePicker
                      :disabled="isLoadingFilter"
                      v-model="createdAt"
                      :placeholder="$t('document.byCreatedAt')"
                      range
                      :partial-range="false"
                      locale="ru"
                      model-type="yyyy-MM-dd"
                      :format="'dd.MM.yyyy'"
                      auto-apply
                      :enable-time-picker="false" />
                </div>
                <div class="flex-col w-1/3 mr-4">
                  <VueDatePicker
                      :disabled="isLoadingFilter"
                      v-model="updatedAt"
                      :placeholder="$t('document.byUpdatedAt')"
                      range
                      :partial-range="false"
                      locale="ru"
                      model-type="yyyy-MM-dd"
                      :format="'dd.MM.yyyy'"
                      auto-apply
                      :enable-time-picker="false" />
                </div>
                <div class="flex-col w-1/3">
                  <VueDatePicker
                      :disabled="isLoadingFilter"
                      v-model="deadline"
                      :placeholder="$t('document.byDeadlineAt')"
                      range
                      :partial-range="false"
                      locale="ru"
                      model-type="yyyy-MM-dd"
                      :format="'dd.MM.yyyy'"
                      auto-apply
                      :enable-time-picker="false" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <template v-if="entityIds.length">
              <div>
                <button @click.prevent="deleteEntities" v-if="!isRestoreEntity" class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>{{ $t('common.delete') }}</span>
                </button>
                <button @click.prevent="restoreEntities" v-if="isRestoreEntity" class="flex px-6 py-4 bg-blue-500 rounded-md text-white hover:bg-blue-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>{{ $t('common.restore') }}</span>
                </button>
              </div>
            </template>
            <template v-else>
              <div v-if="entityStore.pagination">
                <span class="text-gray-500">{{ $t('common.found') }}</span> {{ entityStore.pagination.total }}
              </div>
            </template>
          </div>
          <div class="flex-col p-7 w-1/2 text-right">
            <nav v-if="entityStore.pagination && entityStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of entityStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="entityPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                    <tr>
                      <th scope="col" class="p-4" v-if="allowDeleteEntity">
                        <div class="flex items-center">
                          <input v-model="checkboxAllSelected"
                                 @click="selectAllCheckbox"
                                 id="checkbox-all" type="checkbox"
                                 class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <div class="flex items-center cursor-pointer" :class="{'text-black font-normal': sort === 'list_contour_id'}" @click.prevent="sortBy('list_contour_id')">
                          <span>{{ $t('common.contourType') }}</span>
                          <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1"><path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/></svg>
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <div class="flex items-center cursor-pointer" :class="{'text-black font-normal': sort === 'exhibitor_name'}" @click.prevent="sortBy('exhibitor_name')">
                          <span>{{ $t('document.exhibitorName') }}</span>
                          <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1"><path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/></svg>
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <div class="flex items-center cursor-pointer" :class="{'text-black': sort === 'name'}" @click.prevent="sortBy('name')">
                          <span>{{ $t('document.documentName') }}</span>
                          <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1"><path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/></svg>
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <div class="flex items-center cursor-pointer" :class="{'text-black font-normal': sort === 'created_at'}" @click.prevent="sortBy('created_at')">
                          <span>{{ $t('document.created') }}</span>
                          <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1"><path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/></svg>
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <div class="flex items-center cursor-pointer" :class="{'text-black font-normal': sort === 'updated_at'}" @click.prevent="sortBy('updated_at')">
                          <span>{{ $t('document.updated') }}</span>
                          <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1"><path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/></svg>
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <div class="flex items-center cursor-pointer" :class="{'text-black font-normal': sort === 'deadline'}" @click.prevent="sortBy('deadline')">
                          <span>{{ $t('document.deadline') }}</span>
                          <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1"><path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/></svg>
                        </div>
                      </th>
                      <th scope="col" class="p-4 text-left font-light text-gray-400">
                        <div class="flex items-center cursor-pointer" :class="{'text-black font-normal': sort === 'status'}" @click.prevent="sortBy('status')">
                          <span>{{ $t('document.status') }}</span>
                          <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1"><path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/></svg>
                        </div>
                      </th>
                      <th scope="col" class=""></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="entity of entityStore.entities" :key="entity.id" :class="{'bg-neutral-100 opacity-50': entity.isDeleted, 'bg-blue-50': entity.isNotRead}" class="hover:bg-gray-100" @dblclick="onHandleClickTableDocument(entity)">
                    <td class="p-4 w-4" v-if="allowDeleteEntity">
                      <div class="flex items-center">
                        <input v-model="entityIds" :value="entity.id" aria-describedby="checkbox-1" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ entity.contour?.name }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ entity.exhibitor?.name }}</p>
                    </td>
                    <td class="p-4" style="overflow-wrap: anywhere;">
                      <p class="text-base font-medium text-gray-900">
                        <span>{{ entity.name }}</span>
                      </p>
                      <p class="text-sm text-gray-500">{{ entity.document_type?.key === 'event' ? entity.document_type?.name : typeList.find(item => item.id === entity.type).name }}</p>
                      <p class="text-sm text-gray-500">{{ entity.doc_types.map(item => documentTypeList.find(documentType => documentType.id === item.id).name).join(', ') }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ moment(entity.created_at).format('DD.MM.YYYY') }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ moment(entity.updated_at).format('DD.MM.YYYY') }}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">
                        {{
                          entity.document_type?.key === 'event'
                            ? (entity.date_start ? moment(entity.date_start).format('DD.MM.YYYY HH:mm') : '') + ' - ' + (entity.date_end ? moment(entity.date_end).format('DD.MM.YYYY HH:mm') : '')
                            : (entity.deadline ? moment(entity.deadline).format('DD.MM.YYYY') : '')
                        }}
                        {{ entity.deadline ? moment(entity.deadline).format('DD.MM.YYYY') : '' }}
                      </p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">
                        <span class="px-4 py-1 rounded-xl whitespace-nowrap" :class="['bg-status-' + entity.status]">{{ entity.statusName }}</span>
                      </p>
                    </td>
                    <td class="text-right">
                      <router-link :to="onHandleClickTableDocument(entity, true)" class="py-2 px-3 text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !entityStore.entities.length" class="text-xl text-center p-7">{{ $t('common.notFound') }}</h3>
                <div class="flex-col p-7 w-2/2 text-right">
                  <nav v-if="entityStore.pagination && entityStore.pagination.lastPage > 1">
                    <ul class="inline-flex -space-x-px">
                      <li v-for="(link, index) of entityStore.pagination.links">
                        <a v-if="link.url" href="#"
                           @click.prevent="entityPaginate(link.url)"
                           :class="classInputPagination(index, link.active)"
                           class="px-3 py-2 border border-gray-300">
                          <span v-html="link.label"></span>
                        </a>
                        <a v-else
                           :class="classInputPagination(index, link.active)"
                           class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                          <span v-html="link.label"></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRegistryStore} from "../../stores/RegistryStore.js";
import {useInterfaceStore} from "../../stores/InterfaceStore.js";
import {checkRole} from "../../utilits/functions";
import Spinner from "../flowbite/spinner.vue";
import InputText from "../flowbite/form/inputText.vue";
import Modal from "../flowbite/modal.vue";
import {ref, watch} from "vue";
import moment from 'moment'
import Multiselect from '@vueform/multiselect'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from "axios";
import {serviceSelectedUserContours} from "../../service/user/user.js";
import {serviceCoordinationTypeList} from "../../service/lists/serviceListCoordinationType.js";
import {serviceCoordinationStatusList} from "../../service/lists/serviceListCoordinationStatus.js";
import {
  serviceCoordinationDocumentDownload,
  serviceCoordinationList,
  serviceCoordinationMultiDelete
} from "../../service/documents/serviceCoordination.js";
import {serviceDocumentList} from "../../service/documents/serviceDocument.js";
import {useRoute, useRouter} from 'vue-router';

const interfaceStore = useInterfaceStore();

const entityStore = useRegistryStore();

const isDropdownOpen = ref(true);

const isLoadingFilter = ref(false);

const isRestoreEntity = ref(false);
const loadData = ref(true);
const checkboxAllSelected = ref(false);
const entityIds = ref([]);
const search = ref('');
let debounceTimeout = null;

const sort = ref('');
const sortDirection = ref('desc');

const allowDeleteEntity = checkRole(['admin']);

const loadDataDownload = ref(false);

const isShowModalAnyDocument = ref(false);
const loadDataInModalAnyDocument = ref(true);
const selectedContours = ref([]);
const docSelectedContourError = ref('');

const createdAt = ref(null);
const updatedAt = ref(null);
const deadline = ref(null);
const status = ref([]);
const type = ref([]);
const exhibitor = ref([]);
const exhibitorType = ref([]);
const federalDistrict = ref([]);

const statusList = ref([])
const typeList = ref([])
const documentTypeList = ref([])
const exhibitors = ref([])
const exhibitorTypes = ref([])
const federalDistricts = ref([])
const documentAction = ref('');

const internationalParticipationList = ref([
  {
    id: 1,
    name: 'Да',
  },
  {
    id: 2,
    name: 'Нет'
  }
]);

const selectedInternationalParticipation = ref([]);

const onHandleClickTableDocument = (item, isOnlyParams = false) => {
  let data = {};

  if (item.type === 1) {
    data = {name: checkRole(['admin', 'manager']) ?  'AdminDocumentEdit' : 'ExhibitorDocumentEdit', params: {id: item.id}};
  }
  else if (item.type === 2) {
    data = {name: checkRole(['admin', 'manager']) ?  'AdminMessageExhibitorEdit' : 'ExhibitorMessageExhibitorEdit', params: {id: item.id}};
  }
  else if (item.type === 3) {
    data = {name: 'CoordinationEdit', params: {id: item.id}};
  }
  else {
    data = {
      name: item.document_type?.key === 'event' ? 'CoordinationEdit' : 'RegistryEdit',
      params: {id: item.id}
    };
  }

  if (!isOnlyParams)
    router.push(data);

  else return data;
}

// Документ
const isCreateDocument = ref(false);
// Мероприятия
const isCreateCoordination = ref(false);

const contours = ref([]);
const selectedFilterContours = ref([]);

// тип контора отфильтрованы, которые доступны для выбора в модальном окне
const createdContours = ref({
  documents: [],
  coordinations: [],
});

const route = useRoute()
const router = useRouter()

const onHandleFilterContour = (id) => {
  const index = selectedFilterContours.value.findIndex(itemId => itemId === id);

  if (index === -1) {
    selectedFilterContours.value.push(id);
  }
  else
    selectedFilterContours.value.splice(index, 1);

  searchEntity();
}

// Функция, которая будет вызвана при изменении значения search
const handleSearchChange = (newValue) => {
  // Очищаем предыдущий таймаут, если он уже был установлен
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  // Устанавливаем новый таймаут для отправки запроса через 500 миллисекунд
  debounceTimeout = setTimeout(() => {
    searchEntity();
  }, 500);
};

const onHandleClickDocCreate = (type) => {
  documentAction.value = type;

  if (checkRole(['admin', 'manager'])) {
    if (type === 'document') {
      router.push({
        name: 'AdminDocumentCreate',
      });
    }
    else if (type === 'coordination') {
      router.push({
        name: 'CoordinationCreate',
      });
    }
  }
  else isShowModalAnyDocument.value = true;
}

const onHandleNextSteep = () => {
  if (!documentAction.value) {
    docSelectedContourError.value = 'Вы не выбрали контур';

    return null;
  }

  if (!selectedContours.value) {
    docSelectedContourError.value = 'Вы не выбрали контур';
  }

  docSelectedContourError.value = '';

  if (documentAction.value === 'coordination') {
    const contourId = selectedContours.value ? createdContours.value.coordinations?.find(item => item.id === selectedContours.value[0])?.id : null;

    if (contourId)
      router.push({
        name: 'CoordinationCreate',
        params: { contour: contourId }
      });
  }

  if (documentAction.value === 'document') {
    const contourId = selectedContours.value ? createdContours.value.documents?.find(item => item.id === selectedContours.value[0])?.id : null;

    if (contourId) {
      router.push({
        name: 'ExhibitorDocumentCreate',
        params: {contour: contourId}
      });
    }
    else {
      router.push({
        name: 'AdminDocumentCreate',
      });
    }
  }
}

serviceSelectedUserContours().then(response => {
  const items = response?.data || [];

  contours.value = items;

  loadDataInModalAnyDocument.value = false;

  if (items.length) {
    items?.map((type, index) => {
      type?.userDocTypes?.map(item => {
        if (item.key === 'document') {
          isCreateDocument.value = true;
          createdContours.value.documents.push(items[index]);
        }
        if (item.key === 'event') {
          isCreateCoordination.value = true;
          createdContours.value.coordinations.push(items[index]);
        }
      });
    });
  }

  if (checkRole(['admin', 'manager'])) {
    isCreateDocument.value = true;
    isCreateCoordination.value = true;
  }
});

// Первоначальные данные
entityStore.getDirectories().then(() => {
  statusList.value = [...statusList.value, ...entityStore.statusList];
  documentTypeList.value = [...documentTypeList.value, ...entityStore.documentTypeListAll];
  typeList.value = [...typeList.value, ...entityStore.typeList];
  exhibitors.value = [...exhibitors.value, ...entityStore.exhibitors];
  exhibitorTypes.value = [...exhibitorTypes.value, ...entityStore.exhibitorTypes];
  federalDistricts.value = [...federalDistricts.value, ...entityStore.federalDistricts];

  entityStore.getEntities().then(() => {
    loadData.value = false;
  });
});

// Галочка выбрать всё
const selectAllCheckbox = () => {
  entityIds.value = [];

  if (!checkboxAllSelected.value) {
    entityStore.entities.forEach((entity, index) => {
      entityIds.value.push(entity.id)
    });
  }
}

// Работа с данными
const entityPaginate = (url) => {
  if (url) {
    loadData.value = true;

    let params = getSearchParams()

    entityStore.getEntities(params, url).then(() => {
      loadData.value = false;
    });
  }
}

const deleteEntities = () => {
  entityStore.deleteEntities(entityIds.value).then(() => {
    entityIds.value = [];
  })
}

const searchEntity = () => {
  loadData.value = true;
  isLoadingFilter.value = true;

  let params = getSearchParams()

  entityStore.getEntities(params).then(() => {
    loadData.value = false;
    isLoadingFilter.value = false;
  });
}

const sortBy = (sortBy) => {
  sort.value = sortBy
  sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc'

  searchEntity()
}

watch(search, handleSearchChange);

watch([selectedFilterContours, selectedInternationalParticipation, type, exhibitor, exhibitorType, status, createdAt, updatedAt, deadline, federalDistrict], () => {
  searchEntity()
})

watch(entityIds, () => {
  isRestoreEntity.value = false

  let ids = []

  entityIds.value.forEach((entityId) => {
    ids.push(entityId)
  })

  entityStore.entities.forEach((entity) => {
    if (ids.indexOf(entity.id) !== -1 && entity.isDeleted) {
      isRestoreEntity.value = true
    }
  })
})

const restoreEntities = () => {
  entityStore.restoreEntities(entityIds.value).then(() => {
    entityIds.value = [];
  })
}

const getSearchParams = () => {
  let internationalParticipation = null;
  if (type.value.find(id => id === 3) && selectedInternationalParticipation.value?.length) {
    internationalParticipation = selectedInternationalParticipation.value.includes(1);
  }

  return {
    sort: sort.value,
    sortDirection: sortDirection.value,
    search: search.value,
    filter: {
      type: type.value,
      exhibitor_id: exhibitor.value,
      exhibitor_type_id: exhibitorType.value,
      status: status.value,
      created_at: createdAt.value,
      updated_at: updatedAt.value,
      deadline: deadline.value,
      federal_district: federalDistrict.value,
      selected_filter_contours: selectedFilterContours.value,
      internationalParticipation: internationalParticipation
    }
  }
}

const download = () => {
  loadDataDownload.value = true

  let config = {
    responseType: 'blob',
    params: getSearchParams(),
  }

  serviceCoordinationDocumentDownload(config).then(response => {

  });
}

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === entityStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>
