import {getToken, handleAsync} from "../../handleAsync.js";
import axios from "axios";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

function queryParams(params) {
    let query = '';

    if (params?.status) {
        query = '?status=' + params.status;
    }
    if (params?.exhibitor) {
        query += (!query.length ? ('?') : ('&')) + ('exhibitor=' + params.exhibitor);
    }
    if (params?.createdAt) {
        query += (!query.length ? ('?') : ('&')) + ('createdAt=' + params.createdAt);
    }
    if (params?.updatedAt) {
        query += (!query.length ? ('?') : ('&')) + ('updatedAt=' + params.updatedAt);
    }
    if (params?.search) {
        query += (!query.length ? ('?') : ('&')) + ('search=' + params.search);
    }
    if (params?.page) {
        query += (!query.length ? ('?') : ('&')) + ('page=' + params.page);
    }

    return query;
}

export async function serviceContentProductList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/products` + queryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentProductFindById(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/products/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentProductCreate(data) {
    const token = await getToken();

    return await axios.post(`${url}/contents/products/create`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e?.response?.data;

            return {
                success: false,
                errors: response?.errors || [],
                onlyErrors: response?.onlyErrors || [],
            };
        });
}

export async function serviceContentProductUpdate(id, data) {
    const token = await getToken();

    return await axios.post(`${url}/contents/products/${id}/edit`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e?.response?.data;

            return {
                success: false,
                errors: response?.errors || [],
                onlyErrors: response?.onlyErrors || [],
            };
        });
}

export async function serviceContentProductMessageCreate(id, params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/products/${id}/message/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    }));
}

export async function serviceContentProductMultiDelete(ids) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/products/multi-delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({ids})
    }));
}
