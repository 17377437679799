import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceContourList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/doc-types` + getQueryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

function getQueryParams(params) {
    let queryParams = params?.page ? `?page=${params.page}` : '';
    if (params?.limit)
        queryParams += (queryParams ? '&' : '?') + `limit=${params.limit}`;
    if (params?.search)
        queryParams += (queryParams ? '&' : '?') + `search=${params.search}`;
    if (params?.contourId)
        queryParams += (queryParams ? '&' : '?') + `contourId=${params.contourId}`;

    return queryParams;
}
