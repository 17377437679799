import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useDocsDirectoryStore = defineStore('docTypeStore', () => {
    const entities = ref([]);
    const roles = ref([]);
    const exhibitorTypes = ref([]);
    const extensions = ref([]);
    const entity = ref(null);
    const pagination = ref(null);
    const error = ref(null);

    const getDirectories = async (params = null) => {
        await axios.get('/exhibitors/doc-types/directories')
            .then((r) => {
                roles.value = r.data.data.roles
                exhibitorTypes.value = r.data.data.exhibitorTypes
                extensions.value = r.data.data.extensions
            }).catch((e) => {
                console.log(e)
            })
    }

    const getEntities = async (params = null, url = '/exhibitors/doc-types') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    };

    const getEntityById = async (id) => {
        await axios.get('/exhibitors/doc-types/' + id)
            .then((r) => {
                entity.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    };

    const storeEntity = async (data) => {
        await axios.post('/exhibitors/doc-types/store', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    };

    const deleteEntities = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/exhibitors/doc-types', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    const ids = response.data;

                    const missingIds = data.filter(id => !ids.includes(id));
                    const filteredItems = entities.value.filter(item => !missingIds.some(id => id === item.id));

                    filteredItems.map(item => {
                        if (ids.includes(item.id)) {
                            item.errorMessage = 'Внимание! Удалить данное значение справочника запрещено, так как оно используется в других объектах личного кабинета';
                        } else {
                            item.errorMessage = null;
                        }

                        return item;
                    });

                    entities.value = filteredItems;

                    setTimeout(() => {
                        entities.value.map(item => {
                            item.errorMessage = null;
                        })
                    }, 8000);

                    // getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const setEmptyEntity = async () => {
        entity.value = {
            name: '',
            group: '',
            priority: '',
            isDeleted: '',
            access_exhibitor_type_ids: [],
            selectedContours: [],
        }
    };

    const resetEntity = async () => {
        entity.value = null;
    }

    const restoreEntities = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/exhibitors/doc-types/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    return {
        pagination,
        entities,
        entity,
        roles,
        exhibitorTypes,
        extensions,
        error,
        setEmptyEntity,
        getEntities,
        getEntityById,
        storeEntity,
        deleteEntities,
        resetEntity,
        restoreEntities,
        getDirectories,
    }
})
