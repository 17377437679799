import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceUserParticipant() {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/user/participant`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceNewsQuota() {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/user/news-quota`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}
