<template>
  <div class="item-section">
    <div>
      <div class="mb-5">
        <label class="block mb-2 font-medium text-basic-gray">Выберите докладчика/спикера/модератора</label>
        <Multiselect
            :disabled="true"
            v-model="localTypeSpeaker"
            :options="typeSpeakerItems"
            :valueProp="'id'"
            track-by="name"
            label="name"
            :close-on-select="false"
            :searchable="true"/>
        <p v-if="typeSpeakerError" class="mt-2 text-sm text-red-600"> {{ typeSpeakerError }} </p>
      </div>
      <hr class="mb-4 mb-2">
    </div>
    <div class="mb-5 mt-5">
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localNameRu"
            label="Наименование (RU) *"/>
        <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
      </div>
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localNameEn"
            label="Наименование (EN)"/>
        <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
      </div>
    </div>
    <div class="grid gap-4 mb-5 sm:grid-cols-2">
      <div class="sm:col-span-2">
        <input-textarea
            :disabled="localIsDisable"
            class="placeholder-gray-100"
            placeholder=""
            v-model="localDescriptionRu"
            label="Подробное описание (RU) *"
        />
        <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600"> {{ descriptionRuError }} </p>
      </div>
      <div class="sm:col-span-2">
        <input-textarea
            :disabled="localIsDisable"
            class="placeholder-gray-100"
            placeholder=""
            v-model="localDescriptionEn"
            :label="'Подробное описание (EN)'"
        />
        <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600"> {{ descriptionEnError }} </p>
      </div>
    </div>
    <div class="mb-5 mt-5">
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localPositionRu"
            label="Должность (RU) *"/>
        <p v-if="positionRuError" class="mt-2 text-sm text-red-600"> {{ positionRuError }} </p>
      </div>
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localPositionEn"
            label="Должность (EN)"/>
        <p v-if="positionEnError" class="mt-2 text-sm text-red-600"> {{ positionEnError }} </p>
      </div>
    </div>

    <div class="mb-7" v-if="typeof id !== 'string'">
      <div v-if="typeof localImage !== 'string' && localImage?.length">
        <div class="image-content"
             style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
          <h2 class="mb-2" style="text-align: center">Изображение *</h2>
          <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
            <span class="mr-2">{{ localImage[0].name }}</span>
            <button @click="localImage=null" type="button" class="hover:opacity-60">
              <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z"
                    stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
          v-else-if="typeof localImage !== 'string'"
          class="grid gap-4 mb-4">
        <div class="sm:col-span-2">
          <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
          <drop-file
              :disabled="localIsDisable"
              v-model="localImage"
              :type="imageTypes"
              :single="true"
          />
        </div>
      </div>
      <div
          v-else
          class="image-content" style="text-align: center; width: 250px; margin: auto;">
        <img
            style="width: 250px; height: auto; object-fit: cover"
            :src="localImage"
            alt="">
        <button
            v-if="!localIsDisable"
            @click="localImage=null"
            class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
          Удалить
        </button>
      </div>
      <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>
    </div>

    <div class="mb-5 mt-5" style="margin-bottom: 15px;">
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localAuthorRu"
            label="Автор (RU) *"/>
        <p v-if="authorRuError" class="mt-2 text-sm text-red-600"> {{ authorRuError }} </p>
      </div>
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localAuthorEn"
            label="Автор (EN)"/>
        <p v-if="authorEnError" class="mt-2 text-sm text-red-600"> {{ authorEnError }} </p>
      </div>
    </div>
    <button
        v-if="isCreate"
        @click="onDeleteCard(id, index)"
        type="button"
        style="margin-bottom: 30px; padding: 5px 15px;"
        class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
      Удалить
    </button>
    <button
        v-else-if="!isDisable"
        @click="onDeleteCard(id, index)"
        type="button"
        style="margin-bottom: 30px; padding: 5px 15px;"
        class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
      Удалить
    </button>
  </div>
</template>

<script lang="ts" setup>

import {defineProps, defineEmits, watch, ref} from 'vue';
import InputText from "../../../../flowbite/form/inputText.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect'

const {
  index,
  key,
  id,
  nameRu,
  nameRuError,
  nameEn,
  nameEnError,
  descriptionRu,
  descriptionRuError,
  descriptionEn,
  descriptionEnError,
  positionRu,
  positionRuError,
  positionEn,
  positionEnError,
  image,
  imageError,
  authorRu,
  authorRuError,
  authorEn,
  authorEnError,
  typeSpeaker,
  typeSpeakerError,
  isDisable,
  isCreate,
} = defineProps([
  'key', 'id', 'index', 'typeSpeaker', 'nameRu', 'nameEn', 'descriptionRu', 'descriptionEn', 'positionRu', 'positionEn', 'image', 'authorRu', 'authorEn', 'isDisable',
  'nameRuError', 'nameEnError', 'descriptionRuError', 'descriptionEnError', 'positionRuError', 'positionEnError', 'imageError',
  'authorRuError', 'authorEnError', 'typeSpeakerError', 'isCreate',
]);

const localNameRu = ref(nameRu);
const localNameEn = ref(nameEn);
const localDescriptionRu = ref(descriptionRu);
const localDescriptionEn = ref(descriptionEn);
const localPositionRu = ref(positionRu);
const localPositionEn = ref(positionEn);
const localAuthorRu = ref(authorRu);
const localAuthorEn = ref(authorEn);
const localImage = ref(image);
const localTypeSpeaker = ref(typeSpeaker);

const typeSpeakerItems = ref([
  {name: 'Спикер', id: 'speaker'},
  {name: 'Модератор', id: 'moderator'},
  {name: 'Докладчик', id: 'lecturer'},
]);

const localIsDisable = ref(isDisable);

const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

const onDeleteCard = (id, index) => {
  emit('click:onDelete', {action: typeSpeaker + 's', id, index});
}

const emit = defineEmits([
  'update:nameRu', 'update:nameEn', 'update:descriptionRu', 'update:descriptionEn', 'update:positionRu',
  'update:positionEn', 'update:authorRu', 'update:authorEn', 'update:image', 'update:typeSpeaker', 'click:onDelete',
]);

watch(localTypeSpeaker, (newVal) => {
  emit('update:typeSpeaker', newVal);
});

watch(localNameRu, (newVal) => {
  emit('update:nameRu', newVal);
});

watch(localNameEn, (newVal) => {
  emit('update:nameEn', newVal);
});

watch(localDescriptionRu, (newVal) => {
  emit('update:descriptionRu', newVal);
});

watch(localDescriptionEn, (newVal) => {
  emit('update:descriptionEn', newVal);
});

watch(localPositionRu, (newVal) => {
  emit('update:positionRu', newVal);
});

watch(localPositionEn, (newVal) => {
  emit('update:positionEn', newVal);
});

watch(localAuthorRu, (newVal) => {
  emit('update:authorRu', newVal);
});

watch(localAuthorEn, (newVal) => {
  emit('update:authorEn', newVal);
});

watch(localImage, (newVal) => {
  emit('update:image', newVal);
});

</script>

<style src="../../../../../../node_modules/@vueform/multiselect/themes/default.css"></style>

<style>
.item-section {
  padding: 15px 15px 0 15px;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
</style>
