import {getToken, handleAsync} from "../handleAsync.js";
import axios from "axios";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceCoordinationList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/documents/coordinations` + getQueryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceCoordinationFindById(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${import.meta.env.VITE_BASE_URL_API}/exhibitors/docs/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceCoordinationDocumentDownload(config) {
    const token = await getToken();

    return axios.get(`${url}/documents/coordinations/download`, config)
        .then(response => {
            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'documents.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)

            loadDataDownload.value = false
        }).catch(console.error)
}

export async function serviceCoordinationUpdate(id, data) {
    const token = await getToken();

    return await axios.post(`${url}/documents/doc-event-store/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: r?.status === 200,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceCoordinationCreate(data) {
    const token = await getToken();

    return await axios.post(`${url}/documents/doc-event-store`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceCoordinationSendMessage(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/documents/doc-event-store`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params),
    }));
}

export async function serviceCoordinationMultiDelete(ids) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/documents/coordinations/multi-delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({ids}),
    }));
}

export async function serviceCoordinationChangeStatus(id, data) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/documents/coordinations/${id}/status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data),
    }));
}

export async function serviceContourExhibitorTypeExhibitor(id, data) {
    const token = await getToken();
    return await handleAsync(fetch(`${url}/contours/${id}/exhibitor-types/exhibitors`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({exhibitorTypes: data}),
    }));
}

function getQueryParams(params) {
    if (!params)
        return '';

    let queryParams = params?.contours ? `?contours=${JSON.stringify(params.contours)}` : '';
    if (params?.search !== null)
        queryParams += (queryParams ? '&' : '?') + `search=${params.search}`;
    if (params?.statuses)
        queryParams += (queryParams ? '&' : '?') + `statuses=${JSON.stringify(params.statuses)}`;
    if (params?.docType)
        queryParams += (queryParams ? '&' : '?') + `docType=${JSON.stringify(params.docType)}`;
    if (params?.createdAtStart)
        queryParams += (queryParams ? '&' : '?') + `createdAtStart=${params.createdAtStart}`;
    if (params?.createdAtEnd)
        queryParams += (queryParams ? '&' : '?') + `createdAtEnd=${params.createdAtEnd}`;
    if (params?.updatedAtStart)
        queryParams += (queryParams ? '&' : '?') + `updatedAtStart=${params.updatedAtStart}`;
    if (params?.updatedAtStart)
        queryParams += (queryParams ? '&' : '?') + `updatedAtStart=${params.updatedAtStart}`;
    if (params?.deadlineStart)
        queryParams += (queryParams ? '&' : '?') + `deadlineStart=${params.deadlineStart}`;
    if (params?.deadlineEnd)
        queryParams += (queryParams ? '&' : '?') + `deadlineEnd=${params.deadlineEnd}`;

    return queryParams;
}
