<template>
  <spinner v-if="isLoadingData"/>

  <div v-if="!isLoadingData" class="grid grid-cols-2 gap-7 px-7 pt-7">
    <div class="col-span-1 mb-4 xl:mb-2">

      <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <div class="p-7 pb-2 border-b border-gray-200">
                  <form action="">
                    <table class="mb-4">
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Идентификатор:</td>
                        <td class="pb-3 text-sm">{{ id }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Наименование (RU):</td>
                        <td class="pb-3 text-sm">{{ nameRu }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Наименование (EN):</td>
                        <td class="pb-3 text-sm">{{ nameEn || '-' }}</td>
                      </tr>
                      <tr>
                        <td class="pb-3 font-medium text-basic-gray pr-12">Порядок сортировки:</td>
                        <td class="pb-3 text-sm">{{ sortValue || '-' }}</td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import Spinner from "../../../../flowbite/spinner.vue";
import {useRoute, useRouter} from 'vue-router'
import {serviceEventTypeFindById} from "../../../../../service/lists/serviceListEventType.js";

const route = useRoute();
const router = useRouter();

const isLoadingData = ref(true);

const id = ref(null);
const nameRu = ref(null);
const nameEn = ref(null);
const sortValue = ref(null);

if (route.params.id) {
  serviceEventTypeFindById(route.params.id).then(response => {
    isLoadingData.value = false;

    if (response.success && response.data) {
      const data = response.data;

      nameRu.value = data.titleRu;
      nameEn.value = data.titleEn;
      id.value = data.id;
      sortValue.value = data.sort;
    }
  });
}

</script>
