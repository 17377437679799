export function validateSlider(items) {
    const result = {
        items: items,
        isError: false,
        messageEmpty: null,
    };

    items?.map(slider => {
        if (Array.isArray(slider.image)) {
            slider.image = slider.image[0];
        }

        if (slider.image?.name?.length)
            slider.imageError = '';
        else if (slider.image?.length)
            slider.imageError = '';
        else {
            slider.imageError = 'Вы не выбрали аудиофайл';
            result.isError = true;
        }
    });

    result.items = items;

    return result;
}
