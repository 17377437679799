<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">

    <div
        v-if="saveMessageResponse"
        style="z-index: 100; top: 80px"
        id="toast-top-right"
        :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
        class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
        role="alert">
      <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
    </div>

    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                        {{ entityStore.error }}
                      </div>

                      <div v-if="isEntitySaved"
                           class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                           role="alert">
                        {{ $t('common.event') }}
                      </div>

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <input-text v-model="name" :label="$t('document.documentName') + ':'"/>
                          <p v-if="nameError" class="mt-2 text-sm text-red-600"> {{ nameError }} </p>
                          <div class="user-contact-info mt-2">
                            <span class="phone text-gray-400 text-sm font-medium mr-6">{{ user?.name ? user?.name : '' }}</span>
                            <span class="phone text-gray-400 text-sm font-medium mr-6">{{ user?.phone ? '+' + user?.phone : '' }}</span>
                            <span class="email text-gray-400 text-sm font-medium">{{ user?.email }}</span>
                          </div>

                          <div
                              v-if="contour"
                              class="flex mt-6 mb-4">
                            <label class="block mb-2 mr-1 font-medium text-basic-gray">{{ $t('common.contour') }}:</label>
                            <div
                                class="text-black focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium"
                            >
                              {{ contour?.name || '-' }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="grid gap-4 mb-5 sm:grid-cols-2">
                        <div class="sm:col-span-2">
                          <input-textarea
                              class="placeholder-gray-100"
                              placeholder="Описание документа"
                              v-model="description"
                              :label="$t('document.description') + ':'"
                              :readonly="!canEditAttribute('description')" />
                        </div>
                      </div>

                      <hr class="mb-4 mb-2">

                      <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                        <input
                            id="bordered-checkbox-1"
                            type="checkbox"
                            v-model="isInternationalParticipation"
                            name="bordered-checkbox"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <div class="ml-2 text-sm">
                          <label for="bordered-checkbox-1" class="font-medium text-basic-gray dark:text-gray-300">Международное участие</label>
                          <p
                              id="helper-checkbox-text"
                              class="text-xs font-normal text-gray-500 dark:text-gray-300">
                            {{ isInternationalParticipation ? 'Предполагается международное участие' : 'Не предполагается международное участие' }}
                          </p>
                        </div>
                      </div>

                      <div class="mb-5" v-if="checkRole(['admin', 'manager'])">
                        <label class="block mb-2 font-medium text-basic-gray">Выберите контур:</label>
                        <Multiselect
                            v-model="selectedContours"
                            :options="contours"
                            :mode="'tags'"
                            :valueProp="'id'"
                            max="1"
                            track-by="name"
                            label="name"
                            :close-on-select="false"
                            :searchable="true" />
                        <p v-if="docSelectedTypesError" class="mt-2 text-sm text-red-600"> {{ docSelectedTypesError }} </p>
                      </div>

                      <div class="mb-5">
                        <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.chooseDocument') }}:</label>
                        <Multiselect
                            v-model="entityStore.entity.doc_types"
                            :options="docTypes"
                            :mode="'tags'"
                            :valueProp="'id'"
                            track-by="name"
                            label="name"
                            :close-on-select="false"
                            :searchable="true" />
                        <p v-if="docSelectedTypesError" class="mt-2 text-sm text-red-600"> {{ docSelectedTypesError }} </p>
                      </div>

                      <div class="grid gap-4 mb-4 sm:grid-cols-2 pt-5 pb-3">
                        <template v-if="canEditAttribute('exhibitors') && checkRole(['admin', 'manager'])">
                          <div class="sm:col-span-1">
                            <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.exhibitorType') }}:</label>
                            <Multiselect
                                v-model="entityStore.entity.draft_exhibitor_type_id"
                                :options="exhibitorTypes?.filter(item => item.id !== -1)"
                                max="1"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="name"
                                label="name"
                                :close-on-select="false"
                                :searchable="true"
                            />
                            <p v-if="exhibitorTypesError" class="mb-2 mt-2 text-sm text-red-600"> {{ exhibitorTypesError }} </p>
                          </div>
                          <div class="sm:col-span-1">
                            <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.exhibitor') }}:</label>
                            <Multiselect
                                v-model="entityStore.entity.draft_exhibitors"
                                :options="filteredExhibitors?.filter(item => item.id !== -1)"
                                max="1"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="name"
                                label="name"
                                :close-on-select="false"
                                :searchable="true"
                            />
                            <p v-if="filteredExhibitorsError" class="mb-2 mt-2 text-sm text-red-600"> {{ filteredExhibitorsError }} </p>
                          </div>
                        </template>
                      </div>
                      <div>
                        <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.venue') }}:</label>
                        <Multiselect
                            v-model="selectedVenue"
                            :options="venues"
                            :mode="'tags'"
                            :valueProp="'id'"
                            track-by="name"
                            label="name"
                            max="1"
                            :close-on-select="false"
                            :searchable="true" />
                        <p v-if="selectedVenueError" class="mt-2 text-sm text-red-600"> {{ selectedVenueError }} </p>
                      </div>

                      <div class="pt-5 pb-3">
                        <input-text
                            v-model="responsiblePerson"
                            :label="'Ответственное лицо'" />
                        <p v-if="responsiblePersonError" class="mt-2 text-sm text-red-600"> {{ responsiblePersonError }} </p>
                      </div>
                      <div class="grid gap-4 mb-4 sm:grid-cols-3 pt-5 pb-3">
                        <div>
                          <input-text
                              v-model="numberPersons"
                              :label="'Количество человек'" />
                          <p v-if="numberPersonsError" class="mt-2 text-sm text-red-600"> {{ numberPersonsError }} </p>
                        </div>

                        <div>
                          <span class="title text-gray-400">Дата и время начала</span>
                          <VueDatePicker
                              class="mt-2"
                              v-model="dateStart"
                              locale="ru"
                              model-type="yyyy-MM-dd HH:mm"
                              :format="'dd.MM.yyyy HH:mm'"
                              auto-apply
                              :enable-time-picker="true" />
                          <p v-if="dateStartError" class="mt-2 text-sm text-red-600"> {{ dateStartError }} </p>
                        </div>

                        <div>
                          <span class="title text-gray-400">Дата и время окончание</span>
                          <VueDatePicker
                              class="mt-2"
                              v-model="dateEnd"
                              locale="ru"
                              model-type="yyyy-MM-dd HH:mm"
                              :format="'dd.MM.yyyy HH:mm'"
                              auto-apply
                              :enable-time-picker="true" />
                          <p v-if="dateEndError" class="mt-2 text-sm text-red-600"> {{ dateEndError }} </p>
                        </div>
                      </div>

                      <div class="link-site-event" style="margin-bottom: 35px;">
                        <label class="mb-2 mr-1 font-medium text-basic-gray">Форма мероприятия: {{ eventForm ? '' : '-' }}</label>
                        <a
                            @click="onHandleClickDownloadFile(eventForm)"
                            v-if="eventForm"
                            :href="eventForm"
                            class="bg-gray-200 rounded-xl cursor-pointer p-2">
                          Ссылка на файл
                        </a>
                      </div>

                      <div v-for="fileBlock in entityStore.entity.files">
                        <div class="grid gap-4 mb-4 sm:grid-cols-2 p-4" :class="{'bg-[#effbe3] border-[#effbe3]': entityStore.entity.doc_types_agreed.includes(fileBlock.doc_type), 'border': true}">
                          <div class="sm:col-span-1">
                            <div class="flex items-center mb-2">
                              <input v-if="entityStore.entity.type === 1 && checkRole(['admin', 'manager'])" :id="'doc-type-files-' + fileBlock.doc_type" v-model="entityStore.entity.doc_types_agreed" :value="fileBlock.doc_type" type="checkbox" class="w-4 h-4 bg-gray-50 mr-2 rounded border-gray-300 focus:ring-3 checked:bg-primary focus:ring-primary-300">
                              <label :for="'doc-type-files-' + fileBlock.doc_type" class="block font-medium text-basic-gray">{{ $t('document.files') }} «{{ documentTypeListAll.find(documentType => documentType.id === fileBlock.doc_type)?.name }}»:</label>
                            </div>

                            <div v-if="fileBlock.files.length" class="mt-4">
                              <div v-for="file in fileBlock.files" class="mb-3">
                                <div class="flex">
                                  <a :href="file.src" target="_blank" class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4 hover:text-blue-600" :title="file.original_name">{{ file.original_name }}<div class="text-xs">{{ moment(file.created_at).format('DD.MM.YYYY HH:mm') }}</div></a>
                                  <span class="text-xs text-gray-400 mr-2">{{ humanFileSize(file.size) }}</span>
                                  <button @click.prevent="deleteFile(file.id)" type="button" class="hover:opacity-60 h-5" v-if="canEditAttribute('file_delete')">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="sm:col-span-1" v-if="!docTypesAgreed.includes(fileBlock.doc_type) && canEditAttribute('file_upload')">
                            <div class="sm:col-span-2">
                              <drop-file
                                  v-model="fileBlock.newFiles"
                                  :type="documentTypeListAll.find(documentType => documentType.id === fileBlock.doc_type)"/>
                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                  <div class="p-7 flex justify-between">
                    <div>
                      <div class="flex flex-wrap">
                        <div class="flex items-center mb-4">
                          <label @click.prevent="onHandleClickCreate"
                                 :class="checkRole(['admin', 'manager']) ? 'bg-status-6' : 'bg-status-5'"
                                 class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                            <span>{{ checkRole(['admin', 'manager']) ? 'Согласовать' : 'Отправить организатору'}}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center p-7 border-b border-gray-200">
            <div class="text-3xl font-medium">
              {{ $t('document.remarksTitle') }}
            </div>
          </div>
          <div class="p-7 pt-4">
            <div class="flex-1 justify-between flex flex-col h-[50vh]">
              <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                <div class="chat-message" v-for="(message, index) in messages">
                  <div class="flex items-end justify-end">
                    <div style="position: relative" class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                      <div>
                        <div
                            @click="onHandleClickDeleteMessage(index)"
                            style="position: absolute; right: 10px; top: 10px; font-size: 12px; cursor: pointer"
                            class="icon-delete">
                          <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                        </div>
                        <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                          <div class="text-xs">
                            {{ message.user.name }}
                          </div>
                          <div class="text-xs">
                            {{ message.createdAt }}
                          </div>
                          <div class="text-sm">
                            {{ message.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                <div class="relative flex">
                    <textarea
                        :placeholder="$t('chat.write_message')"
                        v-model="messageData"
                        rows="5"
                        class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                    ></textarea>
                </div>
                <div v-if="checkRole(['admin', 'manager'])" class="items-center mt-2">
                  <button type="button"
                          @click.prevent="sandMessage"
                          class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                    <template v-if="!loadDataMessage">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                      </svg>
                    </template>
                    <template v-else>
                      <svg aria-hidden="true" class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRegistryStore} from "../../../stores/RegistryStore.js";
import {checkRole} from "../../../utilits/functions.js";
import Spinner from "../../flowbite/spinner.vue";
import {computed, reactive, ref, watch, onUpdated} from "vue";
import InputText from "../../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {helpers, required} from "../../../utilits/custom-validators.js";
import InputSelectLite from "../../flowbite/form/inputSelectLite.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../flowbite/form/inputTextarea.vue";
import DropFile from "../../flowbite/form/dropFile.vue";
import moment from "moment";
import Multiselect from '@vueform/multiselect'
import i18n from "../../../i18n.js";
import {serviceSelectedUserContours, serviceSelectedUserContoursFindId} from "../../../service/user/user.js";
import {serviceCoordinationCreate} from "../../../service/documents/serviceCoordination.js";
import {serviceContourList} from "../../../service/lists/serviceListDocumentType.js";
import {serviceVenueList} from "../../../service/lists/serviceListVenue.js";

const entityStore = useRegistryStore()
entityStore.error = null

const loadData = ref(true)
const isEntitySaved = ref(false)

const statusList = ref([])
const statusListAll = ref([])
const typeList = ref([])
const typeListAllowed = ref([])
const documentTypeList = ref([])
const documentTypeListAll = ref([])
const changeStatusList = ref({})
const createStatusList = ref({})
const exhibitors = ref([]);
const filteredExhibitors = ref([]);
const filteredExhibitorsError = ref('');
const exhibitorTypes = ref([]);
const exhibitorTypesError = ref('');

const contours = ref([]);
const venues = ref([]);
const selectedVenue = ref([]);

// Первоначальные данные
const docTypesAgreed = ref([])

const route = useRoute()
const router = useRouter()

const name = ref('');
const nameError = ref('');

const description = ref('');

const responsiblePerson = ref('');
const responsiblePersonError = ref('');

const numberPersons = ref(null);
const numberPersonsError = ref('');

const eventForm = ref('');

const dateStart = ref(null);
const dateStartError = ref('');

const dateEnd = ref(null);
const dateEndError = ref('');

const docSelectedTypesError = ref('');

const messages = ref([]);
const messageData = ref('');
const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;
const saveMessageResponse = ref(null);
const docTypes = ref([]);
const files = ref([]);
const selectedContours = ref([]);
const selectedVenueError = ref(null);
const contour = ref(null);

const isInternationalParticipation = ref(false);

const onHandleClickDownloadFile = (url) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', '');

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

const userContours = () => {
  if (checkRole(['admin', 'manager'])) {
    serviceSelectedUserContours(route.params.id).then(response => {
      const items = response?.data || [];

      if (items.length) {
        items?.map((type, index) => {
          type?.userDocTypes?.map(item => {
            if (item.key === 'event') {
              contours.value.push(items[index]);
            }
          });
        });
      }
    });
  }
  else {
    serviceSelectedUserContoursFindId(route.params.contour).then(response => {
      contour.value = response.data || null;

      if (contour.value) {
        eventForm.value = contour.value.downloadUrl;

        if (contour.value?.id) {
          documentContourList({contourId: contour.value?.id});
        }
      }
    });
  }
}

userContours();

serviceVenueList().then(response => {
  venues.value = response.data;
});

const documentContourList = (params) => {
  serviceContourList(params).then(response => {
    docTypes.value = response?.data || [];
  });
}

const sandMessage = () => {
  messages.value.push({
    user: user,
    message: messageData.value,
    createdAt: moment().format('DD.MM.YYYY HH:mm'),
  });

  messageData.value = '';
}

const onHandleClickDeleteMessage = (index) => {
  messages.value.splice(index, 1);
}

const onHandleClickCreate = () => {
  nameError.value = !name.value?.length ? 'Не должно быть пустым' : '';
  responsiblePersonError.value = !responsiblePerson.value?.length ? 'Не должно быть пустое' : '';
  numberPersonsError.value = !numberPersons.value ? 'Не должно быть пустым' : '';
  selectedVenueError.value = !selectedVenue.value?.length ? 'Вы не выбрали площадку' : '';
  docSelectedTypesError.value = !entityStore.entity.doc_types?.length ? 'Вы не выбрали тип документа' : '';

  exhibitorTypesError.value = !entityStore.entity.draft_exhibitor_type_id?.length && checkRole(['admin', 'manager']) ? 'Вы не выбрали "Тип экспонента(ов)"' : '';
  filteredExhibitorsError.value = !entityStore.entity.draft_exhibitors?.length && checkRole(['admin', 'manager']) ? 'Вы не выбрали "Экспонента(ов)"' : '';

  const contourValue = checkRole(['admin', 'manager']) ? (selectedContours.value ? selectedContours.value[0] : null) : contour.value?.id;

  checkedDate();

  let counterFileBlock = 0;
  entityStore.entity.files?.map(fileBlock => {
    if (fileBlock?.newFiles?.length)
      counterFileBlock++;
  });

  if (nameError.value || responsiblePersonError.value || numberPersonsError.value ||
      dateStartError.value || dateEndError.value || selectedVenueError.value || docSelectedTypesError.value ||
      !contourValue || exhibitorTypesError.value || filteredExhibitorsError.value || (entityStore.entity.doc_types?.length !== counterFileBlock)) {

    return null;
  }

  serviceCoordinationCreate({
    name: name.value,
    description: description.value,
    responsiblePerson: responsiblePerson.value,
    numberPersons: numberPersons.value,
    eventForm: eventForm.value,
    dateStart: dateStart.value,
    dateEnd: dateEnd.value,
    messages: messageData.value ? [messageData.value] : [],
    files: entityStore.entity.files,
    venue: selectedVenue.value,
    contourId: contourValue,
    exhibitorTypes: entityStore.entity.draft_exhibitor_type_id,
    exhibitors: entityStore.entity.draft_exhibitors,
    isInternationalParticipation: isInternationalParticipation.value,
  }).then(response => {
    const status = response?.success;
    const message = status ? 'Мероприятие успешно создано' : 'Ошибка, мероприятие не было создано';

    saveMessageResponse.value = {
      message,
      status,
    };

    if (response?.data?.id)
      router.push({ name: 'CoordinationEdit', params: { id: response?.data?.id } })

    setTimeout(() => {
      saveMessageResponse.value = null;
    }, 3000);
  });
}

watch(selectedContours, () => {
  if (!selectedContours.value?.length) {
    eventForm.value = null;

    return null;
  }

  const contour = selectedContours.value?.length ? contours.value?.find(item => item.id === selectedContours.value[0]) : null;
  if (contour && contour?.file) {
    eventForm.value = contour.downloadUrl;
  }

  if (contour) {
    documentContourList({contourId: contour?.id});
  }
});


entityStore.getDirectories().then(() => {
  statusList.value = [...statusList.value, ...entityStore.statusListAll]
  statusListAll.value = [...statusListAll.value, ...entityStore.statusListAll]
  documentTypeList.value = [...documentTypeList.value, ...entityStore.documentTypeList]
  documentTypeListAll.value = [...documentTypeListAll.value, ...entityStore.documentTypeListAll]
  typeList.value = [...typeList.value, ...entityStore.typeList]
  typeListAllowed.value = [...typeListAllowed.value, ...entityStore.typeListAllowed]
  changeStatusList.value = entityStore.changeStatusList
  createStatusList.value = entityStore.createStatusList
  exhibitorTypes.value = entityStore.exhibitorTypes

  exhibitors.value.push({
    id: -1,
    name: i18n.global.t('document.chooseAll'),
    type_id: 0
  })
  exhibitors.value = [...exhibitors.value, ...entityStore.exhibitors]

  filteredExhibitors.value = exhibitors.value

  if (route.params.id > 0) {
    entityStore.getEntityById(route.params.id).then(() => {
      loadData.value = false

      setAvailableStatusList()

      docTypesAgreed.value = entityStore.entity.doc_types_agreed
    })
  } else {
    entityStore.setEmptyEntity().then(() => {
      loadData.value = false

      statusList.value = statusList.value.filter(status => createStatusList.value.includes(status.id))

      entityStore.entity.status = statusList.value[0]?.id
      entityStore.entity.type = typeListAllowed.value[0]?.id

      if (exhibitorTypes.value.length === 1) {
        entityStore.entity.draft_exhibitor_type_id.push(exhibitorTypes.value[0]?.id)
      }
    })
  }
})

onUpdated(() => {
  setChatScroll()
})

// Валидация
const exhibitorIdRule = checkRole(['admin', 'manager']) ? {
  exhibitorRequired: helpers.withMessage(i18n.global.t('common.validation.chooseExponent'), () => {
    return !canEditAttribute('exhibitors') || entityStore.entity.draft_exhibitors.length
  })
} : {}

const filesRule = {
  filesRule: helpers.withMessage(i18n.global.t('common.validation.uploadFiles'), () => {
    return !entityStore.entity.files.filter((files) => {
      return !files.files.length && !files.newFiles.length
    }).length
  })
}

const docTypesRule = {
  exhibitorRequired: helpers.withMessage(i18n.global.t('common.validation.required'), () => {
    if (!canEditAttribute('doc_types') || entityStore.entity.type === 2) {
      return true
    }

    return entityStore.entity.doc_types.length
  })
}

const rules = computed(() => ({
  name: {required},
  type: {required},
  doc_types: docTypesRule,
  status: {required},
  draft_exhibitors: exhibitorIdRule,
  files: filesRule,
}));

let state = reactive({
  name: '',
  type: '',
  doc_types: [],
  status: '',
  exhibitor_id: '',
  description: '',
  remarks: '',
  deadline: '',
  files: [],
  exhibitors: [],
});

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.type = entityStore.entity.type;
    state.doc_types = entityStore.entity.doc_types;
    state.status = entityStore.entity.status;
    state.exhibitor_id = entityStore.entity.exhibitor_id;
    state.description	= entityStore.entity.description;
    state.remarks	= entityStore.entity.remarks;
    state.deadline	= entityStore.entity.deadline;
    state.files	= entityStore.entity.files;

    if (entityStore.entity.doc_types !== undefined) {
      createFileBlocks()
    }
  }
});

watch([dateStart, dateEnd], () => {
  checkedDate();
})

const checkedDate = () => {
  if (!dateStart.value) {
    dateStartError.value = 'Дата начала не может быть пустой';
  }
  else if (dateEnd.value && dateStart.value > dateEnd.value) {
    dateStartError.value = 'Дата начала не может быть позже даты окончания';
  }
  else {
    dateStartError.value = '';
  }

  if (!dateEnd.value) {
    dateEndError.value = 'Дата окончания не может быть пустой';
  }
  else if (dateStart.value && dateEnd.value < dateStart.value) {
    dateEndError.value = 'Дата окончания не может быть раньше даты начала';
  }
  else {
    dateEndError.value = '';
  }
}

const v$ = useVuelidate(rules, state)

// Работа с данными
const createFileBlocks = () => {
  entityStore.entity.doc_types.forEach((doc_type) => {
    if (!entityStore.entity.files.find(item => item.doc_type === doc_type)) {
      entityStore.entity.files.push({
        doc_type: doc_type,
        files: [],
        newFiles: [],
      })
    }
  })

  entityStore.entity.files.forEach((files, index) => {
    if (!entityStore.entity.doc_types.includes(files.doc_type) && !files.files.length) {
      entityStore.entity.files.splice(index, 1)
    }
  })
}

const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

const deleteFile = (fileId) => {
  if (confirm('Вы действительно хотите удалить файл?')) {
    entityStore.deleteFile(fileId).then(() => {
    });
  }
}

const setChatScroll = () => {
  let el = document.getElementById('messages')

  if (el) {
    el.scrollTop = el.scrollHeight
  }
}

const setAvailableStatusList = () => {
  let availableStatusList = changeStatusList.value[entityStore.entity.type][entityStore.entity.status]

  if (typeof availableStatusList === 'undefined') {
    availableStatusList = []
  }

  statusList.value = statusListAll.value.filter(status => availableStatusList.includes(status.id) || status.id === entityStore.entity.status)
}

const canEditAttribute = (attribute) => {
  if (!entityStore.entity.id) {
    return true
  }

  return entityStore.entity.permissions.edit[attribute];
}

const chooseDraftExhibitors = (option) => {
  if (option === -1) {
    entityStore.entity.draft_exhibitors = filteredExhibitors.value.map(item => item.id).filter(item => item !== -1)
  }
}

const filterDraftExhibitorsByType = (exhibitorsTypeIds) => {
  filteredExhibitors.value = exhibitors.value.filter((item) => {
    return item.id === -1 || !exhibitorsTypeIds.length || exhibitorsTypeIds.includes(item.type_id)
  })

  let filteredIds = filteredExhibitors.value.map(item => item.id)

  entityStore.entity.draft_exhibitors = entityStore.entity.draft_exhibitors.filter(id => filteredIds.includes(id))
}

// const sendMessage = () => {
//   entityStore.error = null
//
//   messageEntity.value.message = entityStore.entity.remarks
//
//   if (messageEntity.value.message) {
//     loadDataMessage.value = true
//
//     entityStore.sendMessage(messageEntity.value).then(() => {
//       loadDataMessage.value = false
//       messageEntity.value.message = ''
//       entityStore.entity.remarks = ''
//     })
//   }
// }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>
