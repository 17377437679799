<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-2 gap-7 px-7 pt-7">
      <div class="col-span-1 mb-4 xl:mb-2">
        <div v-if="isEntitySaved" class="w-full p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
          {{ $t('common.documentSaved') }}
        </div>

        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
            <div>
              <h1 class="text-3xl font-medium">{{ $t('common.characteristics') }}</h1>

              <p class="mb-2" v-if="checkRole('admin')">
                <router-link :to="{name: 'UserLog', query: {object: 'App\\Models\\DocType', id: entityStore.entity.id}}" target="_blank" class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">{{ $t('common.toLogs') }}</router-link>
              </p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow">
                  <div v-if="entityStore.entity">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.name" :label="$t('directoryDocuments.name')"/>
                            <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900">{{ $t('directoryDocuments.roles') }}</label>
                            <Multiselect v-model="entityStore.entity.roles" :options="entityStore.roles" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                          </div>
                          <div class="sm:col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900">{{ $t('directoryDocuments.exhibitorType') }}</label>
                            <Multiselect v-model="entityStore.entity.access_exhibitor_type_ids" :options="exhibitorTypes" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                          </div>
                          <div class="sm:col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900">{{ $t('directoryDocuments.extensions') }}</label>
                            <Multiselect v-model="entityStore.entity.extensions" :options="extensions" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                            <p v-if="v$.extensions.$error" class="mt-2 text-sm text-red-600"> {{ v$.extensions.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <label class="block mb-2 font-medium text-basic-gray">{{ $t('common.contour') }}:</label>
                            <Multiselect
                                :max="checkRole(['admin', 'manager']) ? -1 : 1"
                                v-model="selectedContours"
                                :options="contours"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="name"
                                label="name"
                                :close-on-select="false"
                                :searchable="true" />
                            <label
                                @click="onHandleContourSelectedAll"
                                class="block mb-2 font-medium text-basic-gray">
                              {{ isSelectContoursAll ? 'Убрать все' : 'Выбрать все' }}
                            </label>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.max_filesize" :label="$t('directoryDocuments.maxFilesize')"/>
                            <p v-if="v$.max_filesize.$error" class="mt-2 text-sm text-red-600"> {{ v$.max_filesize.$errors[0].$message }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text v-model="entityStore.entity.sort" :label="$t('directoryDocuments.sort')"/>
                            <p v-if="v$.sort.$error" class="mt-2 text-sm text-red-600"> {{ v$.sort.$errors[0].$message }} </p>
                          </div>
                        </div>
                        <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ entityStore.error }}
                        </div>
                      </form>
                    </div>
                    <div class="p-7 flex justify-end">
                      <button @click.prevent="saveEntity" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                        <span>{{ $t('common.save') }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useDocsDirectoryStore} from "../../stores/DocsDirectoryStore";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {helpers, required} from '../../utilits/custom-validators.js'
import Multiselect from '@vueform/multiselect'
import {between} from "@vuelidate/validators";
import i18n from "../../i18n.js";
import {checkRole} from "../../utilits/functions";
import {serviceContourList} from "../../service/lists/serviceListContour.js";
import {serviceSelectedUserContours} from "../../service/user/user.js";
import {serviceDocumentTypContour} from "../../service/lists/serviceSelectedDocumentTypeContour.js";

const entityStore = useDocsDirectoryStore();
entityStore.error = null;

const loadData = ref(true);

const isEntitySaved = ref(false)

// Первоначальные данные
const route = useRoute()
const router = useRouter()

const exhibitorTypes = ref([])
const extensions = ref([])

const contours = ref([]);
const selectedContours = ref([]);
const isSelectContoursAll = ref(false);

serviceContourList().then(response => {
  contours.value = response?.data || [];
});

serviceDocumentTypContour(route.params.id).then(response => {
  const data = response?.data || [];
  selectedContours.value = data.map(item => item.id);
});

const onHandleContourSelectedAll = () => {
  isSelectContoursAll.value = !isSelectContoursAll.value;

  if (isSelectContoursAll.value)
    selectedContours.value = contours.value.map(item => item.id);

  if (!isSelectContoursAll.value)
    selectedContours.value = [];
}

entityStore.getDirectories().then(() => {
  exhibitorTypes.value = entityStore.exhibitorTypes
  extensions.value = entityStore.extensions

  if (route.params.id > 0) {
    entityStore.getEntityById(route.params.id).then(() => {
      loadData.value = false
    })
  } else {
    entityStore.setEmptyEntity().then(() => {
      loadData.value = false;

      if (exhibitorTypes.value.length === 1) {
        entityStore.entity.access_exhibitor_type_ids.push(exhibitorTypes.value[0]?.id)
      }
    })
  }
})

// Валидация
const rules = computed(() => ({
  name: {required},
  sort: {required},
  roles: {required},
  extensions: {required},
  max_filesize: {required, between: helpers.withMessage(i18n.global.t('common.validation.between', {min: 1, max: 1024}), between(1, 1024))},
}));

let state = reactive({
  name: '',
  roles: [],
  extensions: [],
  max_filesize: '',
  sort: '',
});

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.roles = entityStore.entity.roles;
    state.sort = entityStore.entity.sort;
    state.max_filesize = entityStore.entity.max_filesize;
    state.extensions = entityStore.entity.extensions;
  }
});

const v$ = useVuelidate(rules, state)

// Работа с данными
const saveEntity = () => {
  entityStore.error = null;

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true;

    let isNew = entityStore.entity.id === undefined;

    entityStore.entity.selectedContours = selectedContours.value;

    entityStore.storeEntity(entityStore.entity).then(() => {
      if (!entityStore.error && isNew) {
        router.push({name: 'DocsDirectoryEdit', params: {id: entityStore.entity.id}})
      }

      if (!entityStore.error) {
        isEntitySaved.value = true

        setTimeout(() => {
          isEntitySaved.value = false
        }, 4000)
      }

      loadData.value = false;
    });

    v$.value.$reset();
  }
}
</script>
