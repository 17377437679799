import * as validators from '@vuelidate/validators'
import {createI18nMessage} from '@vuelidate/validators'
import i18n from '../i18n.js';

const withI18nMessage = createI18nMessage({t: i18n.global.t.bind(i18n)});


const selectInList = (param) => (value) => {
    let validate = false;

    value.forEach(function (item) {
        if (item[param]) {
            validate = true;
        }
    });

    return validate;
};


export const required = withI18nMessage(validators.required)
export const between = withI18nMessage(validators.between)
export const minLength = withI18nMessage(validators.minLength, {withArguments: true})
export const maxLength = withI18nMessage(validators.maxLength, {withArguments: true})
export const email = withI18nMessage(validators.email, {withArguments: true})
export const sameAs = withI18nMessage(validators.sameAs, {withArguments: true})
export const helpers = validators.helpers
export {selectInList};