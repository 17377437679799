import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceSendMessage(id, data) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/documents/coordinations/${id}/send-message` , {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data),
    }));
}
