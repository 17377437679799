import axios from "axios";
import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API;

export async function serviceDocumentList(data) {
    const token = await getToken();

    return await axios.get(`${url}/exhibitors/docs?contours=` + JSON.stringify(data.contours), data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data?.data;

            return {
                success: true,
                data: response?.entities,
                pagination: response?.pagination,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

// Просмотр нового документа (только для мероприятия)
export async function serviceDocumentView(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${import.meta.env.VITE_BASE_URL_API}/v1/documents/document/${id}/view`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}
