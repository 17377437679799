import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';
import {checkRole} from "../utilits/functions.js";

export const useRegistryStore = defineStore('registryStore', () => {
    const exhibitors = ref([]);
    const statusList = ref([]);
    const statusListAll = ref([]);
    const documentTypeList = ref([]);
    const documentTypeListAll = ref([]);
    const exhibitorTypes = ref([]);
    const changeStatusList = ref({});
    const createStatusList = ref({});
    const typeList = ref([]);
    const typeListAllowed = ref([]);
    const federalDistricts = ref([]);
    const entities = ref([]);
    const entity = ref(null);
    const pagination = ref(null);
    const error = ref(null);
    const contour = ref(null);

    const getEntities = async (params = null, url = '/exhibitors/docs') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    }

    const getEntityById = async (id) => {
        await axios.get('/exhibitors/docs/' + id)
            .then((r) => {
                const data = r.data.data;
                entity.value = data;

                entity.value.typeAndProgress = checkRole(['exhibitor'])
                    ? (data?.typeAndProgress?.process?.exp || [])
                    : (data?.typeAndProgress?.process?.org || []);

            }).catch((e) => {
                console.log(e);
            })
    }

    const storeEntity = async (data) => {
        await axios.post('/exhibitors/docs/store', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    const data = r.data.data;
                    entity.value = data;

                    entity.value.typeAndProgress = checkRole(['exhibitor'])
                        ? (data?.typeAndProgress?.process?.exp || [])
                        : (data?.typeAndProgress?.process?.org || []);

                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const deleteEntities = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/exhibitors/docs', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const setEmptyEntity = async () => {
        entity.value = {
            name: '',
            type: '',
            doc_type: '',
            status: '',
            exhibitor_id: '',
            description: '',
            remarks: '',
            deadline: '',
            newFiles: [],
            files: [],
            exhibitors: [],
            doc_types_info: [],
            doc_types: [],
            doc_types_agreed: [],
            messages: [],
            draft_exhibitor_type_id: [],
            draft_exhibitors: [],
            listDocumentTypeId: null,
            dateStart: null,
            dateEnd: null,
            responsiblePerson: null,
            numberPersons: null,
            eventForm: null,
            contour: null,
            typeAndProgress: null,
        }
    };

    const resetEntity = async () => {
        entity.value = null;
    }

    const restoreEntities = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/exhibitors/docs/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const getDirectories = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/exhibitors/docs/directories', config)
            .then((r) => {
                exhibitors.value = r.data.data.exhibitors;
                statusList.value = r.data.data.statusList;
                statusListAll.value = r.data.data.statusListAll;
                typeList.value = r.data.data.typeList;
                exhibitorTypes.value = r.data.data.exhibitorTypes;
                typeListAllowed.value = r.data.data.typeListAllowed;
                federalDistricts.value = r.data.data.federalDistricts;
                documentTypeList.value = r.data.data.documentTypeList;
                documentTypeListAll.value = r.data.data.documentTypeListAll;
                changeStatusList.value = r.data.data.changeStatusList;
                createStatusList.value = r.data.data.createStatusList;
                contour.value = r.data.contour;
            }).catch((e) => {
                console.log(e);
            })
    }

    const deleteFile = async (fileId) => {
        await axios.post('/exhibitors/docs/delete-file/' + fileId)
            .then((r) => {
                entity.value.files = r.data.data.files
            }).catch((e) => {
                console.log(e);
            })
    }

    const sendMessage = async (message) => {
        await axios.post('/exhibitors/docs/send-message', message)
            .then((r) => {
                entity.value.messages = r.data.data.messages
            }).catch((e) => {
                console.log(e);
            })
    }

    return {
        pagination,
        exhibitors,
        statusList,
        statusListAll,
        documentTypeList,
        documentTypeListAll,
        changeStatusList,
        createStatusList,
        typeList,
        typeListAllowed,
        federalDistricts,
        exhibitorTypes,
        contour,
        entities,
        entity,
        error,
        sendMessage,
        setEmptyEntity,
        getEntities,
        getEntityById,
        storeEntity,
        deleteEntities,
        resetEntity,
        restoreEntities,
        getDirectories,
        deleteFile,
    }
})
