import {getToken, handleAsync} from "../../handleAsync.js";
import axios from "axios";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

function queryParams(params) {
    let query = '';

    if (params?.status) {
        query = '?status=' + params.status;
    }
    if (params?.exhibitor) {
        query += (!query.length ? ('?') : ('&')) + ('exhibitor=' + params.exhibitor);
    }
    if (params?.createdAt) {
        query += (!query.length ? ('?') : ('&')) + ('createdAt=' + params.createdAt);
    }
    if (params?.isScheduleCloseNowDate) {
        query += (!query.length ? ('?') : ('&')) + ('isScheduleCloseNowDate=' + params.isScheduleCloseNowDate);
    }
    if (params?.search) {
        query += (!query.length ? ('?') : ('&')) + ('search=' + params.search);
    }

    if (params?.updatedAt) {
        query += (!query.length ? ('?') : ('&')) + ('updatedAt=' + params.updatedAt);
    }

    if (params?.participant) {
        query += (!query.length ? ('?') : ('&')) + ('participant=' + params.participant);
    }

    if (params?.page) {
        query += (!query.length ? ('?') : ('&')) + ('page=' + params.page);
    }

    return query;
}

export async function serviceContentEventList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events` + queryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventFindById(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventSessionDelete(eventId, sessionId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${eventId}/sessions/${sessionId}/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventMultiDelete(ids) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/multi-delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({ids})
    }));
}

export async function serviceContentEventHasExternal(eventId, externalId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/has-external/` + eventId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({externalId})
    }));
}

export async function serviceContentEventCreate(data) {
    const token = await getToken();

    if (data.sliders.length) {
        data.sliders.map(item => {
            let image = item.image;
            if (item.image?.length)
                item.image = image[0];
        });
    }

    if (data.routePoints.length) {
        data.routePoints.map(item => {
            if (!item.id) {
                const image = item.image;
                if (item.image?.length)
                    item.image = image[0];

                const audio = item.audio;
                if (item.audio?.length)
                    item.audio = audio[0];
            }
        });
    }

    if (data.eventArtists.length) {
        data.eventArtists.map(item => {
            if (!item?.id) {
                const image = item.image;
                if (item.image?.length)
                    item.image = image[0];
            }
        });
    }

    if (data.partners.length) {
        data.partners.map(item => {
            if (!item.id) {
                const image = item.image;
                if (item.image?.length)
                    item.image = image[0];

                // if (item.isActiveRu === 'true')
                //     item.isActiveRu = 1;
                // else if (item.isActiveRu === 'false')
                //     item.isActiveRu = 0;
                // else item.isActiveRu = Number(item.isActiveRu);
                //
                // if (item.isActiveEn === 'true')
                //     item.isActiveEn = 1;
                // else if (item.isActiveEn === 'false')
                //     item.isActiveEn = 0;
                // else item.isActiveEn = Number(item.isActiveEn);
            }
        });
    }

    if (data.eventSpeakers.length) {
        data.eventSpeakers.map(item => {
            if (!item.id) {
                const image = item.image;
                if (item.image?.length)
                    item.image = image[0];
            }
        });
    }

    if (data.eventModerators.length) {
        data.eventModerators.map(item => {
            if (!item.id) {
                const image = item.image;
                if (item.image?.length)
                    item.image = image[0];
            }
        });
    }

    if (data.eventLecturers.length) {
        data.eventLecturers.map(item => {
            if (!item.id) {
                const image = item.image;
                if (item.image?.length)
                    item.image = image[0];
            }
        });
    }

    return await axios.post(`${url}/contents/events/create`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r?.data?.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e?.response?.data;

            return {
                success: false,
                errors: response?.errors || [],
                onlyErrors: response?.onlyErrors || [],
            };
        });
}

export async function serviceContentEventUpdate(id, data) {
    const token = await getToken();

    if (data.sliders?.length) {
        data.sliders?.map(item => {
            let image = item.image;
            if (Array.isArray(image)) {
                item.image = item.image[0];
            }
        });
    }

    if (data.routePoints?.length) {
        data.routePoints?.map(item => {
            let image = item.image;
            if (Array.isArray(image))
                item.image = image[0];

            let audio = item.audio;
            if (Array.isArray(audio))
                item.audio = audio[0];
        });
    }

    if (data.eventArtists?.length) {
        data.eventArtists?.map(item => {
            let image = item.image;
            if (Array.isArray(image))
                item.image = image[0];
        });
    }

    if (data.partners?.length) {
        data.partners?.map(item => {
            let image = item.image;
            if (Array.isArray(image))
                item.image = image[0];
        });
    }

    if (data.eventSpeakers?.length) {
        data.eventSpeakers?.map(item => {
            let image = item.image;
            if (Array.isArray(image))
                item.image = image[0];
        });
    }

    if (data.eventModerators?.length) {
        data.eventModerators?.map(item => {
            let image = item.image;
            if (Array.isArray(image))
                item.image = image[0];
        });
    }

    if (data.eventLecturers?.length) {
        data.eventLecturers?.map(item => {
            let image = item.image;
            if (Array.isArray(image))
                item.image = image[0];
        });
    }

    return await axios.post(`${url}/contents/events/${id}/edit`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r?.data?.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e?.response?.data;

            return {
                success: false,
                errors: response?.errors || [],
                onlyErrors: response?.onlyErrors || [],
            };
        });
}

export async function serviceContentEventSliderDelete(id, sliderId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/sliders/${sliderId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventMessageCreate(id, params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/message/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    }));
}

export async function serviceContentEventTabCardDelete(id, cardId, action) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/lists/${action}/${cardId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventTabSpeakerDelete(id, valueId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/lists/speakers/${valueId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventTabOrganizerDelete(id, valueId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/lists/organizers/${valueId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventTabArtistDelete(id, valueId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/lists/artists/${valueId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventTabLanguageDelete(id, valueId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/lists/languages/${valueId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventTabPartnerDelete(id, valueId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/lists/partners/${valueId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentEventTabPointRouteDelete(id, valueId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/events/${id}/lists/point-routes/${valueId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}
