import {
    validateTimeRangeEndToStart,
    validateTimeRangeStartToEnd
} from "./helperValidateDate.js";

export function validateSession(items, itemGenerates) {
    const result = {
        items: items,
        isError: false,
        messageSessionEmpty: null,
    };

    if (!itemGenerates.length && !items?.length) {
        return {
            items,
            isError: true,
            messageSessionEmpty: 'Добавьте хотя-бы одну сессию',
        };
    }
    else result.messageSessionEmpty = '';

    items?.map(item => {
        item.nameError = !item.name?.length ? 'Обязательна для заполнения' : '';
        item.dateStartError = !item.dateStart ? 'Обязательна для заполнения' : '';
        item.timeStartError = !item.timeStart ? 'Обязательна для заполнения' : '';
        item.timeEndError = !item.timeEnd ? 'Обязательна для заполнения' : '';
        item.datePlanOpenError = !item.datePlanOpen ? 'Обязательна для заполнения' : '';

        const datetimeStart = item.dateStart + ' ' + item.timeStart;

        const timeError = validateTimeRangeEndToStart(item.timeStart, item.timeEnd, 'Время начала не может быть позже времени окончания', 'Время окончания не может быть раньше времени начала');
        if (!item.timeStartError?.length) {
            item.timeStartError = timeError.timeStartError;
            if (item.timeStartError?.length)
                result.isError = true;
        }
        if (!item.timeEndError?.length) {
            item.timeEndError = timeError.timeEndError;
            if (item.timeEndError?.length) {
                result.isError = true;
            }
        }

        const dateError = validateTimeRangeStartToEnd(datetimeStart, item.datePlanOpen, 'Дата начала не может быть раньше даты планового открытия', 'Дата планового открытия не может быть больше даты начала');
        if (!item.dateStartError?.length) {
            item.dateStartError = dateError.timeStartError;
            if (item.dateStartError?.length)
                result.isError = true;
        }
        if (!item.datePlanOpenError?.length) {
            item.datePlanOpenError = dateError.timeEndError;
            if (item.datePlanOpenError?.length)
                result.isError = true;
        }

        if (item.registrationOffDate) {
            const dateRangeRegistrationDatePlan = validateTimeRangeStartToEnd(item.registrationOffDate, item.datePlanOpen, 'Дата закрытия регистрации не должна быть меньше даты начала и даты планового открытия', '');
            const dateRangeRegistrationDateStart = validateTimeRangeStartToEnd(item.registrationOffDate, datetimeStart, 'Дата закрытия регистрации не должна быть меньше даты начала и даты планового открытия', '');
            const registrationOffDateError = dateRangeRegistrationDatePlan.timeStartError || dateRangeRegistrationDateStart.timeStartError;

            if (!item.registrationOffDateError?.length) {
                item.registrationOffDateError = registrationOffDateError;
                if (item.registrationOffDateError?.length)
                    result.isError = true;
            }

            if (!registrationOffDateError?.length)
                item.registrationOffDateError = '';
        }
        else item.registrationOffDateError = '';

        if (item.nameError || item.dateStartError || item.timeStartError || item.timeEndError || item.dateEndError || item.datePlanOpenError) {
            result.isError = true;
        }
    });

    result.items = items;

    return result;
}

export function validateSessionGenerates(items) {
    const result = {
        item: items,
        isError: false,
        messageSessionEmpty: null,
    };

    items?.map(item => {
        item.nameError = !item.name?.length ? 'Обязательна для заполнения' : '';
        item.dateStartError = !item.dateStart ? 'Обязательна для заполнения' : '';
        item.dateEndError = !item.dateEnd ? 'Обязательна для заполнения' : '';
        item.timeStartError = !item.timeStart ? 'Обязательна для заполнения' : '';
        item.timeEndError = !item.timeEnd ? 'Обязательна для заполнения' : '';
        item.datePlanOpenError = !item.datePlanOpen ? 'Обязательна для заполнения' : '';

        const datetimeStart = item.dateStart + ' ' + item.timeStart;
        const datetimeEnd = item.dateEnd + ' ' + item.timeEnd;

        const dateValStartAndPlan = validateTimeRangeStartToEnd(datetimeStart, item.datePlanOpen, 'Дата начала не может быть меньше даты планового открытия', 'Дата планового открытия не может быть больше даты начала');
        const dateValStartAndEnd = validateTimeRangeEndToStart(datetimeStart, datetimeEnd, 'Дата начала не может быть больше даты окончания', 'Дата окончания не может быть меньше даты начала');

        if (dateValStartAndPlan.timeStartError?.length) {
            item.dateStartError = dateValStartAndPlan.timeStartError;
            if (dateValStartAndPlan.timeStartError)
                result.isError = true;

            if (!dateValStartAndPlan.timeStartError)
                item.dateStartError = '';
        }
        if (dateValStartAndPlan.timeEndError?.length) {
            item.datePlanOpenError = dateValStartAndPlan.timeEndError;
            if (dateValStartAndPlan.timeEndError?.length)
                result.isError = true;

            if (!dateValStartAndPlan.timeEndError)
                item.datePlanOpenError = '';
        }

        if (!item.dateStartError?.length) {
            item.dateStartError = dateValStartAndEnd.timeStartError;
            if (dateValStartAndEnd.timeStartError?.length)
                result.isError = true;

            if (!dateValStartAndEnd.timeStartError?.length)
                item.dateStartError = '';
        }

        if (!item.dateEndError?.length) {
            item.dateEndError = dateValStartAndEnd.timeEndError;
            if (dateValStartAndEnd.timeEndError?.length)
                result.isError = true;

            if (dateValStartAndEnd.timeEndError?.length)
                item.dateEndError = '';
        }

        const timeError = validateTimeRangeEndToStart(item.timeStart, item.timeEnd, 'Время начала не может быть позже времени окончания', 'Время окончания не может быть раньше времени начала');
        if (!item.timeStartError?.length) {
            item.timeStartError = timeError.timeStartError;
            if (timeError.timeStartError?.length)
                result.isError = true;

            if (!timeError.timeStartError)
                item.timeStartError = '';
        }
        if (!item.timeEndError?.length) {
            item.timeEndError = timeError.timeEndError;
            if (timeError.timeEndError?.length)
                result.isError = true;

            if (!timeError.timeEndError)
                item.timeEndError = '';
        }

        const dateError = validateTimeRangeStartToEnd(datetimeStart, item.datePlanOpen, 'Дата начала не может быть раньше даты планового открытия', 'Дата планового открытия не может быть больше даты начала');
        if (!item.dateStartError?.length) {
            item.dateStartError = dateError.timeStartError;
            if (item.dateStartError?.length)
                result.isError = true;

            if (!item.dateStartError)
                item.dateStartError = '';
        }
        if (!item.datePlanOpenError?.length) {
            item.datePlanOpenError = dateError.timeEndError;
            if (dateError.timeEndError?.length)
                result.isError = true;

            if (!dateError.timeEndError)
                item.datePlanOpenError = '';
        }

        if (item.registrationOffDate) {
            const dateRangeRegistrationDatePlan = validateTimeRangeStartToEnd(item.registrationOffDate, item.datePlanOpen, 'Дата закрытия регистрации не должна быть меньше даты начала и даты планового открытия', '');
            const dateRangeRegistrationDateStart = validateTimeRangeStartToEnd(item.registrationOffDate, datetimeStart, 'Дата закрытия регистрации не должна быть меньше даты начала и даты планового открытия', '');
            const registrationOffDateError = dateRangeRegistrationDatePlan.timeStartError || dateRangeRegistrationDateStart.timeStartError;

            if (!item.registrationOffDateError?.length) {
                item.registrationOffDateError = registrationOffDateError;
                if (item.registrationOffDateError?.length)
                    result.isError = true;
            }

            if (!registrationOffDateError?.length)
                item.registrationOffDateError = '';
        }
        else item.registrationOffDateError = '';

        const countWeek = item.weeks?.filter(week => week.value === false);
        if (countWeek?.length === 7) {
            item.weekError = 'Вы не выбрали не одного дня недели';

            result.isError = true
        }
        else item.weekError = '';

        if (item.nameError || item.weekError || item.dateStartError || item.dateEndError || item.timeStartError || item.timeEndError || item.dateEndError || item.datePlanOpenError)
            result.isError = true;

        if (!isGenerateSessions(item)) {
            result.isError = true;
            item.generateError = 'Выбранные настройки генератора не позволяют создать ни одной сессии!';
            result.messageSessionEmpty = 'Выбранные настройки генератора не позволяют создать ни одной сессии!';
        }
    });

    return result;
}

export function validateSessionGenerate(item) {
    const result = {
        item: item,
        isError: false,
        messageSessionEmpty: null,
    };

    item.nameError = !item.name?.length ? 'Обязательна для заполнения' : '';
    item.dateStartError = !item.dateStart ? 'Обязательна для заполнения' : '';
    item.dateEndError = !item.dateEnd ? 'Обязательна для заполнения' : '';
    item.timeStartError = !item.timeStart ? 'Обязательна для заполнения' : '';
    item.timeEndError = !item.timeEnd ? 'Обязательна для заполнения' : '';
    item.datePlanOpenError = !item.datePlanOpen ? 'Обязательна для заполнения' : '';

    const datetimeStart = item.dateStart + ' ' + item.timeStart;
    const datetimeEnd = item.dateEnd + ' ' + item.timeEnd;

    const dateValStartAndPlan = validateTimeRangeStartToEnd(datetimeStart, item.datePlanOpen, 'Дата начала не может быть меньше даты планового открытия', 'Дата планового открытия не может быть больше даты начала');
    const dateValStartAndEnd = validateTimeRangeEndToStart(datetimeStart, datetimeEnd, 'Дата начала не может быть больше даты окончания', 'Дата окончания не может быть меньше даты начала');

    if (dateValStartAndPlan.timeStartError?.length) {
        item.dateStartError = dateValStartAndPlan.timeStartError;
        if (dateValStartAndPlan.timeStartError)
            result.isError = true;

        if (!dateValStartAndPlan.timeStartError)
            item.dateStartError = '';
    }
    if (dateValStartAndPlan.timeEndError?.length) {
        item.datePlanOpenError = dateValStartAndPlan.timeEndError;
        if (dateValStartAndPlan.timeEndError?.length)
            result.isError = true;

        if (!dateValStartAndPlan.timeEndError)
            item.datePlanOpenError = '';
    }

    if (!item.dateStartError?.length) {
        item.dateStartError = dateValStartAndEnd.timeStartError;
        if (dateValStartAndEnd.timeStartError?.length)
            result.isError = true;

        if (!dateValStartAndEnd.timeStartError?.length)
            item.dateStartError = '';
    }

    if (!item.dateEndError?.length) {
        item.dateEndError = dateValStartAndEnd.timeEndError;
        if (dateValStartAndEnd.timeEndError?.length)
            result.isError = true;

        if (dateValStartAndEnd.timeEndError?.length)
            item.dateEndError = '';
    }

    const timeError = validateTimeRangeEndToStart(item.timeStart, item.timeEnd, 'Время начала не может быть позже времени окончания', 'Время окончания не может быть раньше времени начала');
    if (!item.timeStartError?.length) {
        item.timeStartError = timeError.timeStartError;
        if (timeError.timeStartError?.length)
            result.isError = true;

        if (!timeError.timeStartError)
            item.timeStartError = '';
    }
    if (!item.timeEndError?.length) {
        item.timeEndError = timeError.timeEndError;
        if (timeError.timeEndError?.length)
            result.isError = true;

        if (!timeError.timeEndError)
            item.timeEndError = '';
    }

    const dateError = validateTimeRangeStartToEnd(datetimeStart, item.datePlanOpen, 'Дата начала не может быть раньше даты планового открытия', 'Дата планового открытия не может быть больше даты начала');
    if (!item.dateStartError?.length) {
        item.dateStartError = dateError.timeStartError;
        if (item.dateStartError?.length)
            result.isError = true;

        if (!item.dateStartError)
            item.dateStartError = '';
    }
    if (!item.datePlanOpenError?.length) {
        item.datePlanOpenError = dateError.timeEndError;
        if (dateError.timeEndError?.length)
            result.isError = true;

        if (!dateError.timeEndError)
            item.datePlanOpenError = '';
    }

    if (item.registrationOffDate) {
        const dateRangeRegistrationDatePlan = validateTimeRangeStartToEnd(item.registrationOffDate, item.datePlanOpen, 'Дата закрытия регистрации не должна быть меньше даты начала и даты планового открытия', '');
        const dateRangeRegistrationDateStart = validateTimeRangeStartToEnd(item.registrationOffDate, datetimeStart, 'Дата закрытия регистрации не должна быть меньше даты начала и даты планового открытия', '');
        const registrationOffDateError = dateRangeRegistrationDatePlan.timeStartError || dateRangeRegistrationDateStart.timeStartError;

        if (!item.registrationOffDateError?.length) {
            item.registrationOffDateError = registrationOffDateError;
            if (item.registrationOffDateError?.length)
                result.isError = true;
        }

        if (!registrationOffDateError?.length)
            item.registrationOffDateError = '';
    }
    else item.registrationOffDateError = '';

    const countWeek = item.weeks?.filter(week => week.value === false);
    if (countWeek?.length === 7) {
        item.weekError = 'Вы не выбрали не одного дня недели';

        result.isError = true
    }
    else item.weekError = '';

    if (item.nameError || item.weekError || item.dateStartError || item.dateEndError || item.timeStartError || item.timeEndError || item.dateEndError || item.datePlanOpenError)
        result.isError = true;

    if (!isGenerateSessions(item)) {
        result.isError = true;
        result.messageSessionEmpty = 'Выбранные настройки генератора не позволяют создать ни одной сессии!';
    }

    return result;
}

// Проверяет, создаст ли в генераторе хотя-бы одну сессию.
function isGenerateSessions(item) {
    const weeks = item.weeks || [];
    if (!Array.isArray(weeks)) {
        return false;
    }

    const dateStart = item.dateStart || null;
    const dateEnd = item.dateEnd || null;

    if (!dateStart?.length || !dateEnd?.length || !item.timeStart?.length || !item.timeEnd?.length)
        return false;

    const daysOfWeek = [];
    if (weeks.find(day => day.value && day.name === 'ПН')) {
        daysOfWeek.push('monday');
    }
    if (weeks.find(day => day.value && day.name === 'ВТ')) {
        daysOfWeek.push('tuesday');
    }
    if (weeks.find(day => day.value && day.name === 'СР')) {
        daysOfWeek.push('wednesday');
    }
    if (weeks.find(day => day.value && day.name === 'ЧТ')) {
        daysOfWeek.push('thursday');
    }
    if (weeks.find(day => day.value && day.name === 'ПТ')) {
        daysOfWeek.push('friday');
    }
    if (weeks.find(day => day.value && day.name === 'СБ')) {
        daysOfWeek.push('saturday');
    }
    if (weeks.find(day => day.value && day.name === 'ВС')) {
        daysOfWeek.push('sunday');
    }

    const startDate = new Date(dateStart + ' ' + item.timeStart);
    const endDate = new Date(dateEnd + ' ' + item.timeEnd);

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        // Проверяем, что текущий день недели
        if (daysOfWeek.includes(currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase())) {
            return true; // Найдена хотя бы одна сессия для генерации
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return false; // Не найдено ни одной сессии для генерации
}

