<template>
  <div class="item-section">
    <div class="mb-5 mt-5">
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localNameRu"
            label="Наименование (RU) *"/>
        <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
      </div>
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localNameEn"
            label="Наименование (EN)"/>
        <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
      </div>
    </div>
    <div class="mb-5">
      <label class="block mb-2 font-medium text-basic-gray">Выберите павильон *</label>
      <Multiselect
          :disabled="localIsDisable"
          v-model="localSelectedPavilion"
          :options="pavilions"
          :valueProp="'id'"
          track-by="name"
          label="name"
          :close-on-select="true"
          :searchable="true"/>
      <p v-if="selectedPavilionError" class="mt-2 text-sm text-red-600"> {{ selectedPavilionError }} </p>
    </div>
    <div class="grid gap-4 mb-5 sm:grid-cols-2">
      <div class="sm:col-span-2">
        <input-textarea
            :disabled="localIsDisable"
            class="placeholder-gray-100"
            placeholder=""
            v-model="localDescriptionRu"
            label="Подробное описание (RU) *"
        />
        <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600"> {{ descriptionRuError }} </p>
      </div>
      <div class="sm:col-span-2">
        <input-textarea
            :disabled="localIsDisable"
            class="placeholder-gray-100"
            placeholder=""
            v-model="localDescriptionEn"
            :label="'Подробное описание (EN)'"
        />
        <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600"> {{ descriptionEnError }} </p>
      </div>
    </div>
    <div class="mb-7">
      <div v-if="typeof localAudio !== 'string' && localAudio?.length">
        <div class="image-content"
             style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
          <h2 class="mb-2" style="text-align: center">Аудио *</h2>
          <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
            <span class="mr-2">{{ localAudio[0].name }}</span>
            <button @click="localAudio=null" type="button" class="hover:opacity-60">
              <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z"
                    stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="localAudio?.name?.length">
        <div class="image-content"
             style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
          <h2 class="mb-2" style="text-align: center">Аудио *</h2>
          <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
            <span class="mr-2">{{ localAudio.name }}</span>
            <button @click="localAudio=null" type="button" class="hover:opacity-60">
              <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z"
                    stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
          v-else-if="typeof localAudio !== 'string'"
          class="grid gap-4 mb-4">
        <div class="sm:col-span-2">
          <h2 class="mb-2" style="text-align: center">Загрузить аудио *</h2>
          <drop-file
              :disabled="localIsDisable"
              v-model="localAudio"
              :type="audioTypes"
              :single="true"
          />
        </div>
      </div>
      <div
          v-else
          class="image-content" style="text-align: center; margin: auto;">
        <mtAudioPlayer
            :src="localAudio"
        />
        <button
            v-if="!localIsDisable"
            @click="localAudio=null"
            class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
          Удалить
        </button>
      </div>
      <p v-if="audioError" class="mt-2 text-sm text-red-600"> {{ audioError }} </p>
    </div>

    <div class="mb-5 mt-5">
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localAudioTextRu"
            label="Описание аудио (RU) *"/>
        <p v-if="audioTextRuError" class="mt-2 text-sm text-red-600"> {{ audioTextRuError }} </p>
      </div>
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localAudioTextEn"
            label="Описание аудио (EN)"/>
        <p v-if="audioTextEnError" class="mt-2 text-sm text-red-600"> {{ audioTextEnError }} </p>
      </div>
    </div>

    <div class="mb-7">
      <div v-if="typeof localImage !== 'string' && localImage?.length">
        <div class="image-content"
             style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
          <h2 class="mb-2" style="text-align: center">Изображение *</h2>
          <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
            <span class="mr-2">{{ localImage[0].name }}</span>
            <button @click="localImage=null" type="button" class="hover:opacity-60">
              <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z"
                    stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="localImage?.name?.length">
        <div class="image-content"
             style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
          <h2 class="mb-2" style="text-align: center">Изображение *</h2>
          <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
            <span class="mr-2">{{ localImage.name }}</span>
            <button @click="localImage=null" type="button" class="hover:opacity-60">
              <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z"
                    stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
          v-else-if="typeof localImage !== 'string'"
          class="grid gap-4 mb-4">
        <div class="sm:col-span-2">
          <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
          <drop-file
              :disabled="localIsDisable"
              v-model="localImage"
              :type="imageTypes"
              :single="true"
          />
        </div>
      </div>
      <div
          v-else
          class="image-content" style="text-align: center; width: 250px; margin: auto;">
        <img
            style="width: 250px; height: auto; object-fit: cover"
            :src="localImage"
            alt="">
        <button
            v-if="!localIsDisable"
            @click="localImage=null"
            class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
          Удалить
        </button>
      </div>
      <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>
    </div>
    <div class="mb-5 mt-5" style="padding-bottom: 15px;">
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localImageAuthorRu"
            label="Автор изображение (RU) *"/>
        <p v-if="imageAuthorRuError" class="mt-2 text-sm text-red-600"> {{ imageAuthorRuError }} </p>
      </div>
      <div>
        <input-text
            :disabled="localIsDisable"
            v-model="localImageAuthorEn"
            label="Автор изображение (EN)"/>
        <p v-if="imageAuthorEnError" class="mt-2 text-sm text-red-600"> {{ imageAuthorEnError }} </p>
      </div>
    </div>

    <div v-if="checkRole(['admin', 'content_manager', 'manager'])">
      <hr class="mb-4 mb-2">
      <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
        <input
            :id="'bordered-checkbox-route-point-is_active_ru-' + key"
            type="checkbox"
            v-model="localIsActiveRu"
            name="bordered-checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
        <div class="ml-2 text-sm">
          <label :for="'bordered-checkbox-route-point-is_active_ru-' + key" class="font-medium text-basic-gray dark:text-gray-300">
            Статус для (RU)
          </label>
          <p
              :id="'bordered-checkbox-route-point-is_active_ru-helper-' + key"
              class="text-xs font-normal text-gray-500 dark:text-gray-300">
            {{
              localIsActiveRu ? 'Активна (Да)' : 'Не активна (Нет)'
            }}
          </p>
        </div>
      </div>
    </div>

    <button
        v-if="isCreate"
        @click="onDeleteCard(id, index)"
        type="button"
        style="margin-bottom: 30px; padding: 5px 15px;"
        class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
      Удалить
    </button>
    <button
        v-else-if="!isDisable"
        @click="onDeleteCard(id, index)"
        type="button"
        style="margin-bottom: 30px; padding: 5px 15px;"
        class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
      Удалить
    </button>
  </div>
</template>

<script lang="ts" setup>

import {defineProps, defineEmits, watch, ref} from 'vue';
import InputText from "../../../../flowbite/form/inputText.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect';
import MtAudioPlayer from "../../../../media/audio/mt-audio-player.vue";
import {checkRole} from "../../../../../utilits/functions.js";

const {
  index,
  key,
  id,
  nameRu,
  nameRuError,
  nameEn,
  nameEnError,
  selectedPavilion,
  selectedPavilionError,
  pavilions,
  descriptionRu,
  descriptionRuError,
  descriptionEn,
  descriptionEnError,
  audio,
  audioError,
  audioTextRu,
  audioTextRuError,
  audioTextEn,
  audioTextEnError,
  image,
  imageError,
  imageAuthorRu,
  imageAuthorRuError,
  imageAuthorEn,
  imageAuthorEnError,
  isDisable,
  isCreate,
  isActiveRu,
} = defineProps([
  'key', 'id', 'index', 'nameRu', 'nameEn', 'selectedPavilion', 'pavilions', 'descriptionRu', 'descriptionEn', 'audio', 'audioTextRu',
  'audioTextEn', 'image', 'imageAuthorRu', 'imageAuthorEn', 'isDisable', 'isCreate', 'isActiveRu',
]);

const localNameRu = ref(nameRu);
const localNameEn = ref(nameEn);
const localSelectedPavilion = ref(selectedPavilion);
const localDescriptionRu = ref(descriptionRu);
const localDescriptionEn = ref(descriptionEn);
const localAudio = ref(audio);
const localAudioTextRu = ref(audioTextRu);
const localAudioTextEn = ref(audioTextEn);
const localImage = ref(image);
const localImageAuthorRu = ref(imageAuthorRu);
const localImageAuthorEn = ref(imageAuthorEn);
const localIsActiveRu = ref(isActiveRu);

const localIsDisable = ref(isDisable);

const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

const audioTypes = {
  extensions: ["mp3"],
  max_filesize: 1000,
};

const emit = defineEmits([
  'update:nameRu', 'update:nameEn', 'update:selectedPavilion', 'update:descriptionRu', 'update:descriptionEn',
  'update:audio', 'update:audioTextRu', 'update:audioTextEn', 'update:image', 'update:imageAuthorRu', 'update:imageAuthorRu',
  'click:onDelete', 'update:isActiveRu',
]);

const onDeleteCard = (id, index) => {
  emit('click:onDelete', {action: 'pointRoutes', id, index});
}

watch(localNameRu, (newVal) => {
  emit('update:nameRu', newVal);
});

watch(localNameEn, (newVal) => {
  emit('update:nameEn', newVal);
});

watch(localSelectedPavilion, (newVal) => {
  emit('update:selectedPavilion', newVal);
});

watch(localDescriptionRu, (newVal) => {
  emit('update:descriptionRu', newVal);
});

watch(localDescriptionEn, (newVal) => {
  emit('update:descriptionEn', newVal);
});

watch(localAudio, (newVal) => {
  emit('update:audio', newVal);
});

watch(localAudioTextRu, (newVal) => {
  emit('update:audioTextRu', newVal);
});

watch(localAudioTextEn, (newVal) => {
  emit('update:audioTextEn', newVal);
});

watch(localImage, (newVal) => {
  emit('update:image', newVal);
});

watch(localAudioTextRu, (newVal) => {
  emit('update:imageAuthorRu', newVal);
});

watch(localAudioTextEn, (newVal) => {
  emit('update:imageAuthorEn', newVal);
});

</script>

<style src="../../../../../../node_modules/@vueform/multiselect/themes/default.css"></style>

<style>
.item-section {
  padding: 15px 15px 0 15px;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
</style>
