export function validateTimeRangeEndToStart (timeStart, timeEnd, labelStartError, labelEndError) {
    if (timeStart && timeEnd) {
        if (timeStart >= timeEnd) {
            return {timeStartError: labelStartError, timeEndError: labelEndError};
        } else {
            return {timeStartError: '', timeEndError: ''};
        }
    }

    return {timeStartError: '', timeEndError: ''};
}

export function validateTimeRangeStartToEnd (timeStart, timeEnd, labelStartError, labelEndError) {
    if (timeStart && timeEnd) {
        if (timeStart <= timeEnd) {
            return {timeStartError: labelStartError, timeEndError: labelEndError};
        } else {
            return {timeStartError: '', timeEndError: ''};
        }
    }

    return {timeStartError: '', timeEndError: ''};
}
