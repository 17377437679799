<template>
  <div>
    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <div>
                            <input-text
                                v-model="newsQuota"
                                @blur="onHandleBlurNewsQuota"
                                type="number"
                                label="Квота для новостей (общее) *"/>
                            <div v-if="message?.text" style="margin-top: 15px;">
                              <span
                                  style="padding: 5px 15px; border-radius: 15px; background: #c7ffc3;"
                                  v-if="message.action === 'success'">
                                {{message.text}}
                              </span>
                              <span
                                  style="padding: 5px 15px; border-radius: 15px; background: #ffc3c3;"
                                  v-else-if="message.action === 'error'">
                                {{message.text}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="button"
                              @click.prevent="onHandleSaveSetting"
                              class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                        Сохранить
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import '@vuepic/vue-datepicker/dist/main.css'
import InputText from "../../flowbite/form/inputText.vue";
import {serviceSetting, serviceSettingUpdate} from "../../../service/setting/serviceSetting.js";

const newsQuota = ref(null);
const id = ref(null);
const isLoadingCreate = ref(false);
const message = ref(null);

serviceSetting().then(response => {
  newsQuota.value = response?.data?.newsQuota || 5;
  id.value = response?.data?.id;
});

const onHandleSaveSetting = () => {
  isLoadingCreate.value = true;
  serviceSettingUpdate(id.value,{newsQuota: newsQuota.value}).then(response => {
    if (response.code === 204)
      message.value = {text: 'Успешно сохранено', action: 'success'};
    else message.value = {text: 'Не было сохранено', action: 'error'};

    setTimeout(() => {
      message.value = null;
    }, 3000);

    isLoadingCreate.value = false;
  });
}

const onHandleBlurNewsQuota = () => {
  if (newsQuota.value <= 0)
    newsQuota.value = 0;
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>
