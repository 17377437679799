let tokenPromise = null;

export function createTokenPromise() {
    tokenPromise = null;
}

export function getToken() {
    if (tokenPromise === null) {
        tokenPromise = new Promise((resolve, reject) => {
            const token = localStorage.getItem("token");
            if (token) {
                resolve(token);
            } else {
                // Возможно, здесь стоит добавить логику ожидания токена
                // Пример: setTimeout, setInterval, или использование событий
                // reject(new Error('Токен еще не появился'));
            }
        });
    }
    return tokenPromise;
}

export async function handleAsync(promise) {
    try {
        const response = await promise;

        if (response.ok) {
            if (response.status === 204)
                return handleStatus204();

            const dataRes = await response.json();

            return handleStatusOk(dataRes, response.status);
        } else {
            let message = response.statusText;
            let errors = [];
            let responseData = await response.json();

            // if (response.status === 401) handleStatus401();

            if (response.status === 422)
                return responseData;

            if (response.status === 400) {
                errors = responseData.errors || {message};
            } else if (response.status === 500)
                message = 'Произошла ошибка, повторите еще раз немного позднее.';

            errors = [{
                status: response.status,
                message,
                errors
            }];
            return {data: null, success: false, errors};
        }
    } catch (error) {
        return {
            data: null,
            success: false,
            errors: {
                status: null,
                errors: {message: error},
                message: error
            },
        };
    }
}

function handleStatus204() {
    return {
        success: true,
        code: 204,
    };
}

function handleStatusOk(data, code) {
    const response = data.layouts;
    return {
        success: response?.success || false,
        data: response?.data !== undefined ? response.data : data.data,
        filters: response?.filters,
        pagination: response?.pagination,
        code: code,
        errors: response?.errors || [],
    };
}
