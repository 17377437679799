import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceEventTypeCardList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/event-type-cards` + getQueryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceEventTypeCardFindById(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/lists/event-type-cards/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

function getQueryParams(params) {
    let queryParams = params?.page ? `?page=${params.page}` : '';
    if (params?.limit)
        queryParams += (queryParams ? '&' : '?') + `limit=${params.limit}`;
    if (params?.search)
        queryParams += (queryParams ? '&' : '?') + `search=${params.search}`;

    return queryParams;
}
