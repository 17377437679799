import {contentEventCardTypeEnum} from "../constants/contentEventCardTypeEnum.js";

export function validateArtist(items) {
    const result = {
        items: items,
        isError: false,
        messageEmpty: null,
    };

    if (!items?.length) {
        return {
            items,
            isError: true,
            messageEmpty: 'Добавьте хотя-бы одного артиста',
        };
    }
    else result.messageEmpty = '';

    items?.map(artist => {
        if (!artist?.id) {
            artist.nameRuError = artist.nameRu?.length ? '' : 'Обязательно для заполнения';
            if (artist.nameRuError?.length)
                result.isError = true;

            artist.descriptionRuError = artist.descriptionRu?.length ? '' : 'Обязательно для заполнения';
            if (artist.descriptionRuError?.length)
                result.isError = true;

            artist.positionRuError = artist.positionRu?.length ? '' : 'Обязательно для заполнения';
            if (artist.positionRuError?.length)
                result.isError = true;

            if (Array.isArray(artist.image)) {
                artist.image = artist.image[0];
            }

            if (artist.image?.name?.length)
                artist.imageError = '';
            else if (artist.image?.length)
                artist.imageError = '';
            else {
                artist.imageError = 'Вы не выбрали изображение';
                result.isError = true;
            }

            artist.authorRuError = artist.authorRu?.length ? '' : 'Обязательно для заполнения';
            if (artist.authorRuError?.length)
                result.isError = true;
        }
    });

    result.items = items;

    return result;
}

export function validatePointRoute(items) {
    const result = {
        items: items,
        isError: false,
        messageEmpty: null,
    };

    if (!items?.length) {
        return {
            items,
            isError: true,
            messageEmpty: 'Добавьте хотя-бы одну точку маршрута',
        };
    }
    else result.messageEmpty = '';

    items?.map(point => {
        if (!point?.id) {
            point.nameRuError = point.nameRu?.length ? '' : 'Обязательно для заполнения';
            if (point.nameRuError?.length)
                result.isError = true;

            point.selectedPavilionError = point.selectedPavilion?.length ? '' : 'Вы не выбрали павильон';
            if (point.selectedPavilionError?.length)
                result.isError = true;

            point.descriptionRuError = point.descriptionRu?.length ? '' : 'Обязательно для заполнения';
            if (point.descriptionRuError?.length)
                result.isError = true;

            if (Array.isArray(point.image)) {
                point.image = point.image[0];
            }

            if (point.image?.name?.length)
                point.imageError = '';
            else if (point.image?.length)
                point.imageError = '';
            else {
                point.imageError = 'Вы не выбрали аудиофайл';
                result.isError = true;
            }

            if (Array.isArray(point.audio)) {
                point.audio = point.audio[0];
            }

            if (point.audio?.name?.length)
                point.audioError = '';
            else if (point.audio?.length)
                point.audioError = '';
            else {
                point.audioError = 'Вы не выбрали аудиофайл';
                result.isError = true;
            }

            point.audioTextRuError = point.audioTextRu?.length ? '' : 'Обязательно для заполнения';
            if (point.audioTextRuError?.length)
                result.isError = true;

            point.imageAuthorRuError = point.imageAuthorRu?.length ? '' : 'Обязательно для заполнения';
            if (point.imageAuthorRuError?.length)
                result.isError = true;
        }
    });

    console.log(result, 'result')

    result.items = items;

    return result;
}

export function validateOfficial(items, action) {
    const result = {
        items: items,
        isError: false,
        messageEmpty: null,
    };

    if (!action)
        return {
            items: items,
            isError: true,
            messageEmpty: null,
        };

    // if (contentEventCardTypeEnum.Speaker === action) {
    //     if (!items?.length) {
    //         result.messageEmpty = 'Добавьте хотя-бы одного спикера';
    //         result.isError = true;
    //         return result;
    //     } else result.messageEmpty = '';
    //
    //     officials = items;
    // } else if (contentEventCardTypeEnum.Lecturer === action) {
    //     if (!items?.length) {
    //         result.messageEmpty = 'Добавьте хотя-бы одного докладчика';
    //         result.isError = true;
    //         return result;
    //     } else result.messageEmpty = '';
    //
    //     officials = items;
    // } else if (contentEventCardTypeEnum.Moderator === action) {
    //     if (!items?.length) {
    //         result.messageEmpty = 'Добавьте хотя-бы одного модератора';
    //         result.isError = true;
    //         return result;
    //     } else result.messageEmpty = '';
    //
    //     officials = items;
    // } else return result;

    items?.map(official => {
        if (!official?.id) {
            official.imageError = !official.image?.length ? 'Вы не выбрали изображение' : '';
            if (official.imageError)
                result.isError = true;

            official.nameRuError = official.nameRu?.length ? '' : 'Обязательно для заполнения';
            if (official.nameRuError?.length)
                result.isError = true;

            official.authorRuError = official.authorRu?.length ? '' : 'Обязательно для заполнения';
            if (official.authorRuError?.length)
                result.isError = true;

            official.descriptionRuError = official.descriptionRu?.length ? '' : 'Обязательно для заполнения';
            if (official.descriptionRuError?.length)
                result.isError = true;

            official.positionRuError = official.positionRu?.length ? '' : 'Обязательно для заполнения';
            if (official.positionRuError?.length)
                result.isError = true;
        }
    });

    if (contentEventCardTypeEnum.Speaker === action) {
        result.items = items;
    } else if (contentEventCardTypeEnum.Lecturer === action) {
        result.items = items;
    } else if (contentEventCardTypeEnum.Moderator === action) {
        result.items = items;
    } else return result;

    return result;
}

export function validatePartner(items) {
    const result = {
        items: items,
        isError: false,
        messageEmpty: null,
    };

    if (!items?.length) {
        return {
            items,
            isError: true,
            messageEmpty: 'Добавьте хотя-бы одного партнера',
        };
    }
    else result.messageEmpty = '';

    items?.map(partner => {
        if (!partner?.id) {
            partner.nameRuError = partner.nameRu?.length ? '' : 'Обязательно для заполнения';
            if (partner.nameRuError?.length)
                result.isError = true;

            partner.shortDescriptionRuError = partner.shortDescriptionRu?.length ? '' : 'Обязательно для заполнения';
            if (partner.shortDescriptionRuError?.length)
                result.isError = true;

            partner.linkRuError = partner.linkRu?.length ? '' : 'Обязательно для заполнения';
            if (partner.linkRuError?.length)
                result.isError = true;

            if (Array.isArray(partner.image)) {
                partner.image = partner.image[0];
            }

            if (partner.image?.name?.length)
                partner.imageError = '';
            else if (partner.image?.length)
                partner.imageError = '';
            else {
                partner.imageError = 'Вы не выбрали изображение';
                result.isError = true;
            }

            partner.authorImageRuError = partner.authorImageRu?.length ? '' : 'Обязательно для заполнения';
            if (partner.authorImageRuError?.length)
                result.isError = true;
        }
    });

    result.items = items;

    return result;
}
