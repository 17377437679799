<template>
  <div v-if="isLoadingCreate">
    <spinner />
    <div style="text-align: center; color: #afafaf; margin-top: -30px;">Создание профиля ...</div>
  </div>

  <div
      v-if="saveMessageResponse"
      style="z-index: 100; top: 80px"
      id="toast-top-right"
      :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
      class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
      role="alert">
    <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
  </div>

  <div v-if="!isLoadingCreate">
    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div class="tabs">
                        <div
                            :class="{'active': tabKey === 'main'}"
                            @click="onHandleClickTabKey('main')"
                            class="tab">
                          <div class="tab-label">Основное</div>
                        </div>
                        <div
                            :class="{'active': tabKey === 'facts'}"
                            @click="onHandleClickTabKey('facts')"
                            class="tab">
                          <div class="tab-label">Пять интересных фактов</div>
                        </div>
                        <div
                            :class="{'active': tabKey === 'sliders'}"
                            @click="onHandleClickTabKey('sliders')"
                            class="tab">
                          <div class="tab-label">Рендеры {{sliders.length ? `(${sliders.length})` : ''}}</div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'facts'">
                        <div
                            style="padding: 5px 15px; color: red; text-align: center;"
                            v-if="messageFactEmptyRu" class="alert-message-error">
                          {{messageFactEmptyRu}}
                        </div>

                        <div class="fact-content">
                          <div
                              v-for="(fact, index) in factsRu"
                              :key="index"
                              class="fact-content-item mb-10">
                            <input-textarea
                                v-model="fact.name"
                                :label="'Интересный факт (RU) ' + (index + 1) + ' *'" />
                            <small :style="{color: fact.name?.length > 150 ? 'red' : ''}">Количество символов {{fact.name?.length}} из 150</small>
                            <p v-if="fact.nameError" class="mt-2 text-sm text-red-600"> {{ fact.nameError }} </p>
                          </div>
                        </div>
                        <div class="fact-content">
                          <div
                              v-for="(factEn, indexEn) in factsEn"
                              :key="indexEn"
                              class="fact-content-item mb-10">
                            <input-textarea
                                v-model="factEn.name"
                                :label="'Интересный факт (EN) ' + (indexEn + 1) + ''" />
                            <small :style="{color: factEn.name?.length > 150 ? 'red' : ''}">Количество символов {{factEn.name?.length}} из 150</small>
                            <p v-if="factEn.nameError" class="mt-2 text-sm text-red-600"> {{ factEn.nameError }} </p>
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'sliders'">
                        <div
                            style="padding: 5px 15px; color: red; text-align: center;"
                            v-if="messageSliderEmpty" class="alert-message-error">
                          {{messageSliderEmpty}}
                        </div>
                        <div
                            v-for="(slider, index) in sliders"
                            :key="index"
                            class="achievement-content-item">
                          <div class="achievement-content-item-image mb-7">
                            <div class="grid gap-4 mb-4">
                              <div
                                  v-if="!slider.image?.length"
                                  class="sm:col-span-2">
                                <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                <drop-file
                                    v-model="slider.image"
                                    :type="imageTypes"
                                    :single="true"
                                />
                                <p v-if="slider.imageError" class="mt-2 text-sm text-red-600"> {{ slider.imageError }} </p>
                              </div>
                              <div v-else>
                                <div class="image-content"
                                     style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                                  <h2 class="mb-2" style="text-align: center">Изображение *</h2>
                                  <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                    <span class="mr-2">{{slider.image[0].name}}</span>
                                    <button @click="onHandleClickSliderImageDelete(index)" type="button" class="hover:opacity-60">
                                      <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr/>
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  v-model="slider.authorRu"
                                  label="Наименование автора изображения (RU) *"/>
                              <p v-if="slider.authorRuError" class="mt-2 text-sm text-red-600"> {{ slider.authorRuError }} </p>
                            </div>
                            <div>
                              <input-text
                                  v-model="slider.authorEn"
                                  :label="'Наименование автора изображения (EN)'"/>
                              <p v-if="slider.authorEnError" class="mt-2 text-sm text-red-600"> {{ slider.authorEnError }} </p>
                            </div>
                          </div>
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  v-model="slider.sort"
                                  label="Сортировка"/>
                              <p v-if="slider.sortError" class="mt-2 text-sm text-red-600"> {{ slider.sortError }} </p>
                            </div>
                          </div>
                          <hr />
                          <button
                              @click="onHandleDeleteSlider(index)"
                              class="px-7 mr-5 mt-5 py-3 mb-5 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                            Удалить
                          </button>
                        </div>

                        <div
                            @click="onHandleClickSliderCreate"
                            class="session-add">
                          <div class="session-btn">
                            Добавить новый рендер
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'achievements'">
                        <div class="achievement-content">
                          <div
                              v-for="(achievement, index) in achievements"
                              :key="index"
                              class="achievement-content-item">
                            <div class="achievement-content-item-image mb-7">
                              <div class="grid gap-4 mb-4">
                                <div
                                    v-if="!achievement.image?.length"
                                    class="sm:col-span-2">
                                  <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                  <drop-file
                                      v-model="achievement.image"
                                      :type="imageTypes"
                                      :single="true"
                                  />
                                  <p v-if="achievement.imageError" class="mt-2 text-sm text-red-600"> {{ achievement.imageError }} </p>
                                </div>
                                <div v-else>
                                  <div class="image-content"
                                       style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                                    <h2 class="mb-2" style="text-align: center">Изображение *</h2>
                                    <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                      <span class="mr-2">{{achievement.image[0].name}}</span>
                                      <button @click="onHandleClickSessionImageDelete(index)" type="button" class="hover:opacity-60">
                                        <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <hr/>
                            <div class="author-achievement mb-5 mt-5">
                              <div>
                                <input-text
                                    v-model="achievement.authorRu"
                                    label="Наименование автора изображения (RU) *"/>
                                <p v-if="achievement.authorRuError" class="mt-2 text-sm text-red-600"> {{ achievement.authorRuError }} </p>
                              </div>
                              <div>
                                <input-text
                                    v-model="achievement.authorEn"
                                    :label="'Наименование автора изображения (EN)'"/>
                                <p v-if="achievement.authorEnError" class="mt-2 text-sm text-red-600"> {{ achievement.authorEnError }} </p>
                              </div>
                            </div>
                            <hr />
                            <div class="description-achievement mt-5">
                              <div class="sm:col-span-2">
                                <input-textarea
                                    class="placeholder-gray-100"
                                    placeholder=""
                                    v-model="achievement.descriptionRu"
                                    label="Краткое описание (RU) *"
                                />
                                <p v-if="achievement.descriptionRuError" class="mt-2 text-sm text-red-600">
                                  {{ achievement.descriptionRuError }} </p>
                              </div>
                              <div class="sm:col-span-2">
                                <input-textarea
                                    class="placeholder-gray-100"
                                    placeholder=""
                                    v-model="achievement.descriptionEn"
                                    :label="'Краткое описание (EN)'"
                                />
                                <p v-if="achievement.descriptionEnError" class="mt-2 text-sm text-red-600">
                                  {{ achievement.descriptionEnError }} </p>
                              </div>
                            </div>
                            <hr />
                            <button
                                @click="onHandleDeleteAchievement(index)"
                                class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                              Удалить
                            </button>
                          </div>

                          <div
                              @click="onHandleClickAchievementCreate"
                              class="session-add">
                            <div class="session-btn">
                              Добавить новое достижение
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'main'">
                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <div>
                              <input-text v-model="nameRu" label="Наименование (RU) *"/>
                              <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
                            </div>
                            <div>
                              <input-text v-model="nameEn" :label="'Наименование (EN)'"/>
                              <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
                            </div>
                          </div>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionRu"
                                label="Краткое описание (RU) *"
                            />
                            <p v-if="shortDescriptionRuError" class="mt-2 text-sm text-red-600">
                              {{ shortDescriptionRuError }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionEn"
                                :label="'Краткое описание (EN)'"
                            />
                            <p v-if="shortDescriptionEnError" class="mt-2 text-sm text-red-600">
                              {{ shortDescriptionEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionRu"
                                label="Подробное описание (RU) *"
                            />
                            <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600"> {{
                                descriptionRuError
                              }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionEn"
                                :label="'Подробное описание (EN)'"
                            />
                            <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600"> {{
                                descriptionEnError
                              }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите контур</label>
                          <Multiselect
                              v-model="selectedContours"
                              :options="contours"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="name"
                              label="name"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedContourError" class="mt-2 text-sm text-red-600">
                            {{
                              selectedContourError
                            }}
                          </p>
                        </div>

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите павильон *</label>
                          <Multiselect
                              v-model="selectedPavilions"
                              :options="pavilions"
                              :mode="'tags'"
                              :valueProp="'id'"
                              track-by="name"
                              label="name"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedPavilionError" class="mt-2 text-sm text-red-600">
                            {{ selectedPavilionError }}
                          </p>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="!previewImage?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить изображение логотипа/герба *</h2>
                            <drop-file
                                v-model="previewImage"
                                :type="previewImageTypes"
                                :single="true"
                            />
                            <p v-if="previewImageError" class="mt-2 text-sm text-red-600"> {{ previewImageError }} </p>
                          </div>
                          <div v-else>
                            <div class="image-content"
                                 style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                              <h2 class="mb-2" style="text-align: center">Изображение логотипа/герба *</h2>
                              <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                <span class="mr-2">{{previewImage[0].name}}</span>
                                <button @click="onHandleClickPreviewImageDelete" type="button" class="hover:opacity-60">
                                  <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="!video?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить видео файл *</h2>
                            <drop-file
                                v-model="video"
                                :type="videoTypes"
                                :single="true"
                            />
                            <p v-if="videoError" class="mt-2 text-sm text-red-600"> {{ videoError }} </p>
                          </div>
                          <div v-else>
                            <div class="image-content"
                                 style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                              <h2 class="mb-2" style="text-align: center">Изображение логотипа/герба *</h2>
                              <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;">
                                <span class="mr-2">{{video[0].name}}</span>
                                <button @click="onHandleClickVideoDelete" type="button" class="hover:opacity-60">
                                  <svg height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                v-model="authorVideoTitleNameRu"
                                label="Наименование автора видео (RU)"/>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text v-model="authorVideoTitleNameEn"
                                        label="Наименование автора видео (EN)"/>
                          </div>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                          <div
                              class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-4"
                                type="checkbox"
                                v-model="isActiveRu"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-4" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (RU)
                              </label>
                              <p
                                  id="helper-checkbox-text-4"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveRu ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div
                              class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-5"
                                type="checkbox"
                                v-model="isActiveEn"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-5" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (EN)
                              </label>
                              <p
                                  id="helper-checkbox-text-5"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSliderEmpty || isValErrorSlider"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{messageSliderEmpty || 'Ошибки в рендерах'}}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageAchievementEmpty || isValErrorAchievement"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{messageAchievementEmpty || 'Ошибки в достижениях'}}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageFactEmptyRu || isValErrorFact"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{messageFactEmptyRu || 'Ошибки в фактах'}}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageFactEmptyEn || isValErrorFact"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{messageFactEmptyEn || 'Ошибки в фактах'}}
                    </div>
                  </div>

                  <div
                      v-if="isErrorVal"
                      style="padding: 5px 15px; margin-top: 40px; color: red;"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      <ul style="list-style: initial; padding: 15px;">
                        <li v-if="nameRuError"><span style="font-weight: bold">Наименование (RU):</span> {{nameRuError}}</li>
                        <li v-if="nameEnError"><span style="font-weight: bold">Наименование (EN):</span> {{nameEnError}}</li>
                        <li v-if="shortDescriptionRuError"><span style="font-weight: bold">Краткое описание (RU):</span> {{shortDescriptionRuError}}</li>
                        <li v-if="shortDescriptionEnError"><span style="font-weight: bold">Краткое описание (EN):</span> {{shortDescriptionEnError}}</li>
                        <li v-if="descriptionRuError"><span style="font-weight: bold">Подробное описание (RU):</span> {{descriptionRuError}}</li>
                        <li v-if="descriptionEnError"><span style="font-weight: bold">Подробное описание (EN):</span> {{descriptionEnError}}</li>
                        <li v-if="selectedContourError"><span style="font-weight: bold">Контур:</span> {{selectedContourError}}</li>
                        <li v-if="selectedPavilionError"><span style="font-weight: bold">Павильоны:</span> {{selectedPavilionError}}</li>
                        <li v-if="previewImageError"><span style="font-weight: bold">Изображение логотипа/герба:</span> {{previewImageError}}</li>
                        <li v-if="videoError"><span style="font-weight: bold">Видео:</span> {{videoError}}</li>
                        </ul>
                    </div>
                  </div>

                  <div class="p-7 flex justify-between">
                    <div class="p-7 flex justify-between">
                      <div v-if="!isLoadingCreate">
                        <div
                            v-if="checkRole(['admin', 'manager'])"
                            class="flex flex-wrap">
                          <div class="flex items-center mb-4">
                            <label @click.prevent="onHandleClickCreate(6)"
                                   class="bg-status-6 inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                              <span>Согласовать</span>
                            </label>
                          </div>
                        </div>
                        <div
                            v-else
                            class="flex flex-wrap">
                          <div class="flex items-center mb-4">
                            <label @click.prevent="onHandleClickCreate(5)"
                                   class="bg-status-5 inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                              <span>Отправить организатору</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center p-7 border-b border-gray-200">
            <div class="text-3xl font-medium">
              {{ $t('document.remarksTitle') }}
            </div>
          </div>
          <div class="p-7 pt-4">
            <div class="flex-1 justify-between flex flex-col h-[50vh]">
              <div id="messages"
                   class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                <div class="chat-message" v-for="(message, index) in messages">
                  <div class="flex items-end" :class="{'justify-end': !message.isAnswer}">
                    <div style="position: relative" class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                      <div>
                        <div
                            @click="onHandleClickDeleteMessage(index)"
                            style="position: absolute; right: 10px; top: 10px; font-size: 12px; cursor: pointer"
                            class="icon-delete">
                          <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true"
                               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                        </div>
                        <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none"
                             :class="{'bg-gray-300 text-gray-600': message.isAnswer, 'bg-blue-600 text-white': !message.isAnswer}">
                          <div class="text-xs">
                            {{ message.user.name }}
                          </div>
                          <div class="text-xs">
                            {{ message.createdAt }}
                          </div>
                          <div class="text-sm">
                            {{ message.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                <div class="relative flex">
                          <textarea
                              :placeholder="$t('chat.write_message')"
                              v-model="messageData"
                              rows="5"
                              class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                          ></textarea>
                </div>
                <div v-if="checkRole(['admin', 'manager', 'content_manager'])" class="items-center mt-2">
                  <button :disabled="messages.length" type="button"
                          @click.prevent="onHandleClickSendMessage"
                          class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                    <template v-if="!loadDataMessage">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                           class="h-6 w-6 ml-2 transform rotate-90">
                        <path
                            d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                      </svg>
                    </template>
                    <template v-else>
                      <svg aria-hidden="true"
                           class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                           viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"/>
                      </svg>
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {checkRole} from "../../../../utilits/functions.js";
import Spinner from "../../../flowbite/spinner.vue";
import {ref} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect'
import {useRouter} from 'vue-router'
import moment from "moment";
import {serviceParticipantCategoryList} from "../../../../service/lists/serviceListParticipantCategory.js";
import {servicePavilionList} from "../../../../service/lists/serviceListPavilion.js";
import {
  serviceContentCheckCreate,
  serviceContentProfileCreate
} from "../../../../service/contents/news/serviceContentProfile.js";
import {serviceSelectedUserContours} from "../../../../service/user/user.js";

const router = useRouter();

const isLoadingData = ref(true);
const saveMessageResponse = ref(null);

const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;
const messages = ref([]);
const messageData = ref(null);
const tabKey = ref('main');
const isLoadingCreate = ref(false);

const nameRu = ref('');
const nameRuError = ref('');
const nameEn = ref('');
const nameEnError = ref('');
const shortDescriptionRu = ref('');
const shortDescriptionRuError = ref('');
const shortDescriptionEn = ref('');
const shortDescriptionEnError = ref('');
const descriptionRu = ref('');
const descriptionRuError = ref('');
const descriptionEn = ref('');
const descriptionEnError = ref('');
const authorImageTitleNameRu = ref('');
const authorImageTitleNameRuError = ref('');
const authorImageTitleNameEn = ref('');
const authorImageTitleNameEnError = ref('');
const participantCategories = ref([]);
const pavilions = ref([]);
const selectedPavilions = ref([]);
const selectedPavilionError = ref('');
const isActiveRu = ref(true);
const isActiveEn = ref(true);
const achievements = ref([]);
const sliders = ref([]);
const authorVideoTitleNameRu = ref('');
const authorVideoTitleNameEn = ref('');
const selectedContours = ref([]);
const contours = ref([]);
const selectedContourError = ref('');
const factsRu = ref([
  {
    name: '',
    language: 'RU',
    position: 1,
    nameError: '',
  },
  {
    name: '',
    language: 'RU',
    position: 2,
    nameError: '',
  },
  {
    name: '',
    language: 'RU',
    position: 3,
    nameError: '',
  },
  {
    name: '',
    language: 'RU',
    position: 4,
    nameError: '',
  },
  {
    name: '',
    language: 'RU',
    position: 5,
    nameError: '',
  }
]);

const factsEn = ref([
  {
    name: '',
    language: 'EN',
    position: 1,
    nameError: '',
  },
  {
    name: '',
    language: 'EN',
    position: 2,
    nameError: '',
  },
  {
    name: '',
    language: 'EN',
    position: 3,
    nameError: '',
  },
  {
    name: '',
    language: 'EN',
    position: 4,
    nameError: '',
  },
  {
    name: '',
    language: 'EN',
    position: 5,
    nameError: '',
  }
]);

const messageSliderEmpty = ref('');
const isErrorVal = ref(false);
const isValErrorSlider = ref(false);
const isValErrorAchievement = ref(false);
const isValErrorFact = ref(false);
const messageAchievementEmpty = ref('');
const messageFactEmptyRu = ref('');
const messageFactEmptyEn = ref('');
// requiredValue - нужно для того, чтобы выставить (*) где обязательность заполнение для администрации
const requiredValue = checkRole(['admin', 'manager', 'content_manager']) ? '*' : '';

const previewImage = ref(null);
const previewImageError = ref('');
const previewImageTypes = {
  extensions: ['svg'],
  max_filesize: 10,
};
const image = ref(null);
const imageError = ref('');
const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

const video = ref(null);
const videoError = ref('');
const videoTypes = {
  extensions: ["mp4"],
  max_filesize: 1000,
};

serviceSelectedUserContours().then(response => {
  contours.value = response.data || [];
  selectedContours.value = [contours.value.filter((item) => item.name === 'Все')[0]?.id];
});

if (checkRole(['content_exhibitor'])) {
  serviceContentCheckCreate().then(response => {
    if (response.success && response.data === false) {
      router.push({name: 'Contents'});
    }
  });
}

const onHandleClickTabKey = (key) => {
  tabKey.value = key;
}

Promise.all([serviceParticipantCategoryList(), servicePavilionList()])
    .then(([responseParticipantCategory, responsePavilion]) => {
      isLoadingData.value = false;
      participantCategories.value = responseParticipantCategory.data || [];
      pavilions.value = responsePavilion.data || [];
    })
    .catch(error => {
      console.error('Ошибка при выполнении запросов:', error);
    });

const onHandleClickCreate = (newStatus) => {
  const isValExhibitor = validateExhibitor();

  let isValAdministrationAgreed = null;
  if (!checkRole(['content_exhibitor']) && newStatus === 6) {
    isValAdministrationAgreed = validateAdministrationAgreed();
  }

  // const isValAchievement = validateAchievement();
  const isValSlider = validateSlider();
  const isValFact = validateFact();

  isErrorVal.value = isValExhibitor || isValAdministrationAgreed;
  isValErrorSlider.value = isValSlider;
  // isValErrorAchievement.value = isValAchievement;
  isValErrorFact.value = isValFact;

  if (isValExhibitor || isValAdministrationAgreed || isValSlider || isValFact)
    return null;

  let messageItems = [];
  if (checkRole(['content_exhibitor'])) {
    if (messageData.value)
      messageItems = [messageData.value];
  } else messageItems = messages.value.length ? [messages.value[0].message] : [];

  const params = {
    status: newStatus,
    nameRu: nameRu.value,
    nameEn: nameEn.value,
    shortDescriptionRu: shortDescriptionRu.value,
    shortDescriptionEn: shortDescriptionEn.value,
    descriptionRu: descriptionRu.value,
    descriptionEn: descriptionEn.value,
    authorRu: authorImageTitleNameRu.value,
    authorEn: authorImageTitleNameEn.value,
    activeRu: isActiveRu.value,
    activeEn: isActiveEn.value,
    achievements: achievements.value,
    pavilions: selectedPavilions.value.length ? selectedPavilions.value : [],
    contourId: selectedContours.value?.length ? selectedContours.value[0] : null,
    previewImage: previewImage.value.length ? previewImage.value[0] : null,
    image: null,
    messages: messageItems,
    sliders: sliders.value,
    video: video.value?.length ? video.value[0] : null,
    authorVideoRu: authorVideoTitleNameRu.value,
    authorVideoEn: authorVideoTitleNameEn.value,
    factsRu: factsRu.value?.length ? factsRu.value?.map(item => item) : [],
    factsEn: factsEn.value?.length ? factsEn.value?.map(item => item) : [],
  };

  isLoadingCreate.value = true;
  serviceContentProfileCreate(params).then(response => {
    const status = response?.success;
    const message = status ? 'Успешно добавлено' : 'Ошибка, данные не были созданы';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      isLoadingCreate.value = false;

      saveMessageResponse.value = null;
    }, 3000);

    if (response.success && response?.data?.id) {
      router.push({name: 'ContentProfileEdit', params: {id: response?.data?.id}});
    } else {
      console.log('error')
    }
  }).catch(() => {
    isLoadingCreate.value = false;
  });
}

const onHandleClickPreviewImageDelete = () => {
  previewImage.value = null;
}

const onHandleClickVideoDelete = () => {
  video.value = null;
}

const onHandleClickSliderImageDelete = (index) => {
  sliders.value[index].image = null;
}

const onHandleClickSessionImageDelete = (index) => {
  achievements.value[index].image = null;
}

const validateExhibitor = () => {
  let isError = false;

  const valueName = nameRu.value?.trim();
  nameRuError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameRuError.value)
    isError = true;

  const valueShortDescriptionRu = shortDescriptionRu.value?.trim();
  shortDescriptionRuError.value = valueShortDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionRuError.value)
    isError = true;

  const valueDescriptionRu = descriptionRu.value?.trim();
  descriptionRuError.value = valueDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionRuError.value)
    isError = true;

  selectedPavilionError.value = selectedPavilions.value?.length ? '' : 'Вы не выбрали павильон';
  if (selectedPavilionError.value)
    isError = true;

  previewImageError.value = previewImage.value ? '' : 'Вы не выбрали файл';
  if (previewImageError.value)
    isError = true;

  videoError.value = video.value ? '' : 'Вы не выбрали файл';
  if (videoError.value)
    isError = true;

  return isError;
}

const validateAdministrationAgreed = () => {
  return false;
}

const validateAchievement = () => {
  let isError = false;

  // if (!achievements.value?.length) {
  //   messageAchievementEmpty.value = 'Добавьте хотя-бы одно достижение';
  //   return true;
  // }
  // else messageAchievementEmpty.value = '';

  achievements.value.map(item => {
    item.imageError = !item.image ? 'Вы не выбрали файл' : '';
    if (item.imageError)
      isError = true;

    item.authorRuError = !item.authorRu?.length ? 'Не должно быть пустым' : '';
    if (item.authorRuError)
      isError = true;

    if (checkRole(['admin', 'manager', 'content_manager'])) {
      item.authorEnError = !item.authorEn?.length ? 'Не должно быть пустым' : '';
      if (item.authorEnError)
        isError = true;
    }

    item.descriptionRuError = !item.descriptionRu?.length ? 'Не должно быть пустым' : '';
    if (item.descriptionRuError)
      isError = true;

    if (checkRole(['admin', 'manager', 'content_manager'])) {
      item.descriptionEnError = !item.descriptionEn?.length ? 'Не должно быть пустым' : '';
      if (item.descriptionEnError)
        isError = true;
    }
  });

  return isError;
}

const validateFact = () => {
  let isError = false;

  if (factsRu.value?.filter(item => item.name?.length)?.length !== 5) {
    messageFactEmptyRu.value = 'Вы не заполнили все обязательные данные';

    isError = true;
  }
  else messageFactEmptyRu.value = '';

  factsRu.value?.map(item => {
      if (!item.name?.length)
        item.nameError = 'Поле не должно быть пустым';
      else if (item.name?.length > 150)
        item.nameError = 'Не должно быть больше 150 символов';
      else item.nameError = '';

      if (item.nameError)
        isError = true;
  });

  return isError;
}

const validateSlider = () => {
  let isError = false;

  if (!sliders.value?.length) {
    messageSliderEmpty.value = 'Добавьте хотя-бы один рендер';
    return true;
  }
  else messageSliderEmpty.value = '';

  sliders.value?.map(slider => {
    slider.imageError = typeof slider.image === 'string' || !slider.image ? 'Вы не выбрали изображение' : '';

    if (slider.imageError)
      isError = true;

    slider.authorRuError = !slider.authorRu?.length ? 'Поле не должно быть пустым' : '';
    if (slider.authorRuError)
      isError = true;
  });

  return isError;
}

const onHandleClickSliderCreate = () => {
  sliders.value.push({
    image: null,
    imageError: null,
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
    sort: 100,
    sortError: '',
  });
}

const onHandleDeleteSlider = (index) => {
  sliders.value.splice(index, 1);
}

const onHandleClickDeleteMessage = (index) => {
  messages.value.splice(index, 1);
}

const onHandleClickSendMessage = () => {
  if (messages.value.length)
    return null;

  messages.value.push({
    message: messageData.value,
    createdAt: moment().format('DD.MM.YYYY HH:mm'),
    isAnswer: false,
    user,
  });

  messageData.value = null;
}

const onHandleClickAchievementCreate = () => {
  achievements.value.push({
    image: null,
    imageError: null,
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
    descriptionRu: '',
    descriptionRuError: '',
    descriptionEn: '',
    descriptionEnError: '',
  });
}

const onHandleDeleteAchievement = (index) => {
  achievements.value.splice(index, 1);
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.achievement-content-item {
  padding: 15px 15px 0 15px;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.tabs {
  margin-bottom: 35px;
}

.tabs .tab.active {
  background: #c6d6f4;
}

.tabs .tab {
  background: #f0f8ff;
  transition: all .3s;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.tabs .tab:hover {
  cursor: pointer;
  transition: all .3s;
  background: #d0e9ff;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.tabs .tab {
  padding: 10px;
  margin: 5px;
}
</style>
