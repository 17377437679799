<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">{{ $t('common.logs') }}</h1>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-full">
            <div class="flex flex-wrap w-full justify-end">
              <div class="flex w-full mb-4">
                <div class="flex-col w-1/3 mr-4">
                  <Multiselect v-model="model" :options="modelOptions" :mode="'single'" :valueProp="'id'" track-by="name" label="name" :searchable="true" :placeholder="$t('logs.searchObject')"/>
                </div>
                <div class="flex-col w-2/3">
                  <input-text v-model="modelId" :placeholder="$t('logs.searchName')"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="loadData"/>
                <table v-if="!loadData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('logs.datetime') }}
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('logs.object') }}
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('logs.elementName') }}
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('logs.action') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="entity of entityStore.entities" :key="entity.id" :class="{'bg-neutral-100 opacity-50': entity.isDeleted}" class="hover:bg-gray-100">
                    <td class="p-4">{{ entity.updated_at }}</td>
                    <td class="p-4">
                      <template v-if="entity.revisionable_type === 'App\\Models\\Document'">
                        <router-link :to="{name: 'RegistryEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\Lists\\ListContour'">
                        <router-link :to="{name: 'ListContourEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\Lists\\ListVenue'">
                        <router-link :to="{name: 'ListVenueEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\Api\\V1\\ContentAudioGuilde'">
                        <router-link :to="{name: 'ContentAudioGuildEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\Api\\V1\\ContentEvent'">
                        <router-link :to="{name: 'ContentEventEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\Api\\V1\\ContentNews'">
                        <router-link :to="{name: 'ContentNewsEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\Api\\V1\\ContentProduct'">
                        <router-link :to="{name: 'ContentProductEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\Api\\V1\\ContentProfile'">
                        <router-link :to="{name: 'ContentProfileEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\DocType'">
                        <router-link :to="{name: 'DocsDirectoryEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\ExhibitorType'">
                        <router-link :to="{name: 'ExhibitorTypesEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\User'">
                        <router-link :to="{name: 'UserEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\LibraryDocument'">
                        <router-link :to="{name: 'LibraryEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.revisionable_type === 'App\\Models\\FederalDistrict'">
                        <router-link :to="{name: 'FederalDistrictsEdit', params: {id: entity.revisionable_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else-if="entity.document_id">
                        <router-link :to="{name: entity.section_id === 1 ? 'RegistryEdit' : 'LibraryEdit', params: {id: entity.document_id}}" class="text-blue-700">
                          {{ entity.revisionable_type }} #{{ entity.revisionable_id }}
                        </router-link>
                      </template>
                      <template v-else>{{ entity.revisionable_type }} #{{ entity.revisionable_id }}</template>
                    </td>
                    <td class="p-4">{{ entity.revisionable_name }}</td>
                    <td class="p-4" v-if="entity.user">"<router-link :to="{name: 'UserEdit', params: {id: entity.user?.id}}" class="text-blue-700">{{ entity.user.name }}<template v-if="entity.user.fio"> ({{ entity.user.fio }})</template> {{ entity.user.surname }}</router-link>" изменил "{{ entity.field }}" с "{{ entity.old_value }}" на "{{ entity.new_value }}"</td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!loadData && !entityStore.entities.length" class="text-xl text-center p-7">{{ $t('common.notFound') }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <div v-if="entityStore.pagination">
              <span class="text-gray-500">{{ $t('common.found') }}</span> {{ entityStore.pagination.total }}
            </div>
          </div>
          <div class="flex-col p-7 w-1/2 text-right">
            <nav v-if="entityStore.pagination && entityStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of entityStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="entityPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useLogStore} from "../../stores/LogStore.js";
import Spinner from "../flowbite/spinner.vue";
import {ref, watch} from "vue";
import Multiselect from '@vueform/multiselect'
import InputText from "../flowbite/form/inputText.vue";
import {useRoute} from "vue-router";

const entityStore = useLogStore();
const loadData = ref(true);
const search = ref('');

const modelOptions = ref([]);
const model = ref(null);
const modelId = ref(null);

let timerId = null;

const route = useRoute()

// Первоначальные данные
entityStore.getDirectories().then(() => {
  modelOptions.value = [...modelOptions.value, ...entityStore.modelOptions];

  if (route.query.object) {
    model.value = route.query.object
  }

  if (route.query.id) {
    modelId.value = route.query.id
  }

  entityStore.getEntities().then(() => {
    loadData.value = false;
  })
})

// Работа с данными
const entityPaginate = (url) => {
  if (url) {
    loadData.value = true;

    let params = getSearchParams()

    entityStore.getEntities(params, url).then(() => {
      loadData.value = false;
    });
  }
}

watch([model, modelId], () => {
  clearTimeout(timerId)

  timerId = setTimeout(() => {
    searchEntity()
  }, 500)
})

const searchEntity = () => {
  loadData.value = true;

  let params = getSearchParams()

  entityStore.getEntities(params).then(() => {
    loadData.value = false;
  });
}

const getSearchParams = () => {
  return {
    search: modelId.value,
    filter: {
      object: model.value,
    }
  }
}

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === entityStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>
