<template>
  <spinner v-if="isEntitySaved" />

  <div v-if="!isEntitySaved" class="grid grid-cols-2 gap-7 px-7 pt-7">
    <div class="col-span-1 mb-4 xl:mb-2">
      <div v-if="isEntitySaved" class="w-full p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
        {{ $t('common.documentSaved') }}
      </div>

      <div
          v-if="saveMessageResponse"
          style="z-index: 100; top: 80px"
          id="toast-top-right"
          :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
          class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
          role="alert">
        <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
      </div>

      <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">{{ $t('common.venues_edit') }}</h1>
            <p class="mb-2" v-if="checkRole('admin')">
              <router-link :to="{name: 'UserLog', query: {object: 'App\\Models\\Lists\\ListVenue', id: id}}" target="_blank" class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">{{ $t('common.toLogs') }}</router-link>
            </p>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <div class="p-7 pb-12 border-b border-gray-200">
                  <form action="">
                    <div class="grid gap-4 mb-4 sm:grid-cols-2">
                      <div class="sm:col-span-2">
                        <input-text
                            v-model="valueName"
                            @blur="onHandleNameBlur"
                            :label="$t('directoryDocuments.name')" />
                        <p
                            v-if="errorName"
                            class="mt-2 text-sm text-red-600">
                          {{ errorName }}
                        </p>
                      </div>
                      <div class="sm:col-span-2">
                        <textarea
                            placeholder="Описание"
                            v-model="valueDescription"
                            rows="5"
                            class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                        />
                      </div>
                      <div class="sm:col-span-2">
                        <input-text v-model="valueSort" :label="$t('directoryDocuments.sort')"/>
                      </div>
                    </div>
<!--                    <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">-->
<!--                      {{ entityStore.error }}-->
<!--                    </div>-->
                  </form>
                </div>
                <div class="p-7 flex justify-end">
                  <button @click.prevent="onHandleSave" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                    <span>{{ $t('common.save') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {checkRole} from "../../../../utilits/functions";
import InputText from "../../../flowbite/form/inputText.vue";
import {useRoute, useRouter} from 'vue-router'
import {
  serviceContourUniqueName
} from "../../../../service/lists/serviceListContour.js";
import {serviceVenueEdit, serviceVenueFindId} from "../../../../service/lists/serviceListVenue.js";
import Spinner from "../../../flowbite/spinner.vue";

const isEntitySaved = ref(false);

const id = ref(null);
const valueName = ref('');
const valueDescription = ref('');
const errorName = ref(null);
const saveMessageResponse = ref(null);

const valueSort = ref(500);
// const

// Первоначальные данные
const route = useRoute()
const router = useRouter()

serviceVenueFindId(route.params.id).then(response => {
  const data = response?.data;
  if (!data) {
    return null;
  }

  id.value = data.id;
  valueName.value = data.name;
  valueDescription.value = data.description;
  valueSort.value = data.sort;
});

const onHandleSave = () => {
  errorName.value = !valueName.value?.length ? "Не должно быть пустым" : null;
  if (errorName.value) {
    return null;
  }

  isEntitySaved.value = true;
  serviceVenueEdit(route.params.id, {name: valueName.value, description: valueDescription.value, sort: valueSort.value}).then(response => {
    isEntitySaved.value = false;
    const status = response?.success;
    const message = response?.success ? 'Успешно сохранено' : 'Ошибка, данные не были сохранены';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      saveMessageResponse.value = null;
    }, 3000);
  });
};

const onHandleNameBlur = () => {
  if (!valueName.value?.length) {
    return null;
  }

  serviceContourUniqueName(valueName.value).then(response => {
    errorName.value = response.data ? 'Такой тип контура уже существует' : null;
  })
}

</script>
